import { Row, Space } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TabTitle } from '../../components/TabTitle';
import { GenericFilterDrawer } from '../../components/filter/generic/GenericFilterDrawer';
import { createInitialFilterValues } from '../../components/filter/generic/projectIntialValues';
import { CreateProjectModal } from '../../components/modal/projectModal/CreateProjectModal';
import { ProjectTableList } from '../../components/table/projectList/ProjectTableList';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { ButtonBasic } from '../../elements/buttons/StyledButtons';
import { MainLayout } from '../../layouts/Main';
import { routesNamedDict } from '../../routes';
import { RootAuth, RootProject } from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export function ProjectListPage({ title }: TitleProp) {
  const [modal, setModal] = useState({ visible: false });
  const projects = useSelector(
    (state: RootProject) => state.project.projectList
  );
  const [filterOpen, setFilterOpen] = useState(false);
  const [filteredData, setFilteredData] = useState(projects);
  const role = useSelector((state: RootAuth) => state.auth?.role);

  const handleNew = () => {
    setModal({ visible: true });
  };

  // Update filteredData whenever projects change
  useEffect(() => {
    setFilteredData(projects);
  }, [projects]);

  const navigate = useNavigate();
  useEffect(() => {
    // Redirect if role is not 'designer'
    if (role !== 'designer') {
      navigate('/' + routesNamedDict.NOT_FOUND);
    }
  }, [role, navigate]);

  return (
    <MainLayout>
      <HeaderTitle
        title={i18n.t('page_titles:projects_page')}
        subtitle={i18n.t('page_subtitles:projects_sub')}
      />
      <TabTitle title={title} />
      <CreateProjectModal setModal={setModal} modal={modal} />
      <Row justify="start">
        <Space>
          <ButtonBasic onClick={handleNew}>
            {i18n.t('buttons:create')}
          </ButtonBasic>
          <ButtonBasic
            onClick={() => setFilterOpen(true)}
            disabled={projects.length === 0}
          >
            {i18n.t('buttons:filter')}
          </ButtonBasic>
        </Space>
      </Row>
      <GenericFilterDrawer
        initialData={projects}
        setFilteredData={setFilteredData}
        filterOpen={filterOpen}
        closeFilter={() => setFilterOpen(false)}
        createInitialFilterValues={createInitialFilterValues}
      />
      <ProjectTableList data={filteredData} />
    </MainLayout>
  );
}
