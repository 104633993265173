import i18n from 'i18next';

import {
  ButtonBasic,
  DropdownButton,
} from '../../elements/buttons/StyledButtons';
import { CompareButtonProps } from './TableTypes';

export function CompareButton({
  filteredData,
  selectedRowKeys,
  isMobile,
}: CompareButtonProps) {
  const selectedFabrics = filteredData
    .filter((f, idx) => selectedRowKeys.includes(idx + 1) && 'hashed_name' in f)
    .map((f) => f.hashed_name);

  const selectedFabricsUri =
    selectedFabrics.length === 0
      ? ''
      : encodeURIComponent(JSON.stringify(selectedFabrics));

  return (
    <>
      <a
        href={`/compare?fabrics=${selectedFabricsUri}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {isMobile ? (
          <DropdownButton block disabled={selectedRowKeys.length === 0}>
            {i18n.t('buttons:compare')}
          </DropdownButton>
        ) : (
          <ButtonBasic block disabled={selectedRowKeys.length === 0}>
            {i18n.t('buttons:compare')}
          </ButtonBasic>
        )}
      </a>
    </>
  );
}
