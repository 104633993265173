import { Row } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TabTitle } from '../../components/TabTitle';
import { CreateCertificationModal } from '../../components/modal/certification/CreateCertificationModal';
import { EditCertificationModal } from '../../components/modal/certification/EditCertificationModal';
import { CertificationsList } from '../../components/table/certificationsList/CertificationsList';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { ButtonBasic } from '../../elements/buttons/StyledButtons';
import { MainLayout } from '../../layouts/Main';
import { routesNamedDict } from '../../routes';
import { getCertObj } from '../../utils/backendRequests/certifications/CertificationTypes';
import { getCertifications } from '../../utils/backendRequests/certifications/getCertifications';
import { RootAuth } from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export type CertRecord = {
  id: number;
  name: string;
  certification_type: string;
  is_owner: boolean;
};

export function CertificationsListpage({ title }: TitleProp) {
  const [data, setData] = useState<getCertObj[]>([]);
  const [createCertModal, setCreateCertModal] = useState({ visible: false });
  const [editCertModal, setEditCertModal] = useState({ visible: false });
  const [edtiCertRecord, setEditCertRecord] = useState<CertRecord | null>(null);
  const role = useSelector((state: RootAuth) => state.auth?.role);
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  const navigate = useNavigate();

  const editCertOnClick = (record: CertRecord) => {
    setEditCertRecord(record);
    setEditCertModal({ visible: true });
  };

  const refreshCertifications = async () => {
    setData(await getCertifications());
  };

  useEffect(() => {
    if (role === 'supplier') {
      refreshCertifications();
    } else {
      navigate('/' + routesNamedDict.NOT_FOUND);
    }
  }, [role, navigate]);

  return (
    <MainLayout>
      <HeaderTitle
        title={i18n.t('page_titles:certifications_page')}
        subtitle={i18n.t('page_subtitles:certifications_sub')}
      />
      <TabTitle title={title} />
      <Row justify="start">
        {account !== 'viewer' && (
          <ButtonBasic onClick={() => setCreateCertModal({ visible: true })}>
            {i18n.t('buttons:create')}
          </ButtonBasic>
        )}
      </Row>
      <CertificationsList
        title={title}
        data={data}
        editCertOnClick={editCertOnClick}
        getCertifications={refreshCertifications}
      />
      <CreateCertificationModal
        modal={createCertModal}
        setModal={setCreateCertModal}
        onSuccess={refreshCertifications}
      />
      {edtiCertRecord && (
        <EditCertificationModal
          modal={editCertModal}
          setModal={setEditCertModal}
          record={edtiCertRecord}
          onSuccess={refreshCertifications}
        />
      )}
    </MainLayout>
  );
}
