import { TeamOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import i18n from 'i18next';
import { shareCollection } from '../../../utils/backendRequests/collection/shareCollection';
import {
  KeyValueDesignerBrandObject,
  SharedDesignerBrandsProps,
} from './CollectionListTypes';

export function SharedDesignerBrands({
  sharedDesignerBrands,
  collectionId,
  setSharedDesignerBrands,
}: SharedDesignerBrandsProps) {
  const removeSharedDesignerBrandFromCollection = (
    designerBrand: KeyValueDesignerBrandObject
  ) => {
    const newSharedDesignerBrands = sharedDesignerBrands.filter(
      (brand) => brand.key !== designerBrand.key
    );
    const sharedDesignerBrandsIds = newSharedDesignerBrands.map(
      (designerBrand: KeyValueDesignerBrandObject) => Number(designerBrand.key)
    );
    shareCollection(collectionId, sharedDesignerBrandsIds).then(() => {
      setSharedDesignerBrands(newSharedDesignerBrands);
    });
  };

  return (
    <Space direction="vertical" size="small">
      <p style={{ margin: '0' }}>
        {i18n.t('headers:designer_brands_with_access')}
      </p>
      {sharedDesignerBrands.map((designerBrand, index: number) => (
        <Space direction="vertical" size="small" key={index}>
          <Space style={{ height: '2rem' }}>
            <TeamOutlined style={{ fontSize: '22px' }} />
            <p style={{ fontWeight: 'normal' }}>{designerBrand.value}</p>
            <a
              role="button"
              onClick={() =>
                removeSharedDesignerBrandFromCollection(designerBrand)
              }
            >
              {i18n.t('buttons:remove')}
            </a>
          </Space>
        </Space>
      ))}
    </Space>
  );
}
