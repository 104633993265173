import { ResetValidation } from '../AuthTypes';

export const validation: ResetValidation = {
  email: [
    {
      required: true,
      message: 'Please input your email!',
    },
  ],
};
