import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as DelvifyLogo } from '../assets/images/logos/delvify.svg';
import { HeaderBarDobby } from '../components/HeaderBarDobby';
import { LayoutContainer } from '../elements/StyledUI';
import { ContentWrapper } from '../elements/wrapper/ContentWrapper';
import { LayoutWrapper } from '../elements/wrapper/LayoutWrapper';
import { routesNamedDict } from '../routes';
import { verifyAdmin } from '../utils/backendRequests/admin/verify';
import { RootAuth } from '../utils/types/Types';
import { LayoutProps } from './LayoutTypes';

export function AdminLayout({ children }: LayoutProps) {
  const navigate = useNavigate();
  const username = useSelector((state: RootAuth) => state.auth?.name || '');

  // Note: will fall into a loop if the page being redirected to has the <AdminLayout>
  useEffect(() => {
    const handleAdminVerification = async () => {
      const verified = await verifyAdmin();
      if (!verified) {
        // Redirect to 404 if user is not a verified admin
        navigate('/' + routesNamedDict.NOT_FOUND);
      }
    };

    handleAdminVerification();
  }, [navigate]);

  return (
    <LayoutContainer>
      <HeaderBarDobby
        logo={
          <Link to={routesNamedDict.ADMIN}>
            <DelvifyLogo />
          </Link>
        }
        userName={username}
      />
      <LayoutContainer>
        <LayoutWrapper>
          <ContentWrapper>{children}</ContentWrapper>
        </LayoutWrapper>
      </LayoutContainer>
    </LayoutContainer>
  );
}
