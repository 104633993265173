import { isAxiosError } from 'axios';
import { axios } from '../../../plugins/axios';

interface apiProps {
  method: string;
  url: string;
  body?: object;
  headers?: object;
}

/**
 * A template to make API calls to backend_auth using axios. Also includes general
 * error handling.
 */
export const apiAuth = async ({ method, url, body, headers }: apiProps) => {
  try {
    const response = await axios.request({
      url: `${process.env.REACT_APP_BACKEND_AUTH}${url}`,
      method,
      data: body,
      headers,
    });
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      // Handle Axios-specific error for backend_auth
      throw new Error(
        error.response?.data?.error ||
          error.response?.data?.msg ||
          'An unknown error occurred'
      );
    } else {
      // Handle unexpected errors
      throw new Error('An unexpected error occurred');
    }
  }
};
