import { useState } from 'react';
import { Link } from 'react-router-dom';
import { UpdateDesignerUserModal } from '../../modal/createUserModal/UpdateDesignerUserModal';
import { DesignerUserValues } from '../TableTypes';

interface EditDesignerUser {
  user: DesignerUserValues;
  designerUserId: string;
  getUsers: () => void;
}

export function EditDesignerUser({
  user,
  designerUserId,
  getUsers,
}: EditDesignerUser) {
  const [updateModal, setUpdateModal] = useState({ visible: false });
  const handleUpdate = () => {
    setUpdateModal({ visible: true });
  };

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <Link onClick={handleUpdate} to={''}>
          Edit
        </Link>
      </div>
      <UpdateDesignerUserModal
        modal={updateModal}
        setModal={setUpdateModal}
        user={user}
        userId={designerUserId}
        getUsers={getUsers}
      />
    </>
  );
}
