import { axiosNoAuth as axios } from '../../../plugins/axiosNoAuth';
import { setUser } from '../../../plugins/redux/auth';
import { NavigateProp, PathsObject, ReduxStoreProp } from './AuthTypes';
/**
 * Login
 *
 * If successful, EncryptStorage in redux will update the token used for
 * authorization. After the token is updated, will redirect to the home page '/'.
 * View the EncryptStorage plugin file, for more information on the logic.
 */
export async function login(
  store: ReduxStoreProp,
  email: string,
  password: string,
  navigate: NavigateProp,
  paths: PathsObject
): Promise<void> {
  axios
    .post(
      process.env.REACT_APP_BACKEND_AUTH + 'v1/authentication/authenticate',
      {
        email: email,
        password: password,
      }
    )
    .then((response) => {
      if (response.status === 200) {
        if (response.data.twoFactor) {
          navigate(paths['TWO-FACTOR_AUTHENTICATION'], {
            state: { email: email },
          });
        } else {
          store.dispatch(
            setUser({
              name: response.data.name,
              role: response.data.role,
              accountType: response.data.account_type,
              token: response.data.token,
              brandName: response.data.brandName,
              measurementUnit: response.data.measurementUnit,
              hoplunUsername: response.data.hoplunUsername,
            })
          );
          if (response.data.role === 'admin') {
            navigate(paths.ADMIN);
          } else {
            navigate(paths.HOME);
          }
        }
      }
    })
    .catch((error) => {
      // Wrong email or password, or internal server error
      store.dispatch(
        setUser({
          token: '',
        })
      );
      navigate(paths.LOGIN, {
        state: {
          code: error.response.status,
          msg: error.response.data.msg || error.response.data.error,
        },
      });
    });
}
