import Slider from 'react-slick';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ArrowProps, CarouselMainProps } from './CarouselTypes';
import { ShowFabricCard } from './carouselCards/ShowFabricCard';

const SampleNextArrow = (props: ArrowProps) => {
  const { className, style, onClick, isMobile } = props;

  return (
    <div
      className={className}
      style={{
        ...style,
        filter: 'invert(100%)',
        marginTop: isMobile ? '-40px' : '0px',
      }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props: ArrowProps) => {
  const { className, style, onClick, isMobile } = props;

  return (
    <div
      className={className}
      style={{
        ...style,
        filter: 'invert(100%)',
        marginTop: isMobile ? '-40px' : '0px',
      }}
      onClick={onClick}
    />
  );
};

const _defaultClick = () => {};

/**
 * A carousel to display elements
 */
export function CarouselMain({
  items,
  isMobile,
  navigate = _defaultClick,
}: CarouselMainProps) {
  let settings;

  if (isMobile) {
    settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: false,
      nextArrow: <SampleNextArrow isMobile={isMobile} />,
      prevArrow: <SamplePrevArrow isMobile={isMobile} />,
    };
  } else {
    settings = {
      infinite: true,
      speed: 500,
      slidesToShow: items.length > 4 ? 4 : items.length,
      slidesToScroll: 1,
      variableWidth: true,
      nextArrow: <SampleNextArrow isMobile={isMobile} />,
      prevArrow: <SamplePrevArrow isMobile={isMobile} />,
    };
  }

  return (
    <div style={{ width: '70vw', marginLeft: isMobile ? '15px' : '0px' }}>
      <Slider {...settings}>
        {items.map((item, index) => (
          <ShowFabricCard
            item={item}
            key={index}
            navigate={navigate}
            isMobile={isMobile}
          />
        ))}
      </Slider>
    </div>
  );
}
