import i18n from 'i18next';

export type DropdownKey = 'settings' | 'logout';

interface DropdownItem {
  label: string;
  key: DropdownKey;
}

export const dropdownItems: DropdownItem[] = [
  {
    label: i18n.t('headers:settings'),
    key: 'settings',
  },
  {
    label: i18n.t('headers:logout'),
    key: 'logout',
  },
];
