import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { getApprovedSupplierBrands } from '../../../utils/backendRequests/admin/getApprovedSupplierBrands';
import { updateApprovedBrands } from '../../../utils/backendRequests/admin/updateApprovedBrands';
import { Modal } from '../../Modal';
import { BrandValue, ManageApprovedSupplierBrandsProps } from '../TableTypes';
import { ApprovedSupplierBrands } from './ApprovedSupplierBrands';

export function ManageApprovedSupplierBrands({
  designerBrandId,
  supplierBrands,
}: ManageApprovedSupplierBrandsProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [approvedSupplierBrands, setApprovedSupplierBrands] = useState<
    BrandValue[]
  >([]);
  const [selectedBrands, setSelectedBrands] = useState<BrandValue[]>([]);
  const [unapprovedSupplierBrands, setUnapprovedSupplierBrands] = useState<
    BrandValue[]
  >([]);

  useEffect(() => {
    async function _getApprovedBrands() {
      const supplierBrandsData =
        await getApprovedSupplierBrands(designerBrandId);
      setApprovedSupplierBrands(supplierBrandsData);
    }
    _getApprovedBrands();
  }, [designerBrandId]);

  useEffect(() => {
    const approvedBrandIds = approvedSupplierBrands.map((brand) => brand.key);
    setUnapprovedSupplierBrands(
      supplierBrands.filter((brand) => !approvedBrandIds.includes(brand.key))
    );
  }, [approvedSupplierBrands, supplierBrands]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    // Merge selected and previously approved supplier brands
    const updatedApprovedSupplierBrands =
      approvedSupplierBrands.concat(selectedBrands);
    // Convert Brands objects to their Ids for backend API call
    const selectedBrandIds = updatedApprovedSupplierBrands.map(
      (brand: BrandValue) => brand.key
    );
    updateApprovedBrands({
      designerBrandId: designerBrandId,
      brandIds: selectedBrandIds,
    }); // Updates list of approved supplier brands after API call is completed
    setApprovedSupplierBrands(updatedApprovedSupplierBrands);
    // Clears the list of selected brands to prevent resubmission of the same brands
    setSelectedBrands([]);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChange = (value: BrandValue[], option: BrandValue[]) => {
    // Updates the list of brands that the user has selected
    setSelectedBrands(option);
  };

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <Link onClick={showModal} to={''}>
          Manage
        </Link>
      </div>
      <Modal
        title="Approve Brands"
        open={isModalOpen}
        okText="Send"
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Add more brands"
          // @ts-expect-error because Antd restrictions
          onChange={handleChange}
          options={unapprovedSupplierBrands}
          value={selectedBrands}
        />

        <ApprovedSupplierBrands
          approvedSupplierBrands={approvedSupplierBrands}
          designerBrandId={designerBrandId}
          setApprovedSupplierBrands={setApprovedSupplierBrands}
        />
      </Modal>
    </>
  );
}
