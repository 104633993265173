import { isAxiosError } from 'axios';
import { apiAuth } from '../api/apiAuth';

/**
 * Verifies if the user token is still valid.
 */
export async function verifyUser(): Promise<boolean> {
  try {
    await apiAuth({
      method: 'get',
      url: 'v1/profile/verify',
    });
    return true; // If the request is successful, token is valid
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.status === 401) {
        return false; // Token is invalid
      }
    }
    return false;
  }
}
