import { SettingsPage } from '../pages/userSettings/SettingsPage';
import { RouteProps } from './RouteTypes';

export const routesUserSettings: RouteProps[] = [
  {
    path: '/settings',
    title: 'Settings',
    page: SettingsPage,
  },
];
