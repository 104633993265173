import i18n from 'i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { StyledRow } from '../elements/StyledUI';
import { routesNamedDict } from '../routes';
import { HeaderWrapper } from '../styles/wrappers/HeaderWrapper';
import { searchTextFabric } from '../utils/backendRequests/textSearch/fabric';
import { RootState } from '../utils/types/Types';
import { stringValid } from '../utils/validations/stringValidator';
import { HeaderDesktop } from './header/HeaderDesktop';
import { HeaderPhone } from './header/HeaderPhone';
import { HeaderBarDobbyProps } from './table/TableTypes';

export function HeaderBarDobby({ logo, userName }: HeaderBarDobbyProps) {
  const navigate = useNavigate();
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);

  const onSearch = (value: string) => {
    if (stringValid(value, 150, i18n.t('column_titles:text_query'), false)) {
      const query = { text_query: value };
      searchTextFabric(query, navigate, routesNamedDict);
    }
  };

  return (
    <HeaderWrapper>
      <StyledRow>
        {isMobileFlag ? (
          <HeaderPhone logo={logo} />
        ) : (
          <HeaderDesktop logo={logo} userName={userName} onSearch={onSearch} />
        )}
      </StyledRow>
    </HeaderWrapper>
  );
}
