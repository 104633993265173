/**
 * nullParser - Iterates through all the key-value pairs of an object
 * and replaces any empty string values with null.
 */
export const nullParser = (
  rawObject: Record<string, unknown>
): Record<string, unknown> => {
  return Object.entries(rawObject).reduce(
    (parsedObject, [key, value]) => {
      parsedObject[key] = value === '' ? null : value;
      return parsedObject;
    },
    {} as Record<string, unknown>
  );
};
