import { formFieldsProp } from '../../form/FormTypes';

const commonFields: formFieldsProp[] = [
  {
    name: 'name',
    fieldType: 'string',
    required: true,
    stringMax: 50,
  },
  {
    name: 'password',
    fieldType: 'string',
    required: true,
    stringMax: 50,
  },
  {
    name: 'email',
    fieldType: 'string',
    required: true,
    stringMax: 50,
  },
  {
    name: 'city',
    fieldType: 'string',
    required: false,
    stringMax: 30,
  },
  {
    name: 'country_id',
    fieldType: 'singleDropdown',
    required: false,
  },
  {
    name: 'is_active',
    fieldType: 'boolean',
    required: true,
  },
  {
    name: 'is_email_notified',
    fieldType: 'boolean',
    required: true,
  },
  {
    name: 'is_2fa_enabled',
    fieldType: 'boolean',
    required: true,
  },
  {
    name: 'account_type',
    fieldType: 'singleDropdown',
    required: true,
    isTranslatable: true,
  },
  {
    name: 'measurement_unit',
    fieldType: 'singleDropdown',
    required: true,
    isTranslatable: true,
  },
];

// Note designer_brand_id is not included here as its dropdown values are no retreived on a list endpoint
export const designerUserFields = [
  ...commonFields,
  {
    name: 'role',
    fieldType: 'string',
    required: false,
    stringMax: 30,
  },
  {
    name: 'department',
    fieldType: 'string',
    required: false,
    stringMax: 30,
  },
];

export const supplierUserEditFields = commonFields.filter(
  (field) => field.name !== 'password'
);

export const designerUserEditFields = designerUserFields.filter(
  (field) => field.name !== 'password'
);

// Note supplier_brand_id is not included here as its dropdown values are no retreived on a list endpoint
export const supplierUserFields = [...commonFields];

// Needs to be specified as an enum as sequelize in backend_auth takes in a string and not the enum number
export const enumProps = ['measurement_unit', 'account_type'];

interface InputField {
  name: string;
  required: boolean;
  stringMax?: number;
  fieldType: string;
}

// Need to make boolean fields have a boolean default value
export const initialFormFields = (formFields: InputField[]) => {
  return Object.fromEntries(
    formFields.map((field) => [
      field.name,
      field.fieldType === 'boolean' ? false : '',
    ])
  );
};
