import styled from 'styled-components';

import { Color } from '../base/Color';
import { FontSize, FontWeight } from '../base/Font';

export const TitleH6 = styled.p`
  color: ${Color.G6} !important;
  font-size: ${FontSize.H6};
  font-weight: ${FontWeight.SemiBold};
  line-height: 27px;
`;
