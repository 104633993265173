import { Form, Space, message } from 'antd';
import { Dayjs } from 'dayjs';
import i18n from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SaveFabricButton } from '../../../elements/buttons/StyledButtons';
import {
  StyledHorizontalSpace,
  StyledVerticalSpace,
} from '../../../elements/fabrics/fabricStyledElement';
import { TextB1 } from '../../../elements/typography/TextB1';
import { TextB3 } from '../../../elements/typography/TextB3';
import {
  CenteredUploadWrapper,
  FormWrapper,
  MobileFormWrapper,
} from '../../../elements/wrapper/FormWrapper';
import { getSupplierBrandIdFromFabricHash } from '../../../utils/backendRequests/admin/getSupplierBrandIdFromFabricHash';
import { editAttachment } from '../../../utils/backendRequests/fabric/editAttachment';
import { editFabric } from '../../../utils/backendRequests/fabric/editFabric';
import { editFabricImage } from '../../../utils/backendRequests/fabric/editFabricImage';
import { FabricRecordKey } from '../../../utils/fabrics/FabricsTypes';
import { parseFabric } from '../../../utils/fabrics/parseFabric';
import { parseFabricTitles } from '../../../utils/fabrics/parseFabricFields';
import { compareAndReturnDifferences } from '../../../utils/fabrics/populateFabricValues';
import { nullParser } from '../../../utils/parsers/parseNullValues';
import { RootAuth } from '../../../utils/types/Types';
import { FormItems } from '../../form/FormItems';
import { PopulateFabricFieldsModal } from '../../modal/PopulateFabricFieldsModal';
import { EditFabricImageUpload } from '../../upload/EditFabricImageUpload';
import { FileEditUpload } from '../../upload/FileEditUpload';
import {
  CompositeFieldCheckProps,
  EditFabricFormProps,
  FabricFormSubmitProps,
} from '../FabricTypes';
import {
  compositeFabricFieldCheck,
  fabricFieldRules,
} from '../fabricFormRules';
import { formFields } from '../formFields';

/**
 * FabricForm component
 */
export function EditFabricForm({
  formType,
  fabHashedName,
  fabric,
  isMobile,
}: EditFabricFormProps) {
  const { measurementUnit } = useSelector((state: RootAuth) => state.auth);
  const [form] = Form.useForm();
  const [backImg, setBackImg] = useState<File | null>(null);
  const [backImgUrl, setBackImgUrl] = useState<string | null>(null);
  const [frontImg, setFrontImg] = useState<File | null>(null);
  const [frontImgUrl, setFrontImgUrl] = useState<string | null>(null);
  const [headerImg, setHeaderImg] = useState<File | null>(null);
  const [headerImgUrl, setHeaderImgUrl] = useState<string | null>(null);
  const [macroImg, setMacroImg] = useState<File | null>(null);
  const [macroImgUrl, setMacroImgUrl] = useState<string | null>(null);
  const [propsWithDropDownObj, setPropsWithDropDownObj] = useState({});
  const [supplierBrandId, setSupplierBrandId] = useState<number | null>(null);
  const [attachments, setAttachments] = useState<File[] | null>([]);
  const [deleteAttachmentIDs, setDeleteAttachmentIDs] = useState<number[]>([]);
  const [attachmentsFiles, setAttachmentsFiles] = useState<File[]>([]);
  const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);
  const [isPopFabricModalOpen, setIsPopFabricModalOpen] = useState(false);
  const [formValues, setFormValues] = useState<FabricFormSubmitProps | null>(
    null
  );
  const [isPopulatedData, setIsPopulatedData] = useState<Array<{
    field: string;
    original: unknown;
    updated: unknown;
  }> | null>(null);

  const fabricRulesConst = useMemo(
    () => fabricFieldRules(measurementUnit),
    [measurementUnit]
  );
  const navigate = useNavigate();

  useEffect(() => {
    // parse fabric values into form values
    const parsedData = parseFabric(
      propsWithDropDownObj,
      fabric as unknown as FabricRecordKey
    );
    form.setFieldsValue(parsedData);

    // Pre-populate images
    setFrontImgUrl(fabric?.image_url_front);
    setBackImgUrl(fabric?.image_url_back);
    setHeaderImgUrl(fabric?.image_url_header);
    setMacroImgUrl(fabric?.image_url_macro);
    setAttachments(fabric?.attachments as typeof attachments);
  }, [fabric, form, propsWithDropDownObj]);

  useEffect(() => {
    //Retreive a Supplier Brand ID if user is editing as an admin
    const _getSupplierBrandIdFromFabricHash = async () => {
      if (fabric.hashed_name) {
        const brandId = await getSupplierBrandIdFromFabricHash(
          fabric.hashed_name
        );
        setSupplierBrandId(brandId);
      }
    };

    if (formType === 'adminEdit') {
      _getSupplierBrandIdFromFabricHash();
    }
  }, [formType, fabric]);

  const uploadBack = (
    <EditFabricImageUpload
      fieldName={i18n.t('fabric_fields:image_url_back')}
      setImage={setBackImg}
      imageUrl={backImgUrl}
      setImageUrl={setBackImgUrl}
      deleteFieldName={'back'}
      setImagesToDelete={setImagesToDelete}
      imagesToDelete={imagesToDelete}
      hasImage={fabric?.image_url_back === backImgUrl}
    />
  );
  const uploadFront = (
    <EditFabricImageUpload
      fieldName={i18n.t('fabric_fields:image_url_front')}
      setImage={setFrontImg}
      imageUrl={frontImgUrl}
      setImageUrl={setFrontImgUrl}
      deleteFieldName={'front'}
      setImagesToDelete={setImagesToDelete}
      imagesToDelete={imagesToDelete}
      hasImage={fabric?.image_url_front === frontImgUrl}
    />
  );
  const uploadHeader = (
    <EditFabricImageUpload
      fieldName={i18n.t('fabric_fields:image_url_header')}
      setImage={setHeaderImg}
      imageUrl={headerImgUrl}
      setImageUrl={setHeaderImgUrl}
      deleteFieldName={'header'}
      setImagesToDelete={setImagesToDelete}
      imagesToDelete={imagesToDelete}
      hasImage={fabric?.image_url_header === headerImgUrl}
    />
  );
  const uploadMacro = (
    <EditFabricImageUpload
      fieldName={i18n.t('fabric_fields:image_url_macro')}
      setImage={setMacroImg}
      imageUrl={macroImgUrl}
      setImageUrl={setMacroImgUrl}
      deleteFieldName={'macro'}
      setImagesToDelete={setImagesToDelete}
      imagesToDelete={imagesToDelete}
      hasImage={fabric?.image_url_macro === macroImgUrl}
    />
  );

  const fileUpload = (
    <FileEditUpload
      attachments={attachments}
      setAttachments={setAttachments}
      setDeleteAttachmentIDs={setDeleteAttachmentIDs}
      setAttachmentsFiles={setAttachmentsFiles}
    />
  );

  const handleFormSubmit = async (values: FabricFormSubmitProps) => {
    setFormValues(values);
    const populatedData = await compareAndReturnDifferences(values);
    setIsPopulatedData(populatedData);
    if (populatedData === null) {
      //Already autofilled review in process
      handleSubmit(values);
      return;
    }
    setIsPopFabricModalOpen(true);
  };

  const handleSubmit = async (fabricData: FabricFormSubmitProps) => {
    if (!fabricData) return;
    let values = fabricData;
    // Parses field values to null if they are empty strings
    values = nullParser(values) as FabricFormSubmitProps;
    // Format creation_date following the format of date from python datetime
    if (values.creation_date) {
      values.creation_date = (values.creation_date as Dayjs).format(
        'YYYY-MM-DD'
      );
    }

    // Replace form field with correct colours field for backend and delete
    // colour_ids intermediary form field
    if ('colour_ids' in values) {
      values.colours = values.colour_ids as number[];
      delete (values as { colour_ids?: number[] }).colour_ids;
    }

    // Check if composite fields are fulfilled
    if (
      !compositeFabricFieldCheck(values as unknown as CompositeFieldCheckProps)
    )
      return;
    // Create formData, only append required images
    const formData = new FormData();
    if (frontImg) formData.append('new_image_front', frontImg);
    if (backImg) formData.append('new_image_back', backImg);
    if (headerImg) formData.append('new_image_header', headerImg);
    if (macroImg) formData.append('new_image_macro', macroImg);

    // // Create a map to track current images
    // This prevents duplicate entries and ensures only deleted images are appended for removal
    const map: Record<string, File | null> = {
      front: frontImg,
      back: backImg,
      macro: macroImg,
      header: headerImg,
    };

    // Iterate over imagesToDelete array and append image types to delete to formData if they are not in the map
    // This ensures that only images marked for deletion and not currently present are appended for removal
    for (const element of imagesToDelete) {
      // If the image is null in the map, it means it was deleted by the user

      if (map[element] === null)
        formData.append('image_types_to_delete', element);
    }

    const attachmentForm = new FormData();
    deleteAttachmentIDs.forEach((id) => {
      // @ts-expect-error because number required by backend
      attachmentForm.append('attachments_ids_to_delete', id);
    });
    attachmentsFiles.forEach((file) => {
      attachmentForm.append('new_attachments_files', file);
    });

    try {
      await editFabric(fabHashedName, values);
      (frontImg ||
        backImg ||
        headerImg ||
        macroImg ||
        imagesToDelete.length !== 0) &&
        (await editFabricImage(fabHashedName, formData));
      if (deleteAttachmentIDs.length > 0 || attachmentsFiles.length > 0) {
        await editAttachment(fabHashedName, attachmentForm);
      }
      message.success(i18n.t('long_messages:message_fabric_updated_success'));
      navigate(-1);
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message);
      }
    }
  };

  const parseFabricTitleWithUnit = (name: string) => {
    return parseFabricTitles(name, measurementUnit);
  };

  return (
    <>
      {isMobile ? (
        <MobileFormWrapper>
          <Form form={form} name="edit" onFinish={handleFormSubmit}>
            {/* Wait for supplierBrandId to be populated if formType is an adminEdit */}
            {formType === 'edit' && (
              <FormItems
                formFields={formFields}
                propsWithDropDownObj={propsWithDropDownObj}
                setPropsWithDropDownObj={setPropsWithDropDownObj}
                supplierBrandId={supplierBrandId}
                formRules={fabricRulesConst}
                parseFieldTitle={parseFabricTitleWithUnit}
              />
            )}
            <CenteredUploadWrapper>
              <TextB1>{i18n.t('fabric_fields:attachments')}</TextB1>
              {fileUpload}
              <TextB3>{i18n.t('long_messages:upload_file_rule')}</TextB3>
              <TextB1>{i18n.t('fabric_fields:image_url_macro')}</TextB1>
              {uploadMacro}
              <TextB1>{i18n.t('fabric_fields:image_url_header')}</TextB1>
              {uploadHeader}
              <TextB1>{i18n.t('fabric_fields:image_url_front')}</TextB1>
              {uploadFront}
              <TextB1>{i18n.t('fabric_fields:image_url_back')}</TextB1>
              {uploadBack}
            </CenteredUploadWrapper>
            <Form.Item>
              <SaveFabricButton type={'save'} />
            </Form.Item>
          </Form>
        </MobileFormWrapper>
      ) : (
        <StyledHorizontalSpace>
          <StyledVerticalSpace>
            <TextB1>{i18n.t('fabric_fields:attachments')}</TextB1>
            {fileUpload}
            <TextB3>{i18n.t('long_messages:upload_file_rule')}</TextB3>
            <TextB1>{i18n.t('fabric_fields:image_url_macro')}</TextB1>
            {uploadMacro}
            <TextB1>{i18n.t('fabric_fields:image_url_header')}</TextB1>
            {uploadHeader}
            <TextB1>{i18n.t('fabric_fields:image_url_front')}</TextB1>
            {uploadFront}
            <TextB1>{i18n.t('fabric_fields:image_url_back')}</TextB1>
            {uploadBack}
          </StyledVerticalSpace>
          <PopulateFabricFieldsModal
            isVisible={isPopFabricModalOpen}
            onCancel={setIsPopFabricModalOpen}
            formValues={formValues}
            handleSubmit={handleSubmit}
            populatedData={isPopulatedData}
            measurementUnit={measurementUnit as string}
          />
          <Space>
            <FormWrapper>
              <Form form={form} name="edit" onFinish={handleFormSubmit}>
                {/* Wait for supplierBrandId to be populated if formType is an adminEdit */}
                {(formType === 'edit' ||
                  (formType === 'adminEdit' && supplierBrandId !== null)) && (
                  <FormItems
                    formFields={formFields}
                    propsWithDropDownObj={propsWithDropDownObj}
                    setPropsWithDropDownObj={setPropsWithDropDownObj}
                    supplierBrandId={supplierBrandId}
                    formRules={fabricRulesConst}
                    parseFieldTitle={parseFabricTitleWithUnit}
                  />
                )}
                <Form.Item>
                  <SaveFabricButton type={'save'} />
                </Form.Item>
              </Form>
            </FormWrapper>
          </Space>
        </StyledHorizontalSpace>
      )}
    </>
  );
}
