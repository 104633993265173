import { DownOutlined } from '@ant-design/icons';
import { Col, Dropdown, Row, Space } from 'antd';
import i18n from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Modal } from '../../components/Modal';
import { TabTitle } from '../../components/TabTitle';
import { ExportSelectedRowsButton } from '../../components/fabric/ExportSelectedRowsButton';
import { TransformedFilterProps } from '../../components/filter/FilterTypes';
import { FabricFilterDrawer } from '../../components/filter/fabric/FabricFilterDrawer';
import { SelectFabricColumns } from '../../components/modal/SelectFabricColumns';
import { SelectMobileFabricColumns } from '../../components/modal/SelectMobileFabricColumns';
import { AddToCollectionModal } from '../../components/modal/collectionModal/AddToCollectionModal';
import { LibraryType } from '../../components/modal/libraryModal/LibraryModalTypes';
import { MoveToLibraryModal } from '../../components/modal/libraryModal/MoveToLibraryModal';
import { intialDataProp } from '../../components/search/SearchTypes';
import { CompareButton } from '../../components/table/CompareButton';
import {
  defaultPageSize,
  TableGeneric,
} from '../../components/table/TableGeneric';
import { columnFields } from '../../components/table/TableTypes';
import { getSelectedColumns } from '../../components/table/fabricTable/ColumnsDesktop';
import { getSelectedMobileColumns } from '../../components/table/fabricTable/ColumnsMobile';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { Color } from '../../elements/base/Color';
import {
  ButtonBasic,
  DropdownButton,
} from '../../elements/buttons/StyledButtons';
import { MainLayout } from '../../layouts/Main';
import { routesNamedDict } from '../../routes';
import { deleteToBinFabric } from '../../utils/backendRequests/fabric/deleteToBinFabric';
import { getFabrics } from '../../utils/backendRequests/library/getFabrics';
import { fabricPropertyArray } from '../../utils/fabrics/parseFabricFields';
import { getTranslatedValuesAndUpdateData } from '../../utils/fabrics/parseFabricValues';
import {
  record,
  RootAuth,
  RootLibrary,
  RootState,
} from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export function FabricLibraryDetailsPage({ title }: TitleProp) {
  const { libHashedName } = useParams(); // lib hashed name received from the router
  const [data, setData] = useState<record[]>([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [translatedData, setTranslatedData] = useState<intialDataProp>([]);
  const [collectionModal, setCollectionModal] = useState({ visible: false });
  const [libraryModal, setLibraryModal] = useState({ visible: false });
  const [columnModal, setColumnModal] = useState({ visible: false });
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();
  const [selectableColumnNames, setSelectableColumnNames] = useState<string[]>(
    []
  );
  const [sortableColumnNames, setSortableColumnNames] = useState<string[]>([]);
  const [filterableFields, setFilterableFields] =
    useState<TransformedFilterProps>({});
  const [filterableColumns, setFilterableColumns] =
    useState<TransformedFilterProps>({});

  // Pagination and Sorter state
  const [tableState, setTableState] = useState({
    current: 1,
    pageSize: defaultPageSize,
    total: 0,
    sortField: '',
    sortOrder: '',
  });

  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  const { measurementUnit, role } = useSelector(
    (state: RootAuth) => state.auth
  );
  const libraries: LibraryType[] = useSelector(
    (state: RootLibrary) => state.fabricLibrary.libraryList
  );
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  // columns to be initialized based on current selection or local storage
  const [columns, setColumns] = useState<columnFields[]>([]);
  const [libraryName, setLibraryName] = useState<string>('');
  // Hashed names of selected fabrics according to the filtered selection
  const selectedHashedNames = useMemo(() => {
    if (data.length === 0) {
      return [];
    } else {
      return selectedRowKeys
        .filter((rowKey) => data[(rowKey as number) - 1] !== undefined)
        .map((rowKey) => data[(rowKey as number) - 1]['hashed_name']);
    }
  }, [data, selectedRowKeys]);

  useEffect(() => {
    if (isMobileFlag) {
      setColumns(
        getSelectedMobileColumns(
          'library',
          measurementUnit,
          selectableColumnNames,
          role,
          account
        )
      );
    } else {
      setColumns(
        getSelectedColumns(
          'library',
          measurementUnit,
          role,
          selectableColumnNames,
          account
        )
      );
    }
  }, [isMobileFlag, measurementUnit, selectableColumnNames]);

  async function getFabricsFromLibrary() {
    const res = await getFabrics(
      String(libHashedName),
      tableState,
      filterableColumns
    );
    if (res) {
      setData(res.fabrics);
      setTableState((prevState) => ({
        ...prevState,
        total: res.fabrics_count,
      }));
      setSelectableColumnNames(res.selectable_columns);
      setSortableColumnNames(res.sortable_columns);
      if (Object.keys(filterableFields).length === 0) {
        setFilterableFields(res.filterable_columns);
      }
    }
  }

  async function getFilteredFabricsFromLibrary() {
    setTableState((prevState) => ({
      ...prevState,
      current: 1,
    }));
    const res = await getFabrics(
      String(libHashedName),
      tableState,
      filterableColumns
    );
    if (res) {
      setData(res.fabrics);
      setTableState((prevState) => ({
        ...prevState,
        current: 1,
        total: res.fabrics_count,
      }));
      setSelectableColumnNames(res.selectable_columns);
      setSortableColumnNames(res.sortable_columns);
    }
  }

  useEffect(() => {
    getFabricsFromLibrary();
  }, [libHashedName]);

  // Set translated data
  useEffect(() => {
    const translationData = getTranslatedValuesAndUpdateData(
      data,
      fabricPropertyArray
    ) as intialDataProp;
    setTranslatedData(translationData);
  }, [data]);

  // Get Filtered data
  useEffect(() => {
    // Check if data has items and filterableColumns is not an empty object
    if (data.length > 0 && Object.keys(filterableColumns).length > 0) {
      getFilteredFabricsFromLibrary();
    }
  }, [filterableColumns]);

  // Resets selected keys when filter updates data
  useEffect(() => {
    setSelectedRowKeys([]);
  }, [data]);

  // Set Library Name
  useEffect(() => {
    const currentLibrary = libraries.find(
      (library: LibraryType) => library.hashed_name === libHashedName
    );
    if (currentLibrary) {
      setLibraryName(currentLibrary.name);
    }
  }, [libraries, libHashedName]);

  // Resets data when all data filtered out
  useEffect(() => {
    if (data.length === 0 && Object.keys(filterableColumns).length > 0) {
      getFabricsFromLibrary();
    }
  }, [filterableColumns]);

  const onSelectChange = (newSelectedRowKeys: number[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange as (
      selectedRowKeys: React.Key[],
      selectedRows: record[]
    ) => void,
  };

  const hasSelected = selectedRowKeys.length > 0;

  // Delete fabrics
  const handleConfirm = async () => {
    const data = { hashed_names: selectedHashedNames };
    deleteToBinFabric(data, navigate, true);
    setModalVisible(false); // Close the modal after deletion
  };

  // Load data on pagination or sorter change
  useEffect(() => {
    if (data.length > 0) getFabricsFromLibrary();
  }, [
    tableState.current,
    tableState.pageSize,
    tableState.sortField,
    tableState.sortOrder,
  ]);

  const items = [
    // Conditional addition of the create button
    ...(role === 'supplier' && account !== 'viewer'
      ? [
          {
            key: 'create-fabric',
            label: (
              <DropdownButton
                onClick={() =>
                  navigate(
                    routesNamedDict.FABRICS_CREATE_FABRIC.replace(
                      ':libHashedName',
                      String(libHashedName)
                    )
                  )
                }
              >
                {i18n.t('buttons:create')}
              </DropdownButton>
            ),
          },
        ]
      : []),
    {
      key: 'filter',
      label: (
        <DropdownButton onClick={() => setFilterOpen(true)}>
          {i18n.t('buttons:filter')}
        </DropdownButton>
      ),
    },
    {
      key: 'compare',
      label: (
        <CompareButton
          filteredData={data}
          selectedRowKeys={selectedRowKeys}
          isMobile={true}
        />
      ),
    },
    {
      key: 'select-columns',
      label: (
        <DropdownButton onClick={() => setColumnModal({ visible: true })}>
          {i18n.t('buttons:select_columns')}
        </DropdownButton>
      ),
    },
    {
      key: 'export',
      label: (
        <ExportSelectedRowsButton
          disabled={!hasSelected}
          data={data}
          selectedRowKeys={selectedRowKeys}
          pageType={libraryName}
        />
      ),
    },
    {
      key: 'move-to-library',
      label: (
        <DropdownButton onClick={() => setLibraryModal({ visible: true })}>
          {i18n.t('buttons:move_to_library')}
        </DropdownButton>
      ),
    },
    {
      key: 'add-to-collection',
      label: account !== 'viewer' && (
        <DropdownButton onClick={() => setCollectionModal({ visible: true })}>
          {i18n.t('buttons:add_to_collection')}
        </DropdownButton>
      ),
    },
    {
      key: 'delete',
      label: (
        <DropdownButton onClick={() => setModalVisible(true)} danger>
          {i18n.t('buttons:delete')}
        </DropdownButton>
      ),
      danger: true,
    },
  ];

  return (
    <MainLayout>
      <HeaderTitle
        title={`${i18n.t('page_titles:fabric_library_page')} "${libraryName}"`}
      />
      <TabTitle title={title} id={libHashedName} />
      <MoveToLibraryModal
        hashedNames={selectedHashedNames}
        modal={libraryModal}
        setModal={setLibraryModal}
        getFabrics={getFabricsFromLibrary}
        currentLibHashedName={String(libHashedName)}
      />
      <AddToCollectionModal
        fabricHashedNames={selectedHashedNames}
        modal={collectionModal}
        setModal={setCollectionModal}
      />
      <Row justify="start" gutter={[16, 16]}>
        <Col span={isMobileFlag ? 24 : undefined}>
          {isMobileFlag ? (
            <Dropdown menu={{ items }}>
              <ButtonBasic>
                <Space>
                  {i18n.t('buttons:Options')}
                  <DownOutlined />
                </Space>
              </ButtonBasic>
            </Dropdown>
          ) : (
            <Space direction="horizontal" size="small">
              {role === 'supplier' && account !== 'viewer' && (
                <ButtonBasic
                  onClick={() =>
                    navigate(
                      routesNamedDict.FABRICS_CREATE_FABRIC.replace(
                        ':libHashedName',
                        String(libHashedName)
                      )
                    )
                  }
                >
                  {i18n.t('buttons:create')}
                </ButtonBasic>
              )}
              <ButtonBasic
                onClick={() => setFilterOpen(true)}
                disabled={
                  data.length === 0 &&
                  Object.keys(filterableColumns).length === 0
                }
              >
                {i18n.t('buttons:filter')}
              </ButtonBasic>
              <CompareButton
                filteredData={translatedData}
                selectedRowKeys={selectedRowKeys}
              />
              <ButtonBasic
                onClick={() => setColumnModal({ visible: true })}
                disabled={data.length === 0}
              >
                {i18n.t('buttons:select_columns')}
              </ButtonBasic>
              <ExportSelectedRowsButton
                disabled={!hasSelected}
                data={translatedData}
                selectedRowKeys={selectedRowKeys}
                pageType={libraryName}
              />
              {account !== 'viewer' && (
                <>
                  <ButtonBasic
                    block
                    disabled={!hasSelected}
                    onClick={() => setLibraryModal({ visible: true })}
                  >
                    {i18n.t('buttons:move_to_library')}
                  </ButtonBasic>
                  <ButtonBasic
                    block
                    disabled={!hasSelected}
                    onClick={() => setCollectionModal({ visible: true })}
                  >
                    {i18n.t('headers:add_to_collection')}
                  </ButtonBasic>
                </>
              )}
              {role === 'supplier' && account !== 'viewer' && (
                <ButtonBasic
                  disabled={!hasSelected}
                  danger
                  onClick={() => setModalVisible(true)}
                >
                  {i18n.t('buttons:delete')}
                </ButtonBasic>
              )}
            </Space>
          )}
          <Modal
            title={i18n.t('headers:confirm_deletion')}
            open={modalVisible}
            onOk={handleConfirm}
            onCancel={() => setModalVisible(false)}
            okText={i18n.t('buttons:yes')}
            cancelText={i18n.t('buttons:no')}
            okButtonProps={{
              style: { backgroundColor: Color.Red },
            }}
          >
            <p>{i18n.t('long_messages:message_confirm_fabrics_deletion')}</p>
          </Modal>
        </Col>
      </Row>
      {isMobileFlag ? (
        <SelectMobileFabricColumns
          modal={columnModal}
          setModal={setColumnModal}
          setSelectedCol={setColumns}
          pageType={'library'}
          selectableColumnNames={selectableColumnNames}
        />
      ) : (
        <SelectFabricColumns
          modal={columnModal}
          setModal={setColumnModal}
          setSelectedCol={setColumns}
          pageType={'library'}
          selectableColumnNames={selectableColumnNames}
        />
      )}
      <Row>
        <FabricFilterDrawer
          initialData={data}
          filterOpen={filterOpen}
          closeFilter={() => setFilterOpen(false)}
          setFilterableColumns={setFilterableColumns}
          filterableFields={filterableFields}
        />
        <TableGeneric
          rowSelection={rowSelection}
          columns={columns}
          data={translatedData}
          isBackendPagination={true}
          setTableState={setTableState}
          tableState={tableState}
          sortableColumns={sortableColumnNames}
        />
      </Row>
    </MainLayout>
  );
}
