import { message } from 'antd';
import { isAxiosError } from 'axios';
import { apiAuth } from '../api/apiAuth';
import { UpdateApprovedBrandsProps } from './AdminTypes';

/**
 * Update approved supplier brands for a given designer brand.
 */
export async function updateApprovedBrands({
  designerBrandId,
  brandIds,
}: UpdateApprovedBrandsProps): Promise<void> {
  const data = {
    designer_brand_id: designerBrandId,
    supplier_brand_ids: brandIds,
  };
  try {
    await apiAuth({
      method: 'post',
      url: 'v1/superuser/replace_approved_supplier_brands',
      body: data,
      headers: {
        'content-type': 'application/json',
      },
    });
    message.success('Approved supplier brands updated successfully.');
  } catch (error) {
    if (isAxiosError(error)) {
      message.error(error.response?.data.msg);
    }
  }
}
