import { format } from 'date-fns';
import i18n from 'i18next';
import { Link } from 'react-router-dom';

import { FrontImageStyled } from '../../../elements/images/StyledImages';
import { ImageSearchListResponse } from '../../../utils/backendRequests/imageSearch/ImageSearchTypes';
import { TextSearchListResponse } from '../../../utils/backendRequests/textSearch/TextSearchTypes';

export const textColumns = [
  {
    dataIndex: 'text_query',
    title: i18n.t('column_titles:text_query'),
    columnType: 'string',
  },
  {
    dataIndex: 'created_at',
    title: i18n.t('column_titles:created_at'),
    columnType: 'date',
    render: (created_at: string) => {
      const date = new Date(created_at);
      return <span>{format(date, "yyyy-MM-dd HH:mm:ss 'UTC'")}</span>;
    },
  },
  {
    dataIndex: 'fabrics_count',
    title: i18n.t('column_titles:number_of_results'),
    columnType: 'number',
  },
  {
    dataIndex: 'hashed_id',
    title: i18n.t('column_titles:action'),
    render: (hashed_id: string, row: ImageSearchListResponse) =>
      row.fabrics_count > 0 ? (
        <Link to={`/search/text/${hashed_id}`}>
          {i18n.t('column_titles:view')}
        </Link>
      ) : (
        '-'
      ),
  },
];

export const imageColumns = [
  {
    dataIndex: 'image_url_1',
    title: i18n.t('column_titles:front_image'),
    render: (url: string) => (
      <FrontImageStyled
        width={'8vw'}
        height={'8vw'}
        src={url}
        alt={i18n.t('column_titles:front_image')}
      />
    ),
  },
  {
    dataIndex: 'image_url_2',
    title: i18n.t('column_titles:back_image'),
    render: (url: string) =>
      url ? (
        <FrontImageStyled
          width={'8vw'}
          height={'8vw'}
          src={url}
          alt={i18n.t('column_titles:back_image')}
        />
      ) : (
        '-'
      ),
  },
  {
    dataIndex: 'created_at',
    title: i18n.t('column_titles:created_at'),
    columnType: 'date',
    render: (created_at: string) => {
      const date = new Date(created_at);
      return <span>{format(date, "yyyy-MM-dd HH:mm:ss 'UTC'")}</span>;
    },
  },
  {
    dataIndex: 'fabrics_count',
    title: i18n.t('column_titles:number_of_results'),
    columnType: 'number',
  },
  {
    dataIndex: 'is_viewed',
    title: i18n.t('column_titles:viewed'),
    columnType: 'boolean',
  },
  {
    dataIndex: 'hashed_id',
    title: i18n.t('column_titles:action'),
    render: (hashed_id: string, row: TextSearchListResponse) =>
      row.fabrics_count > 0 ? (
        <Link to={`/search/image/${hashed_id}`}>
          {i18n.t('column_titles:view')}
        </Link>
      ) : (
        '-'
      ),
  },
];
