import { UserManagementPage } from '../pages/userManagement/UserManagementPage';
import { RouteProps } from './RouteTypes';

export const routesUserManagement: RouteProps[] = [
  {
    path: '/user_management',
    title: 'Users',
    key: 'user_management_page',
    page: UserManagementPage,
  },
];
