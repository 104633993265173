import { TransformedFilterProps } from '../../../components/filter/FilterTypes';
import { FabricTableReqProps, TableStateObj } from '../../types/Types';
import { api } from '../api/api';
import { textSearchRefreshResponse } from './TextSearchTypes';

/**
 * Gets the fabrics from an earlier text search via its text_search_hashed_id
 */
export async function refreshTextFabric(
  text_search_hashed_id: string,
  tableState: TableStateObj,
  filterableColumns: TransformedFilterProps
): Promise<textSearchRefreshResponse> {
  const params: FabricTableReqProps = {
    fabrics_page: tableState.current,
    fabrics_page_size: tableState.pageSize,
  };
  // Add sort parameters only if both sortField and sortOrder have values
  if (tableState.sortField && tableState.sortOrder) {
    params.fabrics_sort_by = tableState.sortField;
    params.fabrics_sort_order = tableState.sortOrder;
  }
  if (filterableColumns) {
    params.fabrics_filter = JSON.stringify(filterableColumns);
  }
  const response = await api({
    method: 'GET',
    url: `v1/text_search/fabric/${text_search_hashed_id}`,
    params: params,
  });
  return response;
}
