import { message } from 'antd';
import { axios } from '../../../plugins/axios';
import { ChangePasswordProps } from './AuthTypes';

/**
 * Change password
 *
 * If successful, the password of the account will be changed.
 */
export async function changePassword({
  currentPassword,
  newPassword,
}: ChangePasswordProps): Promise<boolean> {
  let success = false;

  await axios
    .put(process.env.REACT_APP_BACKEND_AUTH + 'v1/profile/password_update', {
      current_password: currentPassword,
      new_password: newPassword,
    })
    .then((response) => {
      message.success(response.data.msg);
      success = true;
    })
    .catch((error) => {
      message.error(error.response.data.msg);
    });

  return success;
}
