import { message } from 'antd';

import { itemProp } from '../../../components/carousel/CarouselTypes';
import { api } from '../api/api';
/**
 * Finds fabrics similar to the fabric with the provided hashed name
 * which the current user has access to. Is used for the recommendation
 * carousel. Saves result to a state.
 */
export async function getSimilarFabrics(
  hashedName: string
): Promise<itemProp[]> {
  try {
    const response = await api({
      method: 'GET',
      url: `v1/fabric/${hashedName}/similar`,
    });
    return response;
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
