import { Col, Row, Tabs } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';

import { fabricPropertyArray } from '../../../utils/fabrics/parseFabricFields';
import { getTranslatedValuesAndUpdateData } from '../../../utils/fabrics/parseFabricValues';
import { record } from '../../../utils/types/Types';
import { DisplayImages } from '../../DisplayImages';
import { intialDataProp } from '../../search/SearchTypes';
import { HeaderTitle } from '../../title/HeaderTitle';
import { fabricRecordProps } from '../FabricTypes';
import { FabricAttatchmentsTab } from './FabricAttachmentsTab';
import { FabricBasicInfo } from './FabricBasicInfo';
import { FabricRecommendationTab } from './FabricRecommendationTab';
import { FabricTechnicalTab } from './FabricTechnicalTab';

export function FabricDetailsMain({ fabric }: fabricRecordProps) {
  const [images, setImages] = useState<string[]>([]);
  const [attachmentsExist, setAttachmentsExist] = useState<boolean>(false);
  const [hideImages, setHideImages] = useState<boolean>(false);
  const [translatedData, setTranslatedData] = useState<record>(fabric);

  // Set translated data
  useEffect(() => {
    const translationData = getTranslatedValuesAndUpdateData(
      [fabric],
      fabricPropertyArray
    ) as intialDataProp;
    setTranslatedData(translationData[0]);
  }, [fabric]);

  useEffect(() => {
    if (Object.keys(fabric).length !== 0) {
      const newImages = [
        fabric['image_url_macro'] as string,
        fabric['image_url_front'] as string,
        fabric['image_url_back'] as string,
        fabric['image_url_header'] as string,
      ].filter(Boolean); // Remove null or undefined values
      setImages(newImages);

      setAttachmentsExist(
        Array.isArray(fabric.attachments) && fabric.attachments.length > 0
      );

      const allImagesNull = newImages.length === 0;
      setHideImages(allImagesNull);
    }
  }, [fabric]);

  const tabContents = [
    {
      key: '1',
      label: i18n.t('headers:recommendation'),
      children: <FabricRecommendationTab isMobile={false} fabric={fabric} />,
    },
    {
      key: '2',
      label: i18n.t('headers:technical_info'),
      children: <FabricTechnicalTab isMobile={false} fabric={translatedData} />,
    },
    {
      key: '3',
      disabled: !attachmentsExist,
      label: i18n.t('headers:attachments'),
      children: <FabricAttatchmentsTab fabric={fabric} />,
    },
  ];

  return (
    <>
      {fabric.name && (
        <HeaderTitle
          title={`${i18n.t('page_titles:fabric_details_page')} "${
            fabric.name
          }"`}
        />
      )}
      <Row style={{ marginBottom: '32px', paddingTop: '30px' }}>
        {!hideImages && (
          <Col span={12}>
            <DisplayImages ImageArray={images} isMobile={false} />
          </Col>
        )}
        <Col span={12}>
          <FabricBasicInfo fabric={translatedData} />
        </Col>
      </Row>
      <Row>
        <Tabs
          defaultActiveKey="1"
          items={tabContents}
          size={'large'}
          tabBarStyle={{ height: '70px', margin: '10px', width: '100%' }}
        />
      </Row>
    </>
  );
}
