import { message } from 'antd';
import i18n from 'i18next';

import { api } from '../api/api';

/**
 * Creates a new fabric entry.
 */
export async function createFabric(data: FormData): Promise<void> {
  try {
    await api({
      method: 'POST',
      url: 'v1/fabric',
      body: data,
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    message.success(i18n.t('long_messages:message_fabric_created_success'));
  } catch (error) {
    message.error(i18n.t('error_messages:error_fabric_created_failed'));
  }
}
