import { HeaderTitle } from '../../components/title/HeaderTitle';
import { TitleH2 } from '../../elements/typography/TitleH2';
import { MainLayout } from '../../layouts/Main';
import i18n from '../../plugins/i18n';
import { TitleProp } from '../PagesTypes';

export function TrimsPage({ title }: TitleProp) {
  // TODO: Implement Trims Page Fully
  const titleStyle: React.CSSProperties = {};
  titleStyle.fontSize = '75px';
  titleStyle.display = 'flex';
  titleStyle.justifyContent = 'center';
  titleStyle.marginTop = '100px';
  return (
    <MainLayout>
      <HeaderTitle
        title={title}
        subtitle={i18n.t('page_subtitles:trim_page_sub')}
      />
      <TitleH2 style={titleStyle}>
        {i18n.t('long_messages:coming_soon_message')}...
      </TitleH2>
    </MainLayout>
  );
}
