import { message } from 'antd';
import { TransformedFilterProps } from '../../../components/filter/FilterTypes';
import { FabricTableReqProps, TableStateObj } from '../../types/Types';
import { api } from '../api/api';
import { CollectionShow } from './CollectionTypes';

/**
 * Gets all collections available to the user, this depends on their brand.
 */
export async function getSingleCollection(
  id: number,
  tableState: TableStateObj,
  filterableColumns: TransformedFilterProps
): Promise<CollectionShow> {
  try {
    const params: FabricTableReqProps = {
      fabrics_page: tableState.current,
      fabrics_page_size: tableState.pageSize,
    };
    // Add sort parameters only if both sortField and sortOrder have values
    if (tableState.sortField && tableState.sortOrder) {
      params.fabrics_sort_by = tableState.sortField;
      params.fabrics_sort_order = tableState.sortOrder;
    }
    if (filterableColumns) {
      params.fabrics_filter = JSON.stringify(filterableColumns);
    }
    const res = await api({
      method: 'GET',
      url: `v1/collection/${id}`,
      params: params,
    });
    return res;
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
