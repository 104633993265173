import { Button, Form } from 'antd';
import i18n from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { FormWrapper } from '../../../elements/wrapper/FormWrapper';
import { editCertBody } from '../../../utils/backendRequests/certifications/CertificationTypes';
import { editCertification } from '../../../utils/backendRequests/certifications/editCertification';
import { Modal } from '../../Modal';
import { FormItems } from '../../form/FormItems';
import { EditCertificationModalProps } from './CertificationModalTypes';
import { certificationFields } from './certificationFields';
import { certificationFieldRules } from './certificationFormRules';
type dropDownDataType = Record<string, { value: number; label: string }[]>;

export function EditCertificationModal({
  modal,
  setModal,
  record,
  onSuccess,
}: EditCertificationModalProps) {
  const [form] = Form.useForm();
  const [propsWithDropDownObj, setPropsWithDropDownObj] = useState<
    dropDownDataType | Record<string, never>
  >({});

  const formRulesConst = useMemo(
    () => certificationFieldRules(certificationFields),
    []
  );

  // Generic function to translate field titles
  const parseFieldTitle = (fieldName: string) => {
    return i18n.t(`${fieldName}`);
  };

  useEffect(() => {
    /**
     * Converts dropdown's selected value to its corresponding id.
     */
    const _findIdFromMap = (
      propsWithDropDownObj: dropDownDataType,
      key: string,
      value: string
    ): number | null => {
      const possibleValues = propsWithDropDownObj[key];
      const keyValuePair = possibleValues?.find(
        (prop: { value: number; label: string }) =>
          i18n.t(`certification_type_values:${prop.label}`) === value
      );
      return keyValuePair ? Number(keyValuePair.value) : null;
    };

    form.setFieldsValue({
      name: record.name,
      certification_type: _findIdFromMap(
        propsWithDropDownObj,
        'certification_type',
        record.certification_type
      ),
    });
  }, [record, propsWithDropDownObj]);

  const handleSubmit = async (values: editCertBody) => {
    const editResponse = await editCertification(record.name, values);
    if (editResponse) {
      onSuccess();
      setModal({
        visible: false,
      });
    }
  };

  return (
    <Modal
      title={i18n.t('headers:edit_certification')}
      open={modal.visible}
      onCancel={() => setModal({ visible: false })}
      footer={null}
    >
      <FormWrapper>
        <Form form={form} onFinish={handleSubmit}>
          <FormItems
            formFields={certificationFields}
            propsWithDropDownObj={propsWithDropDownObj}
            setPropsWithDropDownObj={setPropsWithDropDownObj}
            supplierBrandId={null}
            formRules={formRulesConst}
            parseFieldTitle={parseFieldTitle}
          />
          <Form.Item wrapperCol={{ offset: 20 }}>
            <Button type="primary" htmlType="submit">
              {i18n.t('buttons:submit')}
            </Button>
          </Form.Item>
        </Form>
      </FormWrapper>
    </Modal>
  );
}
