import { message } from 'antd';

import { api } from '../api/api';

/**
 * Gets possible measurement units.
 */
export const getMeasurementUnits = async (): Promise<
  Record<string, string>
> => {
  try {
    return await api({
      method: 'GET',
      url: `v1/list/measurement_unit_types`,
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
};
