import styled from 'styled-components';

interface LogoWrapperProps {
  $mainlayout?: boolean;
  $authlayout?: boolean;
}

export const LogoWrapper = styled.div<LogoWrapperProps>`
  padding: ${(props) => {
    if (props.$mainlayout === true) {
      return '10px 0px 0px 20px';
    } else if (props.$authlayout === true) {
      return '10px 0px 20px 10px';
    } else {
      return '10px 0px'; // Default padding if neither mainlayout nor authlayout is "true"
    }
  }};
`;
