import { Col, Row, Select } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMeasurementUnit } from '../../plugins/redux/auth';
import { updateUserProfile } from '../../utils/backendRequests/auth/updateUserProfile';
import { getMeasurementUnits } from '../../utils/backendRequests/list/measurementUnits';
import { RootAuth } from '../../utils/types/Types';

const { Option } = Select;

export const MeasurementUnitSelect = () => {
  const dispatch = useDispatch();

  const { measurementUnit } = useSelector((state: RootAuth) => state.auth);
  const [measurementUnitOptions, setMeasurementUnitOptions] = useState<
    Record<string, string>
  >({});

  // Get measurement unit options
  useEffect(() => {
    const getMeasurementOptions = async () => {
      const units = await getMeasurementUnits();
      setMeasurementUnitOptions(units);
    };
    getMeasurementOptions();
  }, []);

  const unitOptions = Object.entries(measurementUnitOptions).map(
    ([key, value]: [string, string]) => (
      <Option key={key} value={value}>
        {i18n.t(`measurement_unit_values:${value}`)}
      </Option>
    )
  );

  const onChangeUnit = async (selectedUnit: string) => {
    const response = await updateUserProfile({
      measurement_unit: selectedUnit,
    });
    dispatch(
      setMeasurementUnit({
        measurementUnit: selectedUnit,
        token: response.token,
      })
    );
  };

  return (
    <Row>
      <Col span={8}>{i18n.t('headers:measurement_unit')}</Col>
      <Col span={16}>
        <Select
          style={{ width: '100%' }}
          value={measurementUnit}
          onChange={onChangeUnit}
        >
          {unitOptions}
        </Select>
      </Col>
    </Row>
  );
};
