import { message } from 'antd';
import { isAxiosError } from 'axios';

import { api } from '../api/api';
import { GetProjectResults, ProjectType } from './ProjectTypes';
/**
 * Makes an API call for available projects the current
 * user can view.
 */
export async function getProjects(): Promise<ProjectType> {
  let results: GetProjectResults = [];
  try {
    const response = await api({
      method: 'GET',
      url: 'v1/project',
    });
    results = response;
  } catch (error) {
    if (isAxiosError(error)) {
      message.error((error.response as { msg?: string })?.msg);
    } else if (error instanceof Error) {
      message.error(error.message);
    }
  }
  return results;
}
