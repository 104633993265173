import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Color } from '../base/Color';
import { FontSize, FontWeight } from '../base/Font';

const _commonStyling = css`
  color: ${Color.P5} !important;
  font-weight: ${FontWeight.Regular};
  font-size: ${FontSize.Body1};
  line-height: 24px;
`;

// Only to be used if navigating to a static external site.
export const ATagB1 = styled.a`
  ${_commonStyling}
`;

export const LinkB1 = styled(Link)`
  ${_commonStyling}
`;
