import { CloseOutlined, MenuOutlined, SearchOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  MobileHeaderSearchButton,
  MobileSearchButton,
} from '../../elements/buttons/StyledButtons';
import { MobileLogoutIcon } from '../../elements/icons/StyledIcons';
import {
  MobileCenterCol,
  MobileHeaderContainer,
  MobileLeftCol,
  MobileLogoContainer,
  MobileRightCol,
} from '../../elements/StyledUI';
import { toggleSidebar } from '../../plugins/redux/common';
import { routesNamedDict } from '../../routes';
import { RootAuth, RootState } from '../../utils/types/Types';
import { ProfileDropDown } from './profileDropDown/ProfileDropDown';

interface HeaderPhoneProps {
  logo: ReactNode;
}

export function HeaderPhone({ logo }: HeaderPhoneProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSidebarCollapsed = useSelector(
    (state: RootState) => state.common.isSidebarCollapsed
  );
  const role = useSelector((state: RootAuth) => state.auth?.role);
  const isAdmin = role === 'admin';

  const handleSearch = () => {
    navigate(routesNamedDict.SEARCH);
  };

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar(!isSidebarCollapsed));
  };

  return (
    <MobileHeaderContainer>
      <MobileLeftCol>
        {!isAdmin && (
          <MobileHeaderSearchButton type="text" onClick={handleToggleSidebar}>
            {isSidebarCollapsed ? <MenuOutlined /> : <CloseOutlined />}
          </MobileHeaderSearchButton>
        )}
      </MobileLeftCol>
      <MobileCenterCol>
        <MobileLogoContainer>
          {logo}
          {!isAdmin && (
            <MobileSearchButton type="primary" onClick={handleSearch}>
              <SearchOutlined style={{ fontSize: '20px' }} />
            </MobileSearchButton>
          )}
        </MobileLogoContainer>
      </MobileCenterCol>
      <MobileRightCol>
        <MobileLogoutIcon>
          <ProfileDropDown isMobile={true} />
        </MobileLogoutIcon>
      </MobileRightCol>
    </MobileHeaderContainer>
  );
}
