import { BulkUploadPage } from '../pages/BulkUploadPage';
import { RouteProps } from './RouteTypes';

export const routesBulkUpload: RouteProps[] = [
  {
    path: '/import',
    title: 'Import',
    key: 'import_page',
    page: BulkUploadPage,
  },
];
