import { createSlice } from '@reduxjs/toolkit';
import { encryptStorage } from '../encryptStorage';

const initialState = {
  name: encryptStorage.getItem('name') || null,
  role: encryptStorage.getItem('role') || null,
  accountType: encryptStorage.getItem('accountType') || null,
  token: encryptStorage.getItem('token') || null,
  brandName: encryptStorage.getItem('brandName') || null,
  measurementUnit: encryptStorage.getItem('measurementUnit') || 'metric',
  hoplunUsername: encryptStorage.getItem('hoplunUsername') || null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutUser: (state) => {
      // Clear Redux State
      state.name = null;
      state.role = null;
      state.accountType = null;
      state.token = null;
      state.brandName = null;
      state.measurementUnit = null;
      state.hoplunUsername = null;
      // Clear Encrypted Storage
      encryptStorage.removeItem('name');
      encryptStorage.removeItem('role');
      encryptStorage.removeItem('accountType');
      encryptStorage.removeItem('token');
      encryptStorage.removeItem('brandName');
      encryptStorage.removeItem('measurementUnit');
      encryptStorage.removeItem('hoplunUsername');
    },
    setUser: (state, action) => {
      const {
        name,
        role,
        accountType,
        token,
        measurementUnit,
        brandName,
        hoplunUsername,
      } = action.payload;

      // Update Redux State
      state.name = name;
      state.role = role;
      state.accountType = accountType;
      state.token = 'Bearer ' + token;
      state.brandName = brandName;
      state.measurementUnit = measurementUnit;
      state.hoplunUsername = hoplunUsername;
      // Update Encrypted Storage
      encryptStorage.setItem('name', name);
      encryptStorage.setItem('role', role);
      encryptStorage.setItem('accountType', accountType);
      encryptStorage.setItem('token', 'Bearer ' + token);
      encryptStorage.setItem('brandName', brandName);
      encryptStorage.setItem('measurementUnit', measurementUnit);
      encryptStorage.setItem('hoplunUsername', hoplunUsername);
    },
    setMeasurementUnit: (state, action) => {
      // Need to update jwt token as well
      const { measurementUnit, token } = action.payload;
      // Update Redux State
      state.measurementUnit = measurementUnit;
      state.token = 'Bearer ' + token;
      // Update Encrypted Storage
      encryptStorage.setItem('measurementUnit', measurementUnit);
      encryptStorage.setItem('token', 'Bearer ' + token);
    },
    setName: (state, action) => {
      const newName = action.payload;
      // Update Redux State
      state.name = newName;
      // Update Encrypted Storage
      encryptStorage.setItem('name', newName);
    },
    setHoplunUsername: (state, action) => {
      const newUsername = action.payload;
      // Update Redux State
      state.hoplunUsername = newUsername;
      // Update Encrypted Storage
      encryptStorage.setItem('hoplunUsername', newUsername);
    },
  },
});

export const authSliceReducer = authSlice.reducer;
export const {
  logoutUser,
  setUser,
  setMeasurementUnit,
  setName,
  setHoplunUsername,
} = authSlice.actions;
