import { selectableDefaultColumnsLabels } from '../../components/table/fabricTable/ColumnsDesktop';

const localStorageKey = 'selectedFabricTableLabels';
const localMobileStorageKey = 'selectedFabricTableLabelsMobile';
/**
 * Retrieves selected labels from local storage or sets default labels if zero exist.
 */
export const getSelectedLabelsFromLocalStorage = (
  isMobile: boolean
): string[] => {
  const storageKey = isMobile ? localMobileStorageKey : localStorageKey;
  const storedLabelsString = localStorage.getItem(storageKey);
  let storedLabels = storedLabelsString ? JSON.parse(storedLabelsString) : null;

  if (storedLabels === null || storedLabels === undefined) {
    storedLabels = selectableDefaultColumnsLabels;
    localStorage.setItem(
      storageKey,
      JSON.stringify(selectableDefaultColumnsLabels)
    );
  }

  return storedLabels;
};

/**
 * Stores passed selected labels in local storage.
 */
export const setSelectedLabelsInStorage = (
  selectedLabels: string[],
  isMobile: boolean
) => {
  const storageKey = isMobile ? localMobileStorageKey : localStorageKey;
  localStorage.setItem(storageKey, JSON.stringify(selectedLabels));
};
