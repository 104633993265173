import { Space } from 'antd';
import i18n from 'i18next';
import { Link } from 'react-router-dom';

import { routesNamedDict } from '../../../routes';
import { LibraryRecord } from '../TableTypes';
import { BinFabricLibraryButton } from './BinFabricLibraryButton';
import { CopyLibraryToCollectionLink } from './CopyLibraryToCollectionLink';
import { EditLib } from './EditLib';

export const columnsMobile = (account: string | null) => [
  {
    dataIndex: 'name',
    key: 'name',
    title: i18n.t('common:name'),
    columnType: 'string',
    render: (name: string) => (
      <span>
        <span style={{ marginLeft: '15px' }}>{name}</span>
      </span>
    ),
  },
  {
    dataIndex: 'id',
    key: 'id',
    title: i18n.t('column_titles:action'),
    render: (_: unknown, record: LibraryRecord) => (
      <Space size="middle">
        <Link
          to={
            routesNamedDict.FABRICS_LIBRARY_ID.replace(/:libHashedName/g, '') +
            record.hashed_name
          }
        >
          {i18n.t('column_titles:view')}
        </Link>
        <EditLib record={record} />
        {account !== 'viewer' && (
          <CopyLibraryToCollectionLink libraryHashedName={record.hashed_name} />
        )}
        {/* <ExportLibraryButton data={record} /> Disabled as approach needs to be changed with regards to pagination*/}
        {account !== 'viewer' && record.is_owner && (
          <BinFabricLibraryButton hashedName={record.hashed_name} />
        )}
      </Space>
    ),
  },
];
