import { DataProp } from './ParserTypes';

import {
  CertificationBinItem,
  CollectionBinItem,
  FabricBinItem,
  LibraryBinItem,
  ProjectBinItem,
  UserUploadBinItem,
} from '../backendRequests/bin/BinTypes';

/**
 * Parses data for the POST request to either restore or permanently delete the selected
 * binned resources.
 *
 * It should be noted currently only one type of data can be parsed at a time since only
 * 1 binned tab is opened at a time.
 */
export const parseBinBody = (
  type: string,
  selectedRowKeys: number[],
  data: DataProp
) => {
  switch (type) {
    case 'libraries': {
      const hashed_names = selectedRowKeys.map(
        (index: number) => (data[index - 1] as LibraryBinItem).hashed_name
      );
      return { hashed_names };
    }
    case 'fabrics': {
      const hashed_names = selectedRowKeys.map(
        (index: number) => (data[index - 1] as FabricBinItem).hashed_name
      );
      return { hashed_names };
    }
    case 'projects': {
      const names = selectedRowKeys.map(
        (index: number) => (data[index - 1] as ProjectBinItem).name
      );
      return { names };
    }
    case 'uploads': {
      const ids = selectedRowKeys.map(
        (index: number) => (data[index - 1] as UserUploadBinItem).id
      );
      return { ids };
    }
    case 'certifications': {
      const names = selectedRowKeys.map(
        (index: number) => (data[index - 1] as CertificationBinItem).name
      );
      return { names };
    }
    case 'collections': {
      const ids = selectedRowKeys.map(
        (index: number) => (data[index - 1] as CollectionBinItem).id
      );
      return { ids };
    }
    default:
      return {};
  }
};
