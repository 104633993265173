import { axiosNoAuth } from './axiosNoAuth';
import { store } from './redux';

const axiosInstance = axiosNoAuth;

// Executes before each request
axiosNoAuth.interceptors.request.use(
  (config) => {
    // Get the latest token from your Redux store
    const state = store.getState();
    const token = state.auth.token;
    // If token exists, set the authorization header
    if (token) {
      config.headers.Authorization = token;
    }

    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

export { axiosInstance as axios };
