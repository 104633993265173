import { useState } from 'react';
import { Link } from 'react-router-dom';

import { updateApprovedBrands } from '../../utils/backendRequests/admin/updateApprovedBrands';
import { Modal } from '../Modal';
import { RemoveApprovedBrandModalWithLinkProps } from './ModalTypes';

export function RemoveApprovedBrandModalWithLink({
  approvedSupplierBrands,
  designerBrandId,
  setApprovedSupplierBrands,
  supplierBrandToRemove,
}: RemoveApprovedBrandModalWithLinkProps) {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const showConfirmationModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const hideConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const removeApprovedBrand = () => {
    // Gets the ID of the brand that needs to be removed
    const idToRemove = supplierBrandToRemove.key;
    // Converts brands (objects) to user IDs (int) for API call
    const approvedBrandIds = approvedSupplierBrands.map((brand) => brand.key);
    // Get array index of ID to remove
    const indexToRemove = approvedBrandIds.indexOf(idToRemove);
    // Removes the element at the index corresponding to the ID
    approvedBrandIds.splice(indexToRemove, 1);
    updateApprovedBrands({
      designerBrandId: designerBrandId,
      brandIds: approvedBrandIds,
    }).then(() => {
      hideConfirmationModal();
      // Updates the list of approved brands after removing the brand
      const updatedApprovedBrands = approvedSupplierBrands.filter(
        (brand) => brand.key !== idToRemove
      );
      setApprovedSupplierBrands(updatedApprovedBrands);
    });
  };

  return (
    <>
      {/* TODO: Link should be replaced with a Button Instead */}
      <Link to="#" onClick={showConfirmationModal}>
        Remove
      </Link>
      <Modal
        title={`Confirm removal of ${supplierBrandToRemove.value}?`}
        open={isConfirmationModalOpen}
        okText="Yes"
        onOk={removeApprovedBrand}
        onCancel={hideConfirmationModal}
      ></Modal>
    </>
  );
}
