import { ColoursPage } from '../pages/colours/ColoursPage';
import { RouteProps } from './RouteTypes';

export const routesColours: RouteProps[] = [
  {
    path: '/colours',
    title: 'Colours',
    key: 'colours_page',
    page: ColoursPage,
  },
];
