import styled from 'styled-components';

import { Color } from '../base/Color';

export const UploadTitleWrapper = styled.div`
  color: ${Color.P5};
  font-size: 20px;
  line-height: 30px;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
