import { message } from 'antd';

import { routesNamedDict } from '../../../routes';
import { record } from '../../types/Types';
import { GetFabricsResponse, Navigate } from './FabricTypes';
import { getFabricsByNames } from './getFabricNames';
/**
 * Gets fabric details, from its hashed name.
 * If an error is returned, redirect to 404 page.
 */
export async function getFabricDetails(
  hashedName: string,
  navigate: Navigate = () => {}
): Promise<record | void> {
  return getFabricsByNames([hashedName])
    .then((res: GetFabricsResponse) => {
      return res.fabrics[0];
    })
    .catch((error) => {
      message.error(error.message);
      navigate('/' + routesNamedDict.NOT_FOUND);
    });
}
