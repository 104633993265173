import { message } from 'antd';

import { api } from '../api/api';
import { SharedDesignerBrandsObject } from '../collection/CollectionTypes';

/**
 * Only usable by supplier users.
 * Gets an object of Designer Brands, which have
 * approved the Supplier Brand associated with the current Supplier user.
 *
 * Object contains Designer Brand IDs and their name.
 */
export const getApprovedByDesignerBrands =
  async (): Promise<SharedDesignerBrandsObject> => {
    try {
      return await api({
        method: 'GET',
        url: `v1/list/approved_by_designer_brand_ids`,
      });
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message);
      }
      return Promise.reject(error);
    }
  };
