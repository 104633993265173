import { message } from 'antd';

import { api } from '../api/api';

/**
 * Return email notification status for current user as either true or false.
 */

export type UserProps = {
  email: string;
  id: number;
  is_active: boolean;
  is_email_notified: boolean;
  is_2fa_enabled: boolean;
  account_type: string;
  measurement_unit: string;
  name: string;
};

export async function getCurrentUser(): Promise<UserProps> {
  try {
    return await api({
      method: 'GET',
      url: `v1/user/me`,
    }).then((res: UserProps) => {
      return res;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
