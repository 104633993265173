import { List } from 'antd';
import i18n from 'i18next';
import { ButtonBasic } from '../../../elements/buttons/StyledButtons';
import { CollectionListItem } from '../../../utils/backendRequests/collection/CollectionTypes';

type CollectionListProps = {
  handleSave: (item: CollectionListItem) => void;
  collections: CollectionListItem[];
};

export function CollectionList({
  handleSave,
  collections,
}: CollectionListProps) {
  return (
    <List
      dataSource={collections}
      renderItem={(item) => (
        <List.Item
          actions={[
            <ButtonBasic onClick={() => handleSave(item)}>
              {i18n.t('buttons:save')}
            </ButtonBasic>,
          ]}
        >
          <List.Item.Meta title={item.name} />
        </List.Item>
      )}
    />
  );
}
