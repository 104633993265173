import { Button } from 'antd';
import i18n from 'i18next';
import { useState } from 'react';

import { useSelector } from 'react-redux';
import { Color } from '../../../elements/base/Color';
import { deleteToBinFabricLib } from '../../../utils/backendRequests/library/deleteToBinFabricLib';
import { RootAuth } from '../../../utils/types/Types';
import { Modal } from '../../Modal';
import { BinFabricLibraryButtonProp } from '../TableTypes';

export function BinFabricLibraryButton({
  hashedName,
}: BinFabricLibraryButtonProp) {
  const [modalVisible, setModalVisible] = useState(false);
  const account = useSelector((state: RootAuth) => state.auth.accountType);

  const handleConfirm = async () => {
    await deleteToBinFabricLib(hashedName);
    setModalVisible(false); // Close the modal after deletion
  };

  return (
    <>
      {account !== 'viewer' && (
        <Button danger onClick={() => setModalVisible(true)}>
          {i18n.t('buttons:delete')}
        </Button>
      )}
      <Modal
        title={i18n.t('headers:confirm_deletion')}
        open={modalVisible}
        onOk={handleConfirm}
        onCancel={() => setModalVisible(false)}
        okText={i18n.t('buttons:yes')}
        cancelText={i18n.t('buttons:no')}
        okButtonProps={{
          style: { backgroundColor: Color.Red },
        }}
      >
        <p>{i18n.t('long_messages:message_confirm_library_deletion')}</p>
      </Modal>
    </>
  );
}
