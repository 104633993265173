import { Tooltip } from 'antd';
import i18n from 'i18next';
import {
  CertificationInProgressStatusIcon,
  CertificationInvalidStatusIcon,
  CertificationVerifiedStatusIcon,
} from '../../elements/certifications/StyledCertifications';

type certStatusProps = {
  status: string | null;
  showNull?: boolean;
};

export const CertStatusRender = ({
  status,
  showNull = false,
}: certStatusProps) => {
  if (status === 'verified')
    return (
      <Tooltip title={i18n.t(`certification_status_values:${status}`)}>
        <CertificationVerifiedStatusIcon />
      </Tooltip>
    );
  else if (status === 'invalid')
    return (
      <Tooltip title={i18n.t(`certification_status_values:${status}`)}>
        <CertificationInvalidStatusIcon />
      </Tooltip>
    );
  else if (status === 'verification_in_progress')
    return (
      <Tooltip title={i18n.t(`certification_status_values:${status}`)}>
        <CertificationInProgressStatusIcon />
      </Tooltip>
    );
  else
    return showNull ? (
      <Tooltip title={i18n.t(`common:file_not_found`)}>
        <CertificationInvalidStatusIcon />
      </Tooltip>
    ) : null;
};
