import { message } from 'antd';
import i18n from 'i18next';

import { api } from '../api/api';
/**
 * Move fabrics in a library, specified by name. Adds new Fabrics to
 * an existing Fabric Library
 */
export async function moveFabrics(
  hashedName: string,
  newFabrics: string[] | null = null
): Promise<boolean> {
  const data = {
    new_library_hashed_name: hashedName,
    fabric_hashed_names: newFabrics,
  };
  try {
    await api({
      method: 'PUT',
      url: `v1/fabric/update_library`,
      body: data,
    });
    message.success(
      i18n.t('long_messages:message_fabric_moved_success', {
        count: newFabrics?.length,
      })
    );
    return true;
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return false;
  }
}
