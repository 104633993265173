import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';

import { TabTitle } from '../../components/TabTitle';
import { ProfileCards } from '../../components/profile/ProfileCards';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { MainLayout } from '../../layouts/Main';
import i18n from '../../plugins/i18n';
import { RootState } from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export function SettingsPage({ title }: TitleProp) {
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  return (
    <MainLayout>
      <HeaderTitle
        title={i18n.t('page_titles:settings_page')}
        isCenter={true}
      />
      <TabTitle title={title} />
      <Row justify="center">
        <Col span={isMobileFlag ? 24 : 16}>
          <ProfileCards />
        </Col>
      </Row>
    </MainLayout>
  );
}
