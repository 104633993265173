import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { containedFabricProjectsProps } from '../../../utils/backendRequests/project/ProjectTypes';
import { addProjectFabrics } from '../../../utils/backendRequests/project/addProjectFabrics';
import { copyCollectionFabricsToProject } from '../../../utils/backendRequests/project/copyCollectionFabricsToProject';
import { copyProjectToExistingProject } from '../../../utils/backendRequests/project/copyProjectToExistingProject';
import { getContainingFabricsProjects } from '../../../utils/backendRequests/project/getContainingFabricsProjects';
import { RootProject } from '../../../utils/types/Types';
import { Modal } from '../../Modal';
import { CreateProjectModal } from './CreateProjectModal';
import { ProjectList } from './ProjectList';
import { AddProject, AddToProjectModalProps } from './ProjectModalTypes';

export function AddToProjectModal({
  modal,
  setModal,
  hashedNames = [],
  collectionId = null,
  srcProjectName = null,
}: AddToProjectModalProps) {
  const projects = useSelector(
    (state: RootProject) => state.project.projectList
  );
  const [newProjectModal, setNewProjectModal] = useState({ visible: false });
  const [containedFabricProjects, setContainedFabricProjects] =
    useState<containedFabricProjectsProps>();

  const getcontainedFabricProjects = async () => {
    if (hashedNames.length !== 0) {
      const containedProjects = await getContainingFabricsProjects({
        fabricsHashedNames: hashedNames,
      });
      setContainedFabricProjects(containedProjects);
    } else if (srcProjectName !== null) {
      const containedProjects = await getContainingFabricsProjects({
        fabricsSourceProjectName: srcProjectName,
      });
      setContainedFabricProjects(containedProjects);
    }
  };

  useEffect(() => {
    if (modal.visible) {
      getcontainedFabricProjects();
    }
  }, [modal.visible]);

  /**
   * Filter out projects that do not belong to the user
   * to prevent unauthorized addition of fabrics.
   */
  const ownedProjects = projects.filter(
    (project: AddProject) => project.is_owner
  );

  // Close modal
  const handleCancel = () => {
    setModal({
      visible: false,
    });
  };

  // Open create new project modal to create project and save selected fabrics to it. Also closes the AddToProjectModal
  const createNewProject = () => {
    setNewProjectModal({
      visible: true,
    });
    setModal({
      visible: false,
    });
  };

  // Saves fabrics to selected existing project
  const handleSaveToExisting = async (project: AddProject) => {
    /**
     * Either:
     * - Adds all the fabrics in a collection to an existing project,
     * - Adds all given fabrics to an existing project.
     */

    if (collectionId !== null) {
      copyCollectionFabricsToProject(project.name, collectionId).then(() => {
        setModal({
          visible: false,
        });
      });
    } else if (srcProjectName !== null) {
      copyProjectToExistingProject(srcProjectName, project.name).then(() => {
        setModal({
          visible: false,
        });
      });
    } else {
      addProjectFabrics(project.name, hashedNames).then(() => {
        setModal({
          visible: false,
        });
      });
    }
  };

  return (
    <>
      <Modal
        title={i18n.t('headers:add_to_project')}
        open={modal.visible}
        okText={i18n.t('headers:new_project')}
        onOk={createNewProject}
        onCancel={handleCancel}
      >
        <ProjectList
          handleSave={handleSaveToExisting}
          projects={ownedProjects}
          containedFabricProjects={
            containedFabricProjects?.project_names as string[]
          }
        />
      </Modal>
      <CreateProjectModal
        modal={newProjectModal}
        setModal={setNewProjectModal}
        setParentModal={setModal}
        fabricHashedNames={hashedNames}
        collectionId={collectionId}
        srcProjectName={srcProjectName}
      />
    </>
  );
}
