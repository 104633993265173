import styled from 'styled-components';

import { Color } from '../base/Color';
import { FontSize, FontWeight } from '../base/Font';

export const TitleH2 = styled.h2`
  color: ${Color.Logo} !important;
  font-size: ${FontSize.H2};
  font-weight: ${FontWeight.SemiBold}
  line-height: 54px;
`;
