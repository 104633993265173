import styled from 'styled-components';

import { Color } from '../base/Color';
import { FontSize, FontWeight } from '../base/Font';

export const SubTitleH6 = styled.p`
  color: ${Color.G7} !important;
  font-size: ${FontSize.H6};
  font-weight: ${FontWeight.SemiBold};
  line-height: 30px;
  margin-bottom: 0px;
`;
