import { message } from 'antd';
import { api } from '../api/api';
import { SupplierUserValues } from './AdminTypes';

/**
 * Updates an existing supplier user with new user
 * values submitted form a form using the ID to update
 */
export async function editSupplierUser(
  user: SupplierUserValues,
  userId: string
): Promise<boolean> {
  try {
    return await api({
      method: 'PUT',
      url: `v1/superuser/user/supplier/${userId}`,
      body: user,
    }).then(() => {
      message.success('Supplier User successfully updated.');
      return true;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return false;
  }
}
