import styled from 'styled-components';

import { Color } from '../base/Color';

export const UploadWrapper = styled.div`
  border: 2px dashed ${Color.P3};
  border-radius: 12px;
  display: flex;
  height: 200px;
  justify-content: center;
  width: 200px;
`;
