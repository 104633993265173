export type FooterLink = {
  title: string;
  path: string;
};

export const delvifyFooterURLs: FooterLink[] = [
  {
    title: 'Delvify',
    path: 'https://delvify.ai/',
  },
  {
    title: 'Terms of Service',
    path: 'https://delvify.ai/legal/',
  },
  {
    title: 'Privacy Policy',
    path: 'https://delvify.ai/privacy-policy/',
  },
  {
    title: 'Contact Us',
    path: 'mailto:hello@delvify.ai',
  },
];
