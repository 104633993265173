import i18n from 'i18next';
import { useEffect, useState } from 'react';

import { addCollectionFabrics } from '../../../utils/backendRequests/collection/addCollectionFabrics';
import { CollectionListItem } from '../../../utils/backendRequests/collection/CollectionTypes';
import { copyLibraryFabricsToCollection } from '../../../utils/backendRequests/collection/copyLibraryFabricsToCollection';
import { getCollections } from '../../../utils/backendRequests/collection/getCollections';
import { Setter } from '../../../utils/types/Types';
import { Modal } from '../../Modal';
import { CollectionList } from './CollectionList';
import { CreateCollectionModal } from './CreateCollectionModal';

type AddToCollectionModalProps = {
  modal: { visible: boolean };
  setModal: Setter<{ visible: boolean }>;
  fabricHashedNames?: string[];
  libraryHashedName?: string | null;
};

export function AddToCollectionModal({
  modal,
  setModal,
  fabricHashedNames = [],
  libraryHashedName = null,
}: AddToCollectionModalProps) {
  const [newCollectionModal, setNewCollectionModal] = useState({
    visible: false,
  });
  const [collections, setCollections] = useState<CollectionListItem[]>([]);

  useEffect(() => {
    const fetchCollections = async () => {
      await getCollections().then((response) => {
        setCollections(response);
      });
    };

    fetchCollections();
  }, []);

  // Close modal
  const handleCancel = () => {
    setModal({
      visible: false,
    });
  };

  // Open create new collection modal to create collection and save selected fabrics to it. Also closes the AddToCollectionModal
  const createNewCollection = () => {
    setNewCollectionModal({
      visible: true,
    });
    setModal({
      visible: false,
    });
  };

  // Saves fabrics to selected existing collection
  const handleSaveToExisting = async (collection: CollectionListItem) => {
    /**
     * Either:
     * - Adds all the fabrics in a fabric library to an existing collection.
     * - Adds the given fabrics to an existing collection.
     */

    libraryHashedName !== null
      ? await copyLibraryFabricsToCollection(
          collection.id,
          libraryHashedName
        ).then(() => {
          setModal({
            visible: false,
          });
        })
      : await addCollectionFabrics(collection.id, fabricHashedNames).then(
          () => {
            setModal({
              visible: false,
            });
          }
        );
  };

  return (
    <>
      <Modal
        title={i18n.t('headers:add_to_collection')}
        open={modal.visible}
        okText={i18n.t('headers:new_collection')}
        onOk={createNewCollection}
        onCancel={handleCancel}
      >
        <CollectionList
          handleSave={handleSaveToExisting}
          collections={collections}
        />
      </Modal>
      <CreateCollectionModal
        modal={newCollectionModal}
        setModal={setNewCollectionModal}
        setParentModal={setModal}
        fabricHashedNames={fabricHashedNames}
        libraryHashedName={libraryHashedName}
        getData={() => {}}
      />
    </>
  );
}
