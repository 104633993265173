import i18n from 'i18next';
import { Link, useLocation } from 'react-router-dom';
import { EditFabricLinkProps } from '../FabricTypes';

export function EditFabricLink({ pageType, record }: EditFabricLinkProps) {
  const { pathname } = useLocation();

  let linkTo;
  switch (pageType) {
    case 'search':
      linkTo = `/search/${record.hashed_name}/edit`;
      break;
    case 'all_fabrics':
      linkTo = `/all_fabrics/${record.hashed_name}/edit`;
      break;
    default:
      linkTo = `${pathname}/${record.hashed_name}/edit`;
  }

  return <Link to={linkTo}>{i18n.t('buttons:edit')}</Link>;
}
