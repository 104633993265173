import { Checkbox, Col, InputNumber, Row, Slider } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import i18n from 'i18next';

import { NumberRangeFilterProps } from '../FilterTypes';

export function NumberRangeFilter({
  range,
  field,
  currentFilterValues,
  setCurrentFilterValues,
  updateFilteredData,
}: NumberRangeFilterProps) {
  /**
   * Any time a number value is changed or after the slider has been let go
   * after applying changes, this function is called.
   * The new filter values are set to the currentFilterValues state
   * and the updateFilteredData function is called to update the data
   * which gets filtered.
   *
   * *Not to be confused with the _onNumberUpdate function which does not apply
   * the filter and only updates the values which would be used.
   */
  const _onApplyFilter = (maxMinValues: number[]) => {
    const newFilterValues = {
      ...currentFilterValues,
      [field]: {
        ...currentFilterValues[field],
        values: maxMinValues,
      },
    };
    setCurrentFilterValues(newFilterValues);
    if (updateFilteredData) updateFilteredData(newFilterValues);
  };

  /**
   * Called any time a change to the slider occurs to update the
   * filter values. It does not update the data which gets filtered.
   *
   * *Not to be confused with the _onApplyFilter function which updates and applies
   * the filter values.
   */
  const _onNumberUpdate = (maxMinValues: number[]) => {
    const newFilterValues = {
      ...currentFilterValues,
      [field]: {
        ...currentFilterValues[field],
        values: maxMinValues,
      },
    };
    setCurrentFilterValues(newFilterValues);
  };

  const onCheckboxUpdate = (event: CheckboxChangeEvent) => {
    const newFilterValues = {
      ...currentFilterValues,
      [field]: {
        ...currentFilterValues[field],
        includeNull: event.target.checked,
      },
    };
    setCurrentFilterValues(newFilterValues);
    if (updateFilteredData) updateFilteredData(newFilterValues);
  };

  return range.length !== 2 ? (
    <div>{i18n.t('long_messages:null_data_filter')}</div>
  ) : (
    <div style={{ padding: '10px' }}>
      <Slider
        max={range[1]}
        min={range[0]}
        onChange={_onNumberUpdate}
        onChangeComplete={_onApplyFilter}
        range={{ draggableTrack: true }}
        value={[
          Number(currentFilterValues[field].values[0]),
          Number(currentFilterValues[field].values[1]),
        ]}
      />
      <Row justify="space-between">
        <Col style={{ width: '50%', paddingRight: '5px' }}>
          <p>{i18n.t('buttons:min')}</p>
          <InputNumber
            min={range[0]}
            max={Number(currentFilterValues[field].values[1])}
            onChange={(minNumber: number | null) => {
              if (minNumber !== null) {
                _onApplyFilter([
                  Number(minNumber),
                  Number(currentFilterValues[field].values[1]),
                ]);
              }
            }}
            value={Number(currentFilterValues[field].values[0])}
          />
        </Col>
        <Col style={{ width: '50%', paddingLeft: '5px' }}>
          <Row justify="end">
            <p>{i18n.t('buttons:max')}</p>
          </Row>
          <Row justify="end">
            <InputNumber
              min={currentFilterValues[field].values[0]}
              max={range[1]}
              onChange={(maxNumber) => {
                if (maxNumber !== null) {
                  _onApplyFilter([
                    Number(currentFilterValues[field].values[0]),
                    Number(maxNumber),
                  ]);
                }
              }}
              style={{ direction: 'rtl' }}
              value={currentFilterValues[field].values[1]}
            />
          </Row>
        </Col>
      </Row>
      {!currentFilterValues[field].required && ( // Do not show include null checkbox if the field is required
        <Row style={{ paddingTop: '10px' }}>
          <Checkbox
            checked={currentFilterValues[field].includeNull}
            onChange={onCheckboxUpdate}
          >
            {i18n.t('long_messages:show_null_rows')}
          </Checkbox>
        </Row>
      )}
    </div>
  );
}
