import { Row } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';

import { CreateUserUploadsModal } from '../../components/modal/CreateUserUploadsModal';
import { UserUploadsList } from '../../components/table/userUploadsList/UserUploadsList';
import { TabTitle } from '../../components/TabTitle';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { ButtonBasic } from '../../elements/buttons/StyledButtons';
import { MainLayout } from '../../layouts/Main';
import {
  getAvailableUserUploads,
  uploadResponse,
} from '../../utils/backendRequests/userUploads/getUserUploads';
import { TitleProp } from '../PagesTypes';

export function UserUploadsListPage({ title }: TitleProp) {
  const [data, setData] = useState<uploadResponse>([]);
  const [modal, setModal] = useState({ visible: false });

  const newOnClick = () => {
    {
      setModal({ visible: true });
    }
  };

  const getUserUploads = async () => {
    const userUploadData = await getAvailableUserUploads();
    setData(userUploadData);
  };

  useEffect(() => {
    getUserUploads();
  }, []);

  return (
    <MainLayout>
      <HeaderTitle
        title={i18n.t('page_titles:user_uploads_page')}
        subtitle={i18n.t('page_subtitles:user_uploads_sub')}
      />
      <TabTitle title={title} />
      <Row justify="start">
        <ButtonBasic onClick={newOnClick}>
          {i18n.t('buttons:create')}
        </ButtonBasic>
      </Row>
      <UserUploadsList
        title={title}
        data={data}
        getUserUploads={getUserUploads}
      />
      <CreateUserUploadsModal
        modal={modal}
        onSuccess={getUserUploads}
        setModal={setModal}
      />
    </MainLayout>
  );
}
