import { UserOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import i18n from 'i18next';

import { RemoveSharedProjectModalWithLink } from '../../modal/RemoveSharedProjectModalWithLink';
import { SharedUsersProps } from '../TableTypes';

export function SharedUsers({
  sharedUsers,
  projectName,
  setSharedUsers,
}: SharedUsersProps) {
  return (
    <Space direction="vertical" size="small">
      <p style={{ margin: '0' }}>{i18n.t('headers:users_with_access')}</p>
      {sharedUsers.map((user, index: number) => (
        <Space direction="vertical" size="small" key={index}>
          <Space style={{ height: '2rem' }}>
            <UserOutlined style={{ fontSize: '22px' }} />
            <p style={{ fontWeight: 'normal' }}>{user.value}</p>
            <RemoveSharedProjectModalWithLink
              userToRemove={user}
              sharedUsers={sharedUsers}
              projectName={projectName}
              setSharedUsers={setSharedUsers}
            />
          </Space>
        </Space>
      ))}
    </Space>
  );
}
