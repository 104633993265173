import { FilterValues } from '../FilterTypes';

export const createInitialFilterValues = (role?: string) => {
  const initialFilterValues: FilterValues = {
    created_at: {
      type: 'date',
      values: [],
      includeNull: true,
      required: true,
    },
    ...(role === 'supplier'
      ? {}
      : {
          owner: {
            type: 'checkbox',
            values: [],
            includeNull: true,
            required: true,
          },
        }),
  };
  return initialFilterValues;
};
