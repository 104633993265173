import { Locale } from 'antd/es/locale';

import en_US from 'antd/es/locale/en_US';
import es_ES from 'antd/es/locale/es_ES';
import id_ID from 'antd/es/locale/id_ID';
import ja_JP from 'antd/es/locale/ja_JP';
import tr_TR from 'antd/es/locale/tr_TR';
import vi_VN from 'antd/es/locale/vi_VN';
import zh_CN from 'antd/es/locale/zh_CN';
import zh_HK from 'antd/es/locale/zh_HK';

const localStorageKey = 'languageString';

// Gets the language string from local storage. If it doesn't exist, set it to en_US
export const getLanguageLocalStorage = (): string => {
  let languageValue = localStorage.getItem(localStorageKey);

  if (languageValue === null || languageValue === undefined) {
    languageValue = 'en_US';
    localStorage.setItem(localStorageKey, 'en_US');
  }

  return languageValue;
};

// Sets the language string in local storage
export const setLanguageInStorage = (selectedLanguageValue: string): void => {
  localStorage.setItem(localStorageKey, selectedLanguageValue);
};

// Controls the language choices available.
export const languageChoices: Record<string, Locale> = {
  en_US: en_US,
  zh_CN: zh_CN,
  zh_HK: zh_HK,
  ja_JP: ja_JP,
  id_ID: id_ID,
  vi_VN: vi_VN,
  es_ES: es_ES,
  tr_TR: tr_TR,
};

// Returns the language module based on the language string for antD ConfigProvider
export const getLocaleConfig = (language: string): Locale => {
  let languageModule;

  try {
    languageModule = languageChoices[language];
  } catch {
    console.error('Error getting language module');
    languageModule = en_US;
  }
  return languageModule;
};
