import { Button, Slider } from 'antd';
import { useEffect, useState } from 'react';
import ReactCrop, { PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {
  CropperPropertiesContainer,
  ImageCropperUpload,
  ImageUploadPreviewBox,
  ImageUploadPreviewContainer,
  RotationSlider,
} from '../../elements/images/StyledImages';
import i18n from '../../plugins/i18n';
import { Setter } from '../../utils/types/Types';
import { Modal } from '../Modal';

interface ImageUploadCropperModalProps {
  setImage: Setter<File | null>;
  imageUrl: string | null;
  setCroppedImageUrl: Setter<string | null>;
  setScaledImageUrl: Setter<string | null>;
  originalImageFile: File | null;
  scaledImageUrl: string | null;
  setImageUrl: Setter<string | null>;
  setModalVisible: Setter<boolean>;
  modalVisible: boolean;
}

const DEFAULT_PREVIEW_WIDTH = 300;
const DEFAULT_PREVIEW_HEIGHT = 300;

export function ImageCropperModal({
  setImage,
  imageUrl,
  setCroppedImageUrl,
  setScaledImageUrl,
  originalImageFile,
  scaledImageUrl,
  setImageUrl,
  setModalVisible,
  modalVisible,
}: ImageUploadCropperModalProps) {
  const [crop, setCrop] = useState<PixelCrop>({
    unit: 'px',
    width: DEFAULT_PREVIEW_WIDTH,
    height: DEFAULT_PREVIEW_HEIGHT,
    x: 0,
    y: 0,
  });
  const [rotation, setRotation] = useState<number>(0);

  useEffect(() => {
    if (imageUrl === null) {
      setCroppedImageUrl(null);
      setScaledImageUrl(null);
      setCrop({
        unit: 'px',
        width: DEFAULT_PREVIEW_WIDTH,
        height: DEFAULT_PREVIEW_HEIGHT,
        x: 0,
        y: 0,
      });
      setImage(null);
    }
  }, [imageUrl, setImage]);

  const getCroppedImg = (
    crop: PixelCrop,
    rotation: number,
    imageFile: File
  ): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Calculate the safe area for rotation
          const safeArea = Math.max(image.width, image.height) * 2;
          canvas.width = safeArea;
          canvas.height = safeArea;

          // Set the background color to #f5f5f5
          ctx!.fillStyle = '#f5f5f5';
          ctx!.fillRect(0, 0, canvas.width, canvas.height);

          ctx!.translate(safeArea / 2, safeArea / 2);
          ctx!.rotate((rotation * Math.PI) / 180);
          ctx!.translate(-safeArea / 2, -safeArea / 2);

          // Draw the rotated image on the canvas
          ctx!.drawImage(
            image,
            safeArea / 2 - image.width / 2,
            safeArea / 2 - image.height / 2
          );

          // Calculate scaling factor
          const previewWidth = DEFAULT_PREVIEW_WIDTH;
          const previewHeight = DEFAULT_PREVIEW_HEIGHT;
          const imageAspect = image.width / image.height;
          const previewAspect = previewWidth / previewHeight;

          let displayedWidth, displayedHeight;
          if (imageAspect > previewAspect) {
            displayedWidth = previewWidth;
            displayedHeight = previewWidth / imageAspect;
          } else {
            displayedHeight = previewHeight;
            displayedWidth = previewHeight * imageAspect;
          }

          const scaleX = image.width / displayedWidth;
          const scaleY = image.height / displayedHeight;

          // Scale crop values based on the original image size
          const scaledCropX =
            (crop.x - (previewWidth - displayedWidth) / 2) * scaleX;
          const scaledCropY =
            (crop.y - (previewHeight - displayedHeight) / 2) * scaleY;
          const scaledCropWidth = crop.width * scaleX;
          const scaledCropHeight = crop.height * scaleY;

          // Get the image data of the cropped area
          const data = ctx!.getImageData(
            safeArea / 2 - image.width / 2 + scaledCropX,
            safeArea / 2 - image.height / 2 + scaledCropY,
            scaledCropWidth,
            scaledCropHeight
          );

          // Set the canvas to the size of the cropped area
          canvas.width = scaledCropWidth;
          canvas.height = scaledCropHeight;

          // Draw the cropped image on the canvas
          ctx!.putImageData(data, 0, 0);

          // Convert the canvas to a Blob and resolve the Promise with a URL
          canvas.toBlob((blob) => {
            if (blob) {
              const croppedUrl = URL.createObjectURL(blob);
              resolve(croppedUrl);
            }
          }, 'image/jpeg');
        };
      };
      reader.readAsDataURL(imageFile);
    });
  };

  const getCroppedFile = (crop: PixelCrop, fileName: string): Promise<File> => {
    return new Promise((resolve) => {
      if (!originalImageFile) {
        throw new Error(i18n.t('long_messages:error_image_upload_failed'));
      }
      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Calculate the displayed size of the image in the preview
          const previewWidth = DEFAULT_PREVIEW_WIDTH;
          const previewHeight = DEFAULT_PREVIEW_HEIGHT;

          const imageAspect = image.width / image.height;
          const previewAspect = previewWidth / previewHeight;

          let displayedWidth, displayedHeight;
          if (imageAspect > previewAspect) {
            displayedWidth = previewWidth;
            displayedHeight = previewWidth / imageAspect;
          } else {
            displayedHeight = previewHeight;
            displayedWidth = previewHeight * imageAspect;
          }

          // Calculate scaling factor
          const scaleX = image.width / displayedWidth;
          const scaleY = image.height / displayedHeight;

          // Calculate the safe area for rotation
          const safeArea = Math.max(image.width, image.height) * 2;
          canvas.width = safeArea;
          canvas.height = safeArea;

          // Set the background color to #f5f5f5
          ctx!.fillStyle = '#f5f5f5';
          ctx!.fillRect(0, 0, canvas.width, canvas.height);

          ctx!.translate(safeArea / 2, safeArea / 2);
          ctx!.rotate((rotation * Math.PI) / 180);
          ctx!.translate(-safeArea / 2, -safeArea / 2);

          // Draw the image on the canvas
          ctx!.drawImage(
            image,
            safeArea / 2 - image.width / 2,
            safeArea / 2 - image.height / 2
          );

          // Scale crop values based on the original image size
          const scaledCropX =
            (crop.x - (previewWidth - displayedWidth) / 2) * scaleX;
          const scaledCropY =
            (crop.y - (previewHeight - displayedHeight) / 2) * scaleY;
          const scaledCropWidth = crop.width * scaleX;
          const scaledCropHeight = crop.height * scaleY;

          const data = ctx!.getImageData(
            safeArea / 2 - image.width / 2 + scaledCropX,
            safeArea / 2 - image.height / 2 + scaledCropY,
            scaledCropWidth,
            scaledCropHeight
          );

          canvas.width = scaledCropWidth;
          canvas.height = scaledCropHeight;

          ctx!.putImageData(data, 0, 0);

          canvas.toBlob((blob) => {
            if (blob) {
              const file = new File([blob], fileName, { type: 'image/jpeg' });
              resolve(file);
            }
          }, 'image/jpeg');
        };
      };
      reader.readAsDataURL(originalImageFile);
    });
  };

  const handleOk = async () => {
    if (originalImageFile && crop.width && crop.height) {
      if (
        crop.width === DEFAULT_PREVIEW_WIDTH &&
        crop.height === DEFAULT_PREVIEW_HEIGHT &&
        crop.x === 0 &&
        crop.y === 0 &&
        rotation === 0
      ) {
        // If the crop size is the same as the preview size and no crop adjustments are made,
        // use the original image file without cropping or scaling.
        setImage(originalImageFile);
        setCroppedImageUrl(imageUrl); // Use the original image URL as the cropped image URL
      } else {
        // Use the cropped version of the image
        const croppedFile = await getCroppedFile(crop, 'cropped.jpg');
        const croppedPreview = await getCroppedImg(
          crop,
          rotation,
          originalImageFile as File
        );
        setImage(croppedFile);
        setCroppedImageUrl(croppedPreview);
      }
    }
    resetCrop();
    setModalVisible(false);
  };

  const handleCancel = () => {
    setImage(null);
    setImageUrl(null);
    resetCrop();
    setModalVisible(false);
    setCroppedImageUrl(null);
  };

  const resetCrop = () => {
    if (scaledImageUrl) {
      setCrop({
        unit: 'px',
        width: DEFAULT_PREVIEW_WIDTH,
        height: DEFAULT_PREVIEW_HEIGHT,
        x: 0,
        y: 0,
      });
    }
    setRotation(0);
  };

  return (
    <Modal
      title={i18n.t('headers:edit_image')}
      open={modalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="reset" onClick={resetCrop}>
          {i18n.t('buttons:reset')}
        </Button>,
        <Button key="back" danger onClick={handleCancel}>
          {i18n.t('buttons:cancel')}
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          {i18n.t('buttons:upload')}
        </Button>,
      ]}
    >
      {scaledImageUrl && (
        <ImageUploadPreviewBox>
          <ImageUploadPreviewContainer
            previewWidth={DEFAULT_PREVIEW_WIDTH}
            previewHeight={DEFAULT_PREVIEW_HEIGHT}
          >
            <ReactCrop
              crop={crop}
              onChange={(newCrop) => setCrop(newCrop)}
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <ImageCropperUpload
                rotation={rotation}
                src={scaledImageUrl}
                alt="Crop source"
              />
            </ReactCrop>
          </ImageUploadPreviewContainer>
        </ImageUploadPreviewBox>
      )}
      <CropperPropertiesContainer>
        <RotationSlider title={i18n.t('headers:rotate')}>
          <Slider
            min={-180}
            max={180}
            step={1}
            value={rotation}
            onChange={(value) => setRotation(value)}
            tooltip={{
              formatter: (value) => `${i18n.t('headers:rotate')}: ${value}°`,
            }}
          />
        </RotationSlider>
      </CropperPropertiesContainer>
    </Modal>
  );
}
