import { Col, Row } from 'antd';
import i18n from 'i18next';
import { useSelector } from 'react-redux';
import {
  StyledTechicalField,
  TechnicalFieldsWrapper,
} from '../../../elements/fabrics/fabricStyledElement';
import { TitleH7 } from '../../../elements/typography/TitleH7';
import { FabricCert } from '../../../utils/backendRequests/certifications/CertificationTypes';
import { formFieldsProp } from '../../../utils/fabrics/FabricsTypes';
import { priceParser } from '../../../utils/fabrics/parseFabric';
import {
  parseFabricTitles,
  returnOrderedFields,
} from '../../../utils/fabrics/parseFabricFields';
import { RootAuth } from '../../../utils/types/Types';
import { Colour, DisplayColour } from '../../DisplayColour';
import { DisplayCertifications } from '../../certification/DisplayCertifications';
import { DetailsComponentProps, FabricTabProps } from '../FabricTypes';

/**
 * A tab component to display the technical information of one fabric.
 */
export function FabricTechnicalTab({ fabric, isMobile }: FabricTabProps) {
  const { measurementUnit } = useSelector((state: RootAuth) => state.auth);

  const technicalFields = returnOrderedFields([
    'original_supplier',
    'name_from_original_supplier',
    'ml_pattern_tags',
    'colour_fastness',
    'colours',
    'country_of_origin',
    'wash_care',
    'piling',
    'gauge_inch',
    'yarn_count',
    'special_yarn',
    'stretch',
    'hand_feel',
    'functional_finish',
    'season',
    'usage_category',
    'lace_structure',
    'pattern_design',
    'repeat',
    'aesthetic_finish',
    'edge_finish',
    'look',
    'sustainability',
    'lead_time_days',
    'availability',
    'moq_m',
    'mcq_m',
    'moq_sqm',
    'mcq_sqm',
    'moq_kg',
    'mcq_kg',
    'moq_piece',
    'mcq_piece',
    'stock_m',
    'stock_sqm',
    'stock_kg',
    'stock_piece',
    'weight_grams_per_m',
    'weight_grams_per_piece',
    'length_cm_per_piece',
    'certifications',
    'creation_date',
  ]);

  type colourData = {
    id: number;
    name: string;
    hex_value: string | null;
  };

  const DetailsComponent = ({
    title,
    value,
    fieldName,
  }: DetailsComponentProps) => {
    let displayedValue = value;
    let isColour = false;
    let colours;
    if (fieldName === 'colours') {
      const colourPropsArray = value as unknown as colourData[];
      // Transform ColourData to Colour and set tooltipShow to false
      colours = colourPropsArray.map((colour) => ({
        title: colour.name,
        hexValue: colour.hex_value,
        tooltipShow: true,
      }));
      isColour = true;
    }

    if (typeof value === 'number' && fieldName.startsWith('price')) {
      displayedValue = priceParser(fabric.currency as string, value);
    }
    // Handle certifications separately
    if (fieldName === 'certifications') {
      return (
        <>
          <TitleH7>{title}</TitleH7>
          <DisplayCertifications
            certArray={value as FabricCert[]}
            showDownload={true}
          />
        </>
      );
    }

    return (
      <>
        {isColour ? (
          <>
            <TitleH7>{title}</TitleH7>
            <DisplayColour colours={colours as Colour[]} />
          </>
        ) : (
          <>
            <TitleH7>{title}</TitleH7>
            <StyledTechicalField>{String(displayedValue)}</StyledTechicalField>
          </>
        )}
      </>
    );
  };

  // Filter out null fields
  const filteredTechnicalFields = technicalFields.filter(
    (item: formFieldsProp) => {
      const fieldValue = fabric[item.name as keyof typeof fabric];
      return (
        fieldValue !== null &&
        fieldValue !== '' &&
        (Array.isArray(fieldValue) ? fieldValue.length !== 0 : true)
      );
    }
  );

  const spanObj = {
    span: isMobile
      ? 24
      : filteredTechnicalFields.length > 4
        ? 6
        : Math.floor(24 / filteredTechnicalFields.length),
  };

  const technicalDataProp = filteredTechnicalFields.map(
    (item: formFieldsProp, index: number) => (
      <Col
        key={item.name}
        xs={{ span: 12 }}
        sm={{ span: 12 }}
        md={spanObj}
        lg={spanObj}
        xl={spanObj}
        xxl={spanObj}
        style={{
          marginBottom: isMobile
            ? '16px'
            : index < filteredTechnicalFields.length - 1
              ? '16px'
              : '0',
        }}
      >
        <DetailsComponent
          title={parseFabricTitles(item.name, measurementUnit)}
          value={fabric[item.name as keyof typeof fabric] as string}
          fieldName={item.name}
        />
      </Col>
    )
  );

  return (
    <>
      <Row gutter={[filteredTechnicalFields.length < 4 ? 128 : 16, 16]}>
        {technicalDataProp}
      </Row>
      {fabric.ml_description && (
        <TechnicalFieldsWrapper>
          <DetailsComponent
            title={i18n.t('fabric_fields:ml_description')}
            value={fabric.ml_description}
            fieldName={'ml_description'}
          />
        </TechnicalFieldsWrapper>
      )}
      {fabric.description && (
        <TechnicalFieldsWrapper>
          <DetailsComponent
            title={i18n.t('fabric_fields:description')}
            value={fabric.description}
            fieldName={'description'}
          />
        </TechnicalFieldsWrapper>
      )}
      {fabric.notes && (
        <TechnicalFieldsWrapper>
          <DetailsComponent
            title={i18n.t('fabric_fields:notes')}
            value={fabric.notes}
            fieldName={'notes'}
          />
        </TechnicalFieldsWrapper>
      )}
    </>
  );
}
