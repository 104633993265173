import styled from 'styled-components';

import { Color } from '../base/Color';
import { FontSize, FontWeight } from '../base/Font';

export const TextB2 = styled.p`
  color: ${Color.G5} !important;
  font-size: ${FontSize.Body2};
  font-weight: ${FontWeight.Regular};
  line-height: 21px;
  margin-bottom: 10px;
`;
