import { Col, Row, Space, Tabs } from 'antd';
import { format } from 'date-fns';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { Color } from '../../../elements/base/Color';
import { TextB2 } from '../../../elements/typography/TextB2';
import { AddToCollectionModal } from '../../modal/collectionModal/AddToCollectionModal';
import { AddToProjectModal } from '../../modal/projectModal/AddToProjectModal';

import { HeaderTitle } from '../../../components/title/HeaderTitle';
import { ButtonBasic } from '../../../elements/buttons/StyledButtons';
import { HelpIcon } from '../../../elements/icons/StyledIcons';
import { routesNamedDict } from '../../../routes';
import { deleteToBinFabric } from '../../../utils/backendRequests/fabric/deleteToBinFabric';
import { priceParser } from '../../../utils/fabrics/parseFabric';
import {
  fabricPropertyArray,
  parseFabricTitles,
  returnOrderedFields,
} from '../../../utils/fabrics/parseFabricFields';
import { getTranslatedValuesAndUpdateData } from '../../../utils/fabrics/parseFabricValues';
import {
  compositionsProp,
  parseTranslatedCompositions,
} from '../../../utils/parsers/parseTranslatedCompositions';
import { record, RootAuth } from '../../../utils/types/Types';
import { DisplayImages } from '../../DisplayImages';
import { Modal } from '../../Modal';
import { CreateHangerRequestModal } from '../../modal/CreateHangerRequestModal';
import { intialDataProp } from '../../search/SearchTypes';
import { BasicInfoComponentProps, fabricRecordProps } from '../FabricTypes';
import { FabricAttatchmentsTab } from './FabricAttachmentsTab';
import { FabricRecommendationTab } from './FabricRecommendationTab';
import { FabricTechnicalTab } from './FabricTechnicalTab';

export function FabricDetailsMobile({ fabric }: fabricRecordProps) {
  const [images, setImages] = useState<string[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [projectModal, setProjectModal] = useState({ visible: false });
  const [collectionModal, setNewCollectionModal] = useState({ visible: false });
  const [isHangerRequestModalVisible, setIsHangerRequestModalVisible] =
    useState(false);
  const [attachmentsExist, setAttachmentsExist] = useState<boolean>(false);
  const [hideImages, setHideImages] = useState<boolean>(false);
  const [translatedData, setTranslatedData] = useState<record>(fabric);
  const navigate = useNavigate();
  const { measurementUnit, role } = useSelector(
    (state: RootAuth) => state.auth
  );
  const account = useSelector((state: RootAuth) => state.auth.accountType);

  // Set translated data
  useEffect(() => {
    const translationData = getTranslatedValuesAndUpdateData(
      [fabric],
      fabricPropertyArray
    ) as intialDataProp;
    setTranslatedData(translationData[0]);
  }, [fabric]);

  const basicFabricFields = returnOrderedFields([
    'fabric_type',
    'price_per_m',
    'price_per_sqm',
    'price_per_kg',
    'price_per_piece',
    'weight_grams_per_sqm',
    'width_cm',
    'compositions',
    'construction',
    'created_at',
    'updated_at',
  ]);

  useEffect(() => {
    if (Object.keys(fabric).length !== 0) {
      const newImages = [
        fabric['image_url_macro'] as string,
        fabric['image_url_front'] as string,
        fabric['image_url_back'] as string,
        fabric['image_url_header'] as string,
      ].filter(Boolean); // Remove null or undefined values
      setImages(newImages);

      setAttachmentsExist(
        Array.isArray(fabric.attachments) && fabric.attachments.length > 0
      );

      const allImagesNull = newImages.length === 0;
      setHideImages(allImagesNull);
    }
  }, [fabric]);

  const tabContents = [
    {
      key: '1',
      label: i18n.t('headers:recommendation'),
      children: (
        <div style={{ marginLeft: '5px' }}>
          <FabricRecommendationTab isMobile={true} fabric={fabric} />
        </div>
      ),
    },
    {
      key: '2',
      label: i18n.t('headers:technical_info'),
      children: (
        <div>
          <FabricTechnicalTab isMobile={true} fabric={translatedData} />
        </div>
      ),
    },
    {
      key: '3',
      disabled: !attachmentsExist,
      label: i18n.t('headers:attachments'),
      children: <FabricAttatchmentsTab fabric={fabric} />,
    },
  ];

  const BasicInfoComponent = ({
    fieldName,
    title,
    value,
    fieldType,
  }: BasicInfoComponentProps) => {
    const hasValue = value !== null && value !== '' && value !== undefined;
    if (fieldType === 'date' && hasValue) {
      const date = new Date(value as string);
      value = format(date, "yyyy-MM-dd HH:mm:ss 'UTC'");
    } else if (typeof value === 'number' && fieldName.startsWith('price')) {
      value = priceParser(fabric.currency as string, value);
    } else if (fieldName === 'compositions') {
      value = parseTranslatedCompositions(
        value as unknown as compositionsProp[],
        true
      ) as string;
    }

    const widthMetric = i18n.t(
      'long_messages:message_fabric_width_tolerance_metric'
    );

    const widthImperial = i18n.t(
      'long_messages:message_fabric_width_tolerance_imperial'
    );

    const widthMessage =
      measurementUnit === 'metric' ? widthMetric : widthImperial;
    const weightMessage = i18n.t(
      'long_messages:message_fabric_weight_tolerance'
    );

    const tooltipText =
      (fieldName === 'width_cm' && widthMessage) ||
      (fieldName === 'weight_grams_per_sqm' && weightMessage);

    return (
      <div>
        {tooltipText !== false ? (
          <div>
            <TextB2
              style={{
                marginRight: '8px',
              }}
            >
              {title}
              <HelpIcon
                title={tooltipText}
                fontSize="12px"
                margin="0 5px 0 3px"
              />
              :
            </TextB2>
            <span style={{ wordWrap: 'break-word' }}>
              {hasValue ? value : '-'}
            </span>
          </div>
        ) : (
          <TextB2 style={{ wordWrap: 'break-word' }}>
            {title}: {hasValue ? value : '-'}
          </TextB2>
        )}
      </div>
    );
  };
  const { libHashedName } = useParams();

  const editFabricNavigate = () => {
    navigate(
      routesNamedDict.FABRICS_FABRIC_EDIT.replace(
        ':libHashedName',
        libHashedName as string
      ).replace(':fabHashedName', fabric.hashed_name)
    );
  };

  // Filter out null fields and map them to BasicInfoComponent
  const basicFieldsWithValues = basicFabricFields
    .filter(
      (field) =>
        fabric[field.name as keyof typeof fabric] !== null &&
        fabric[field.name as keyof typeof fabric] !== '' &&
        fabric[field.name as keyof typeof fabric] !== undefined
    )
    .filter((field) => {
      const value = fabric[field.name as keyof typeof fabric];
      return Array.isArray(value) ? value.length !== 0 : true;
    }); // Filter out empty arrays

  // Calculate the length of each column
  const halfLength = Math.ceil(basicFieldsWithValues.length / 2);

  // Divide fields into two columns
  const firstColumnFields = basicFieldsWithValues.slice(0, halfLength);
  const secondColumnFields = basicFieldsWithValues.slice(halfLength);

  // Map the fields to BasicInfoComponent for each column
  const basicFieldsPropFirstColumn = firstColumnFields.map((field, index) => (
    <BasicInfoComponent
      key={index}
      fieldName={field.name}
      title={parseFabricTitles(field.name, measurementUnit)}
      value={fabric[field.name as keyof typeof fabric] as string}
      fieldType={field.fieldType}
    />
  ));

  const basicFieldsPropSecondColumn = secondColumnFields.map((field, index) => (
    <BasicInfoComponent
      key={index}
      fieldName={field.name}
      title={parseFabricTitles(field.name, measurementUnit)}
      value={fabric[field.name as keyof typeof fabric] as string}
      fieldType={field.fieldType}
    />
  ));

  // Function to handle opening and closing of the hanger request modal
  const toggleHangerRequestModal = () => {
    setIsHangerRequestModalVisible(!isHangerRequestModalVisible);
  };

  const handleConfirm = async () => {
    const data = { hashed_names: [fabric.hashed_name] };
    deleteToBinFabric(data, navigate);
    setModalVisible(false); // Close the modal after deletion
  };

  return (
    <>
      {fabric.name && (
        <HeaderTitle
          title={`${i18n.t('page_titles:fabric_details_page')} "${
            fabric.name
          }"`}
          isCenter={true}
        />
      )}
      <AddToProjectModal
        modal={projectModal}
        setModal={setProjectModal}
        hashedNames={[fabric.hashed_name]}
      />
      <AddToCollectionModal
        modal={collectionModal}
        setModal={setNewCollectionModal}
        fabricHashedNames={[fabric.hashed_name]}
      />
      <CreateHangerRequestModal
        visible={isHangerRequestModalVisible}
        onCancel={toggleHangerRequestModal}
        hashedName={fabric.hashed_name}
      />
      <Modal
        title={i18n.t('headers:confirm_deletion')}
        open={modalVisible}
        onOk={handleConfirm}
        onCancel={() => setModalVisible(false)}
        okText={i18n.t('buttons:yes')}
        cancelText={i18n.t('buttons:no')}
        okButtonProps={{
          style: { backgroundColor: Color.Red },
        }}
      >
        <p>{i18n.t('long_messages:message_confirm_fabrics_deletion')}</p>
      </Modal>
      {!hideImages && (
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <DisplayImages ImageArray={images} isMobile={true} />
        </Row>
      )}
      <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
        <Col span={12}>{basicFieldsPropFirstColumn}</Col>
        <Col span={12}>{basicFieldsPropSecondColumn}</Col>
      </Row>
      <Row style={{ marginTop: '30px' }}>
        <Space>
          {role === 'designer' && (
            <ButtonBasic onClick={() => setProjectModal({ visible: true })}>
              {i18n.t('headers:add_to_project')}
            </ButtonBasic>
          )}
          {role === 'supplier' && account !== 'viewer' && (
            <ButtonBasic
              onClick={() => setNewCollectionModal({ visible: true })}
            >
              {i18n.t('headers:add_to_collection')}
            </ButtonBasic>
          )}
          {role === 'supplier' && account !== 'viewer' && (
            <ButtonBasic onClick={editFabricNavigate}>
              {i18n.t('buttons:edit')}
            </ButtonBasic>
          )}
          {role === 'designer' && account !== 'viewer' && (
            <ButtonBasic onClick={toggleHangerRequestModal}>
              {i18n.t('buttons:request_hanger')}
            </ButtonBasic>
          )}
          {role === 'supplier' && account !== 'viewer' && (
            <ButtonBasic danger onClick={() => setModalVisible(true)}>
              {i18n.t('buttons:delete')}
            </ButtonBasic>
          )}
        </Space>
      </Row>
      <Row>
        <Tabs
          defaultActiveKey="1"
          items={tabContents}
          size={'large'}
          tabBarStyle={{ height: '70px', marginTop: '30px' }}
        />
      </Row>
    </>
  );
}
