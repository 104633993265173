import { Page404 } from '../pages/404';
import { RouteProps } from './RouteTypes';

export const routes404: RouteProps[] = [
  {
    path: 'not_found',
    title: 'Not Found',
    page: Page404,
  },
];
