import { Button } from 'antd';
import styled from 'styled-components';
import i18n from '../../plugins/i18n';
import { Color } from '../base/Color';

interface ButtonBasicProps {
  $small?: boolean;
}

export const ButtonBasic = styled(Button)<ButtonBasicProps>`
  height: ${(props) => (props.$small ? '35px' : '48px')};
  font-size: 15px;
  line-height: 24px;
`;

export const SearchButton = styled(ButtonBasic)`
  margin-left: 15px;
  color: ${Color.White};
  background: ${Color.P5};
`;

export const SubmitButton = styled(ButtonBasic)`
  width: 100%;
  background-color: ${Color.Logo};
  color: ${Color.White};
`;

export const DownloadTemplateButton = styled.a`
  padding-left: 5px;
`;

export const FabricStyledButton = styled(Button)`
  width: 100%;
  height: 48px;
`;

export const MobileSearchButton = styled(Button)`
  max-width: 200px;
  margin-left: 10px;
  margin-bottom: 5px;
  font-size: 18px;
`;

export const MobileHeaderSearchButton = styled(Button)`
  margin-right: 10px;
  font-size: 18px;
`;

export const DesktopSidebarButton = styled(Button)`
  font-size: 18px;
`;

export const UserInformationButton = styled(ButtonBasic)`
  float: right;
`;

type FabricSubmitProps = {
  type: string;
};

// Create a functional component that accepts title as a prop
export const SaveFabricButton = ({ type }: FabricSubmitProps) => (
  <FabricStyledButton type="primary" htmlType="submit">
    {i18n.t(`buttons:${type}`)}
  </FabricStyledButton>
);

export const DropdownButton = styled(ButtonBasic)`
  text-decoration: underlined;
  border: none !important;
  box-shadow: none !important;
  padding: 0 8px;
  height: auto;
  display: block;
  width: 100%;
  text-align: center;
  background: none !important;
`;

// Fabric Filter Drawer Buttons
export const ResetFilterButton = styled(Button)`
  width: 100%;
`;

export const ApplyFilterButton = styled(Button)`
  width: 100%;
  margin-top: 5px;
`;
