import { Dayjs } from 'dayjs';

export const requiredRule = (field: string) => {
  return {
    required: true,
    message: `${field} is required!`,
  };
};

export const minMaxRule = (
  field: string,
  min: number,
  max: number,
  minStrict: boolean,
  maxStrict: boolean
) => {
  const rules = [];

  if (min !== null) {
    rules.push({
      validator: (_: unknown, value: number | '') => {
        if (value === null || value === '') return Promise.resolve();

        // For other min values, check based on the minStrict flag
        if (minStrict ? value <= min : value < min) {
          return Promise.reject(
            new Error(
              `${field} must be greater than${
                minStrict ? '' : ' or equal to'
              } ${min}`
            )
          );
        }

        return Promise.resolve();
      },
    });
  }

  if (max !== null) {
    rules.push({
      validator: (_: unknown, value: number) => {
        if (value === null) return Promise.resolve();

        // For other max values, check based on the maxStrict flag
        if (maxStrict ? value >= max : value > max) {
          return Promise.reject(
            new Error(
              `${field} must be less than${
                maxStrict ? '' : ' or equal to'
              } ${max}`
            )
          );
        }

        return Promise.resolve();
      },
    });
  }

  return rules;
};

export const maxLengthRule = (field: string, maxLength: number) => {
  return {
    validator: (_: unknown, value: string) => {
      if (value === null || value === undefined) {
        return Promise.resolve();
      }
      if (value.length <= maxLength) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(`${field} must have fewer than ${maxLength + 1} characters`)
      );
    },
  };
};

export const websiteValidationRule = (field: string) => {
  const regex = /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,})([/\w .-]*)*\/?$/;
  return {
    validator: (_: unknown, value: string) => {
      if (value === '' || value === null) {
        return Promise.resolve();
      }
      if (regex.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(`${field} must be a valid website URL`));
    },
  };
};

export const dateValidRangeRule = (
  field: string,
  dateMin: Dayjs,
  dateMax: Dayjs
) => {
  return {
    validator: (_: unknown, value: Dayjs | '') => {
      if (value === null || value === '') {
        return Promise.resolve();
      }
      if (dateMax.isSameOrAfter(value) && dateMin.isSameOrBefore(value)) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error(
          `${field} must be between ${dateMin.format(
            'YYYY-MM-DD'
          )} and ${dateMax.format('YYYY-MM-DD')}`
        )
      );
    },
  };
};
