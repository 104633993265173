import { Space } from 'antd';
import { useNavigate } from 'react-router-dom';

import { TabTitle } from '../../../components/TabTitle';
import { AuthForm } from '../../../components/form/AuthForm';
import { HeaderTitle } from '../../../components/title/HeaderTitle';
import { AuthLayout } from '../../../layouts/auth/Auth';
import i18n from '../../../plugins/i18n';
import { store } from '../../../plugins/redux';
import { routesNamedDict } from '../../../routes';
import { login } from '../../../utils/backendRequests/auth/login';
import { TitleProp } from '../../PagesTypes';
import { LoginValues } from '../AuthTypes';
import { validation } from './validation';

export function LoginPage({ title }: TitleProp) {
  const navigate = useNavigate();

  const handleSubmit = async (values: LoginValues) => {
    const email = values.email;
    const password = values.password;
    await login(store, email, password, navigate, routesNamedDict);
  };

  return (
    <AuthLayout>
      <TabTitle title={title} />
      <Space direction="vertical" size="small">
        <HeaderTitle
          title={i18n.t('page_titles:login_page')}
          subtitle={i18n.t('page_subtitles:login_sub')}
        />
        <AuthForm
          formType="login"
          handleSubmit={handleSubmit}
          routesNamedDict={routesNamedDict}
          validation={validation}
        />
      </Space>
    </AuthLayout>
  );
}
