import { message } from 'antd';

import { api } from '../api/api';

/**
 * Logs in the user to HopLun. If successful, returns the login token.
 */

export type hoplunTokenProps = {
  userKey: string;
};

export async function getHoplunToken(): Promise<string> {
  try {
    return await api({
      method: 'GET',
      url: 'v1/user/hoplun_token',
    }).then((res: hoplunTokenProps) => {
      return res.userKey;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
