import { Divider } from 'antd';
import React from 'react';

import { ATagB1 } from '../../elements/typography/LinkB1';
import { FooterLink } from './delvifyFooterURLs';

interface FooterLinksProps {
  links: FooterLink[];
}

export function FooterLinks({ links }: FooterLinksProps) {
  return (
    <>
      {links.map((link: FooterLink, i: number) => {
        return (
          <React.Fragment key={i}>
            <ATagB1 href={link.path} target="_blank">
              {link.title === 'Delvify' ? (
                <span>
                  &copy; {new Date().getFullYear() + ' ' + link.title}{' '}
                </span>
              ) : (
                <span>{link.title}</span>
              )}
            </ATagB1>
            <Divider type="vertical" />
          </React.Fragment>
        );
      })}
    </>
  );
}
