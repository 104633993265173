import { message } from 'antd';

import { api } from '../api/api';
import { CollectionListItem } from './CollectionTypes';

/**
 * Gets all collections available to the user, this depends on their brand.
 */
export async function getCollections(): Promise<CollectionListItem[]> {
  try {
    return await api({
      method: 'GET',
      url: 'v1/collection',
    }).then((res: CollectionListItem[]) => {
      return res;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
