import i18n from 'i18next';

import { api } from '../api/api';

/**
 * Adds or Removes attachments from the database for a fabric
 */
export async function editAttachment(
  hashedName: string,
  data: FormData
): Promise<void> {
  await api({
    method: 'PUT',
    url: `v1/fabric/${hashedName}/attachment`,
    body: data,
    headers: {
      'content-type': 'multipart/form-data',
    },
  }).catch(() => {
    throw new Error(i18n.t('error_messages:error_fabric_attachments_failed'));
  });
}
