import styled from 'styled-components';

export const MobileSearchBarWrapper = styled.div`
  max-height: 40px;
  padding-bottom: 10px;
  @media only screen and (min-width: 800px) {
    min-width: 380px;
  }
  @media only screen and (max-width: 800px) {
    min-width: 380px;
  }
  @media only screen and (min-width: 470px) and (max-width: 480px) {
    min-width: 378px !important;
  }
  @media only screen and (min-width: 460px) and (max-width: 470px) {
    min-width: 375px !important;
  }
  @media only screen and (min-width: 450px) and (max-width: 460px) {
    min-width: 368px !important;
  }
  @media only screen and (min-width: 440px) and (max-width: 450px) {
    min-width: 355px !important;
  }
  @media only screen and (min-width: 430px) and (max-width: 440px) {
    min-width: 350px !important;
  }
  @media only screen and (min-width: 420px) and (max-width: 430px) {
    min-width: 335px !important;
  }
  @media only screen and (min-width: 410px) and (max-width: 420px) {
    min-width: 320px !important;
  }
  @media only screen and (max-width: 410px) {
    min-width: 310px;
  }
`;
