import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

export const CertificationDownloadIcon = styled(CloudDownloadOutlined)`
  font-size: 22px;
`;

export const CertificationVerifiedStatusIcon = styled(CheckCircleOutlined)`
  font-size: 22px;
  color: green;
`;

export const CertificationInvalidStatusIcon = styled(CloseCircleOutlined)`
  font-size: 22px;
  color: red;
`;

export const CertificationInProgressStatusIcon = styled(ClockCircleOutlined)`
  font-size: 22px;
  color: orange;
`;
