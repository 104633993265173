import i18n from 'i18next';

import { Color } from '../../../elements/base/Color';
import { permanentlyDeleteBin } from '../../../utils/backendRequests/bin/permanentlyDeleteBin';
import { Modal } from '../../Modal';
import { PermanentlyDeleteModalProps } from './BinModalTypes';

export function PermanentlyDeleteModal({
  modal,
  setModal,
  type,
  selectedRowKeys,
  data,
  getBinContents,
}: PermanentlyDeleteModalProps) {
  // Close modal
  const handleCancel = () => {
    setModal({
      visible: false,
    });
  };

  const handleSubmit = async () => {
    const successFlag = await permanentlyDeleteBin(type, data, selectedRowKeys);
    if (successFlag) {
      setModal({
        visible: false,
      });
      getBinContents();
    }
  };

  return (
    <Modal
      title={i18n.t('headers:confirm_permanent_deletion')}
      open={modal.visible}
      onCancel={handleCancel}
      onOk={handleSubmit}
      okText={i18n.t('buttons:permanently_delete')}
      okButtonProps={{
        style: { backgroundColor: Color.Red },
      }}
    >
      {i18n.t('long_messages:message_confirm_permanent_deletion')}
    </Modal>
  );
}
