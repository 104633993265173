import { Button } from 'antd';
import i18n from 'i18next';
import { useState } from 'react';

import { Color } from '../../../elements/base/Color';
import { deleteToBinCertification } from '../../../utils/backendRequests/certifications/deleteToBinCertification';
import { Modal } from '../../Modal';

interface BinCertProps {
  getCertifications: () => Promise<void>;
  certName: string;
}

export function BinCertificationButton({
  getCertifications,
  certName,
}: BinCertProps) {
  const [modalVisible, setModalVisible] = useState(false);

  const handleConfirm = async () => {
    const deletionSuccess = await deleteToBinCertification(certName);
    if (deletionSuccess) {
      setModalVisible(false); // Close the modal after deletion
      getCertifications();
    } else {
      setModalVisible(false);
    }
  };

  return (
    <>
      <Button danger onClick={() => setModalVisible(true)}>
        {i18n.t('buttons:delete')}
      </Button>
      <Modal
        title={i18n.t('headers:confirm_deletion')}
        open={modalVisible}
        onOk={handleConfirm}
        onCancel={() => setModalVisible(false)}
        okText={i18n.t('buttons:yes')}
        cancelText={i18n.t('buttons:no')}
        okButtonProps={{
          style: { backgroundColor: Color.Red },
        }}
      >
        <p>{i18n.t('long_messages:message_confirm_cert_deletion')}</p>
      </Modal>
    </>
  );
}
