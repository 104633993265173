import styled from 'styled-components';

import { Color } from '../base/Color';
import { FontSize, FontWeight } from '../base/Font';

export const TextB1 = styled.p`
  color: ${Color.G5} !important;
  font-size: ${FontSize.Body1};
  font-weight: ${FontWeight.Regular};
  line-height: 24px;
  white-space: pre-line;
`;
