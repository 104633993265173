import styled from 'styled-components';

import { Color } from '../../elements/base/Color';

export const SearchWrapper = styled.div`
  background: ${Color.White};
  border: 1px ${Color.P2} solid;
  margin: 0 auto;
  padding: 20px 50px 50px 50px;
`;
