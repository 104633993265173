import { message } from 'antd';
import i18n from 'i18next';

import { defaultPageSize } from '../../../components/table/TableGeneric';
import { routesNamedDict } from '../../../routes';
import { api } from '../api/api';
import { Navigate, Paths } from './TextSearchTypes';

/**
 * Creates a text search for fabrics.
 */

export async function searchTextFabric(
  query: { text_query: string },
  navigate: Navigate,
  paths: Paths
): Promise<void | null> {
  try {
    const response = await api({
      method: 'POST',
      url: 'v1/text_search/fabric',
      body: query,
      params: {
        fabrics_page: 1,
        fabrics_page_size: defaultPageSize,
      },
    });

    // Returns 200 with [] if there's no match in database, so return error message if [] is returned
    if (!response.result_fabrics[0]) {
      const errorBody =
        i18n.t('long_messages:no_fabrics_found_part1_text_search') + ' ';
      // Added margin top so error message does not block the text search bar
      message.error({
        content: (
          <span>
            {errorBody}
            <a href={routesNamedDict.FABRICS}>
              {i18n.t('long_messages:message_no_fabrics_found_part2')}
            </a>
          </span>
        ),
        duration: 6,
        style: {
          marginTop: '41px',
        },
      });
      return null;
    } else {
      navigate(
        paths.SEARCH_TEXT_RESULTS.replace(
          ':textHashedId',
          response.text_search_hashed_id
        ),
        {
          state: {
            result_fabrics: response.result_fabrics,
            text_query: response.text_query,
            fabrics_count: response.fabrics_count,
            selectable_columns: response.selectable_columns,
            filterable_columns: response.filterable_columns,
          },
        }
      );
    }
  } catch (error) {
    message.error(i18n.t('error_messages:error_creating_text_search'));
    throw error;
  }
}
