import i18n from 'i18next';

import { record } from '../../types/Types';
import { api } from '../api/api';
/**
 * Edits a fabric entry.
 * Only text parts, not images.
 */
export async function editFabric(
  fabHashedName: string,
  parsedData: record // TODO: Create type for submitted data
): Promise<void> {
  try {
    await api({
      method: 'PUT',
      url: `v1/fabric/${fabHashedName}`,
      body: parsedData,
    });
  } catch (error) {
    throw new Error(i18n.t('error_messages:error_fabric_updated_failed'));
  }
}
