import { Row, Space } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { TabTitle } from '../../components/TabTitle';
import { GenericFilterDrawer } from '../../components/filter/generic/GenericFilterDrawer';
import { createInitialFilterValues } from '../../components/filter/generic/collectionInitialValues';
import { CreateCollectionModal } from '../../components/modal/collectionModal/CreateCollectionModal';
import { CollectionTableList } from '../../components/table/collectionList/CollectionTableList';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { ButtonBasic } from '../../elements/buttons/StyledButtons';
import { MainLayout } from '../../layouts/Main';
import { routesNamedDict } from '../../routes';
import { CollectionListItem } from '../../utils/backendRequests/collection/CollectionTypes';
import { getCollections } from '../../utils/backendRequests/collection/getCollections';
import { RootAuth } from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export function CollectionListPage({ title }: TitleProp) {
  const [modal, setModal] = useState({ visible: false });
  const [filterOpen, setFilterOpen] = useState(false);

  const [collections, setCollections] = useState<CollectionListItem[]>([]);
  const [filteredData, setFilteredData] = useState<CollectionListItem[]>([]);

  const role = useSelector((state: RootAuth) => state.auth?.role);
  const account = useSelector((state: RootAuth) => state.auth.accountType);

  const navigate = useNavigate();

  const getCollectionsList = async () => {
    const collectionData = await getCollections();
    setCollections(collectionData);
    setFilteredData(collectionData);
  };

  useEffect(() => {
    getCollectionsList();
  }, []);

  const handleNew = () => {
    setModal({ visible: true });
  };

  const viewAllFabrics = () => {
    navigate(routesNamedDict.ALL_FABRICS);
  };

  const subtitleKey =
    role === 'supplier' ? 'collections_sup_sub' : 'collections_des_sub';

  return (
    <MainLayout>
      <HeaderTitle
        title={i18n.t('page_titles:collections_page')}
        subtitle={i18n.t(`page_subtitles:${subtitleKey}`)}
      />
      <TabTitle title={title} />
      <CreateCollectionModal
        setModal={setModal}
        modal={modal}
        getData={getCollectionsList}
      />
      <Row justify="start">
        <Space>
          {role === 'supplier' && account !== 'viewer' && (
            <ButtonBasic onClick={handleNew}>
              {i18n.t('buttons:create')}
            </ButtonBasic>
          )}
          <ButtonBasic
            onClick={() => setFilterOpen(true)}
            disabled={collections.length === 0}
          >
            {i18n.t('buttons:filter')}
          </ButtonBasic>
          {role === 'designer' && (
            <ButtonBasic onClick={viewAllFabrics}>
              {i18n.t('buttons:all_fabrics')}
            </ButtonBasic>
          )}
        </Space>
      </Row>
      <GenericFilterDrawer
        initialData={collections}
        setFilteredData={setFilteredData}
        filterOpen={filterOpen}
        closeFilter={() => setFilterOpen(false)}
        createInitialFilterValues={createInitialFilterValues}
        role={role as string}
      />
      <CollectionTableList
        data={filteredData}
        getCollectionsList={getCollectionsList}
      />
    </MainLayout>
  );
}
