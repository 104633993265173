import { useState } from 'react';
import { Link } from 'react-router-dom';
import { UpdateSupplierBrandModal } from '../../modal/createBrandModal/UpdateSupplierBrandModal';
import { SupplierRecord } from '../TableTypes';

interface EditSupplierBrand {
  brand: SupplierRecord;
  supplierBrandId: number;
  getBrands: () => void;
}

export function EditSupplierBrand({
  brand,
  supplierBrandId,
  getBrands,
}: EditSupplierBrand) {
  const [updateModal, setUpdateModal] = useState({ visible: false });
  const handleUpdate = () => {
    setUpdateModal({ visible: true });
  };

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <Link onClick={handleUpdate} to={''}>
          Edit
        </Link>
      </div>
      <UpdateSupplierBrandModal
        modal={updateModal}
        setModal={setUpdateModal}
        brand={brand}
        supplierBrandId={supplierBrandId}
        getBrands={getBrands}
      />
    </>
  );
}
