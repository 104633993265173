import { FabricResponse } from './ProjectTypes';

import { message } from 'antd';
import i18n from 'i18next';
import { api } from '../api/api';

export const copyCollectionFabricsToProject = async (
  projectName: string,
  collectionId: number
): Promise<FabricResponse> => {
  const data = { project_name: projectName, collection_id: collectionId };
  try {
    return await api({
      method: 'POST',
      url: `v1/project/copy_from_collection`,
      body: data,
    }).then((res: FabricResponse) => {
      message.success(
        i18n.t('long_messages:message_project_add_fabric_success')
      );
      return res;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
};
