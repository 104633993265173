import { message } from 'antd';
import i18n from 'i18next';

/**
 *  Contains at least one lowercase letter ((?=.*[a-z])),
 *  Contains at least one uppercase letter ((?=.*[A-Z])),
 *  Contains at least one digit ((?=.*\d)),
 *  Can consist of lowercase letters, uppercase letters, digits, and special characters ([a-zA-Z\d\W]),
 *  Has a length between 8 and 50 characters ({8,50}).
 */
export const passwordValid = (password: string): boolean => {
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\W]{8,50}$/;
  let validFlag = false;
  if (!pattern.test(password)) {
    message.error(i18n.t('error_messages:error_password_recommendation'));
  } else {
    validFlag = true;
  }
  return validFlag;
};
