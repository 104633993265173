import { Card, Space, Switch } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader } from '../../components/header/CardHeader';
import { UserInformationButton } from '../../elements/buttons/StyledButtons';
import { UserInformationContainer } from '../../elements/StyledUI';
import { setHoplunUsername } from '../../plugins/redux/auth';
import { updateUserProfile } from '../../utils/backendRequests/auth/updateUserProfile';
import {
  UserProps,
  getCurrentUser,
} from '../../utils/backendRequests/user/getCurrentUser';
import { logoutHoplunUser } from '../../utils/backendRequests/user/logoutHoplunUser';
import { RootAuth } from '../../utils/types/Types';
import { ChangePasswordModal } from '../modal/ChangePasswordModal';
import { ChangeUsernameModal } from '../modal/ChangeUsernameModal';
import { HopLunLoginModal } from '../modal/HopLunLoginModal';
import { LanguageSelect } from './LanguageSelect';
import { MeasurementUnitSelect } from './MeasurementUnitSelect';

export function UserInformation() {
  const dispatch = useDispatch();

  const [isPasswordModalOpen, setPasswordIsModalOpen] = useState(false);
  const [isUsernameModalOpen, setUsernameIsModalOpen] = useState(false);
  const [isNotified, setIsNotified] = useState<boolean>(true);
  const [is2faEnabled, setIs2faEnabled] = useState<boolean>(true);
  const username = useSelector((state: RootAuth) => state.auth?.name);
  const brandName = useSelector((state: RootAuth) => state.auth?.brandName);
  const [isHopLunModalOpen, setIsHopLunModalOpen] = useState(false);
  const { hoplunUsername } = useSelector((state: RootAuth) => state.auth);

  const gridStyle = {
    width: '100%',
  };

  const booleanGridStyle = {
    ...gridStyle,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center', // Align items vertically in the center
  };

  const showUsernameModal = () => {
    setUsernameIsModalOpen(true);
  };

  const showPasswordModal = () => {
    setPasswordIsModalOpen(true);
  };

  const showHopLunModal = () => {
    setIsHopLunModalOpen(true);
  };

  useEffect(() => {
    const fetchUserSettings = async () => {
      const currentUserData: UserProps = await getCurrentUser();
      setIsNotified(currentUserData.is_email_notified);
      setIs2faEnabled(currentUserData.is_2fa_enabled);
    };
    fetchUserSettings();
  }, []);

  //On change function handle updated Notification toggle settings change
  const handleNotificationToggle = async () => {
    const updatedNotification = {
      is_email_notified: !isNotified,
    };
    // Call updateUserProfile and handle the promise returned
    updateUserProfile(updatedNotification).then(() => {
      // Update state only after a successful response
      setIsNotified((prevIsNotified) => !prevIsNotified);
    });
  };

  //On change function handle updated 2FA toggle settings change
  const handle2faToggle = async () => {
    const updated2fa = {
      is_2fa_enabled: !is2faEnabled,
    };
    // Call updateUserProfile and handle the promise returned
    updateUserProfile(updated2fa).then(() => {
      // Update state only after a successful response
      setIs2faEnabled((prevIs2faEnabled) => !prevIs2faEnabled);
    });
  };

  const handleHopLunLogout = async () => {
    const isLoggedOut = await logoutHoplunUser();
    if (isLoggedOut) {
      dispatch(setHoplunUsername(null));
    }
  };

  return (
    <UserInformationContainer>
      <CardHeader>{i18n.t('headers:user_information')}</CardHeader>
      <Card.Grid style={gridStyle}>
        <Space direction="vertical">
          <span>{i18n.t('common:name')}</span>
          <span>{username}</span>
        </Space>
        <UserInformationButton
          $small={true}
          type="primary"
          onClick={showUsernameModal}
        >
          {i18n.t('buttons:edit')}
        </UserInformationButton>
        <ChangeUsernameModal
          isModalOpen={isUsernameModalOpen}
          setIsModalOpen={setUsernameIsModalOpen}
        />
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        <Space direction="vertical">
          <span>{i18n.t('common:password')}</span>
          <span>..........</span>
        </Space>
        <UserInformationButton
          $small={true}
          type="primary"
          onClick={showPasswordModal}
        >
          {i18n.t('buttons:edit')}
        </UserInformationButton>
        <ChangePasswordModal
          isModalOpen={isPasswordModalOpen}
          setIsModalOpen={setPasswordIsModalOpen}
        />
      </Card.Grid>
      <Card.Grid style={booleanGridStyle}>
        <Space direction="vertical">
          <span>{i18n.t('common:email_notifications')}</span>
        </Space>
        <Space>
          <Switch
            checked={isNotified}
            onChange={handleNotificationToggle}
            style={{ transform: 'scale(1.2)', marginRight: '5px' }}
          />
        </Space>
      </Card.Grid>
      <Card.Grid style={booleanGridStyle}>
        <Space direction="vertical">
          <span>{i18n.t('page_titles:2fa_page')}</span>
        </Space>
        <Space>
          <Switch
            checked={is2faEnabled}
            onChange={handle2faToggle}
            style={{ transform: 'scale(1.2)', marginRight: '5px' }}
          />
        </Space>
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        <Space direction="vertical">
          <span>{i18n.t('common:company')}</span>
          <span>{brandName !== null ? brandName : '-'}</span>
        </Space>
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        <LanguageSelect />
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        <MeasurementUnitSelect />
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        <Space direction="vertical">{i18n.t('headers:hoplun_portal')}</Space>
        {!hoplunUsername && (
          <>
            <UserInformationButton
              $small={true}
              type="primary"
              onClick={showHopLunModal}
            >
              {i18n.t('page_titles:login_page')}
            </UserInformationButton>
            <HopLunLoginModal
              isModalOpen={isHopLunModalOpen}
              setIsModalOpen={setIsHopLunModalOpen}
            />
          </>
        )}
        {hoplunUsername && (
          <>
            <UserInformationButton
              $small={true}
              danger
              onClick={handleHopLunLogout}
            >
              {i18n.t('headers:logout')}
            </UserInformationButton>
          </>
        )}
      </Card.Grid>
    </UserInformationContainer>
  );
}
