import i18n from 'i18next';
import { TabTitle } from '../components/TabTitle';
import { MainLayout } from '../layouts/Main';
import { TitleProp } from './PagesTypes';

export function Page404({ title }: TitleProp) {
  return (
    <MainLayout>
      <TabTitle title={title} />
      <div>{i18n.t('error_messages:page_not_found')}</div>
    </MainLayout>
  );
}
