import { Input, Space } from 'antd';
import i18n from 'i18next';

import { MobileSearchBarWrapper } from '../../styles/wrappers/MobileSearchbarWrapper';
import { SearchbarProps } from './SearchTypes';

export function MobileSearchbar({ onSearch }: SearchbarProps) {
  const { Search } = Input;

  return (
    <>
      <Space.Compact>
        <MobileSearchBarWrapper>
          <Search
            onSearch={onSearch}
            placeholder={i18n.t('long_message:message_input_search_text')}
            style={{ width: '100%' }}
          />
        </MobileSearchBarWrapper>
      </Space.Compact>
    </>
  );
}
