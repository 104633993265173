import { Input } from 'antd';
import styled, { css } from 'styled-components';

interface InputErrorProps {
  $err?: boolean;
}

export const InputRedOnError = styled(Input)<InputErrorProps>`
  ${(props) =>
    props.$err &&
    css`
      border: 1px solid red;
    `}
`;
