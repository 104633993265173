import { message } from 'antd';
import i18n from 'i18next';
import { api } from '../api/api';

/**
 * Updates the logo image of a brand.
 * Type is either 'designer' or 'supplier'.
 */
export async function editBrandLogo(
  logo: FormData,
  brandId: number,
  type: string
): Promise<boolean> {
  try {
    return await api({
      method: 'PUT',
      url: `v1/superuser/brand/${type}/${brandId}/logo`,
      body: logo,
      headers: {
        'content-type': 'multipart/form-data',
      },
    }).then(() => {
      message.success(i18n.t('long_messages:message_logo_update_success'));
      return true;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return false;
  }
}
