import { formFieldsProp } from '../../form/FormTypes';

// Currently supplier and designer brands have the same fields
export const commonBrandFields: formFieldsProp[] = [
  {
    name: 'name',
    fieldType: 'string',
    required: true,
    stringMax: 50,
  },
  {
    name: 'city',
    fieldType: 'string',
    required: false,
    stringMax: 30,
  },
  {
    name: 'address',
    fieldType: 'string',
    required: false,
    stringMax: 100,
  },
  {
    name: 'website',
    fieldType: 'string', // TODO: Make logic for a website fieldType. Also need to update how the website rule is created on formRules so it is not hardcoded
    required: false,
    stringMax: 50,
  },
  {
    name: 'country_id',
    fieldType: 'singleDropdown',
    required: false,
  },
];

interface InputField {
  name: string;
  required: boolean;
  stringMax?: number;
  fieldType: string;
}

export const initialFormFields = (formFields: InputField[]) => {
  return {
    ...Object.fromEntries(formFields.map((field) => [field.name, ''])),
  };
};
