import { CertificationsListpage } from '../pages/certifications/CertificationsListPage';
import { RouteProps } from './RouteTypes';

export const routesCertifications: RouteProps[] = [
  {
    path: '/certifications',
    title: 'Certifications',
    key: 'certifications_page',
    page: CertificationsListpage,
  },
];
