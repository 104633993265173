import { message } from 'antd';
import { api } from '../api/api';
import { getCertObj } from './CertificationTypes';

/**
 * Gets all certifications available to the supplier user.
 */
export async function getCertifications(): Promise<getCertObj[]> {
  try {
    return await api({
      method: 'GET',
      url: `v1/certification`,
    }).then((res: getCertObj[]) => {
      return res;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
