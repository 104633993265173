import { DownloadTemplateProp, Setter } from '../types/Types';

/**
 * Configures the download template URLs based on the selected language.
 * Updates the download template URLs for different fabric types (knit, lace, woven)
 * If the language is not recognized, default URLs are set.
 */
export const configureLanguageTemplates = (
  language: string,
  setDownloadTemplateURLS: Setter<DownloadTemplateProp>
) => {
  switch (language) {
    case 'en_US':
      setDownloadTemplateURLS({
        knit:
          process.env.PUBLIC_URL +
          'template_files/en_US/Delvify_import_knit_template.xlsx',
        lace:
          process.env.PUBLIC_URL +
          'template_files/en_US/Delvify_import_lace_template.xlsx',
        woven:
          process.env.PUBLIC_URL +
          'template_files/en_US/Delvify_import_woven_template.xlsx',
      });
      break;
    case 'zh_CN':
      setDownloadTemplateURLS({
        knit:
          process.env.PUBLIC_URL +
          'template_files/zh_CN/Delvify针织导入模板.xlsx',
        lace:
          process.env.PUBLIC_URL +
          'template_files/zh_CN/Delvify蕾丝导入模板.xlsx',
        woven:
          process.env.PUBLIC_URL +
          'template_files/zh_CN/Delvify梭织导入模板.xlsx',
      });
      break;
    case 'zh_HK':
      setDownloadTemplateURLS({
        knit:
          process.env.PUBLIC_URL +
          'template_files/zh_HK/Delvify針織導入模板.xlsx',
        lace:
          process.env.PUBLIC_URL +
          'template_files/zh_HK/Delvify蕾絲導入模板.xlsx',
        woven:
          process.env.PUBLIC_URL +
          'template_files/zh_HK/Delvify梭織導入模板.xlsx',
      });
      break;
    default:
      setDownloadTemplateURLS({
        knit:
          process.env.PUBLIC_URL +
          'template_files/en_US/Delvify_import_knit_template.xlsx',
        lace:
          process.env.PUBLIC_URL +
          'template_files/en_US/Delvify_import_lace_template.xlsx',
        woven:
          process.env.PUBLIC_URL +
          'template_files/en_US/Delvify_import_woven_template.xlsx',
      });
      break;
  }
};
