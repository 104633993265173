import { DownOutlined } from '@ant-design/icons';
import { Col, Dropdown, Row, Space, Tag } from 'antd';
import i18n from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  ButtonBasic,
  DropdownButton,
} from '../../../elements/buttons/StyledButtons';
import { fabricPropertyArray } from '../../../utils/fabrics/parseFabricFields';
import { getTranslatedValuesAndUpdateData } from '../../../utils/fabrics/parseFabricValues';
import { record, RootAuth, RootState } from '../../../utils/types/Types';
import { ExportSelectedRowsButton } from '../../fabric/ExportSelectedRowsButton';
import { FabricFilterDrawer } from '../../filter/fabric/FabricFilterDrawer';
import { SelectFabricColumns } from '../../modal/SelectFabricColumns';
import { SelectMobileFabricColumns } from '../../modal/SelectMobileFabricColumns';
import { AddToCollectionModal } from '../../modal/collectionModal/AddToCollectionModal';
import { AddToProjectModal } from '../../modal/projectModal/AddToProjectModal';
import { CompareButton } from '../../table/CompareButton';
import { TableGeneric } from '../../table/TableGeneric';
import { columnFields } from '../../table/TableTypes';
import { getSelectedColumns } from '../../table/fabricTable/ColumnsDesktop';
import { getSelectedMobileColumns } from '../../table/fabricTable/ColumnsMobile';
import { intialDataProp, SearchResultsProps } from '../SearchTypes';

export type constructionDataProp = {
  construction: string;
}[];

export function FabricSearchResults({
  initialData,
  confidence,
  construction,
  searchType,
  tableState,
  setTableState,
  selectableColumnNames,
  sortableColumns,
  setFilterableColumns,
  filterableFields,
  filterableColumns,
  getFilteredFabrics,
  getInitalData,
}: SearchResultsProps) {
  // Data which gets fed to the table after it is filtered
  const [translatedData, setTranslatedData] = useState<intialDataProp>([]);
  const [translatedConstruction, setTranslatedConstruction] = useState<
    string | null
  >();
  // The currently selected row keys on the table
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  const { measurementUnit, role } = useSelector(
    (state: RootAuth) => state.auth
  );
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  const [projectModal, setProjectModal] = useState({ visible: false });
  const [collectionModal, setCollectionModal] = useState({ visible: false });
  const [columnModal, setColumnModal] = useState({ visible: false });
  const [includeSimilarity, setIncludeSimilarity] = useState<boolean>(false);
  // columns to be initialized based on current selection or local storage
  const [columns, setColumns] = useState<columnFields[]>([]);

  // Hashed names of selected fabrics according to the filtered selection
  const selectedHashedNames = useMemo(() => {
    if (initialData.length === 0) {
      return [];
    } else {
      return selectedRowKeys
        .filter((rowKey) => initialData[(rowKey as number) - 1] !== undefined)
        .map((rowKey) => initialData[(rowKey as number) - 1]['hashed_name']);
    }
  }, [initialData, selectedRowKeys]);

  useEffect(() => {
    const hasSimilarityScore = initialData.some(
      (record) => record.similarity_score !== undefined
    );
    const isLace = hasSimilarityScore && searchType === 'lace';
    setIncludeSimilarity(isLace);
  }, [initialData, searchType]);

  // Resets selected keys when filter updates data
  useEffect(() => {
    setSelectedRowKeys([]);
  }, [initialData]);

  //Handle Mobile Screen View
  useEffect(() => {
    if (isMobileFlag) {
      setColumns(
        getSelectedMobileColumns(
          'search',
          measurementUnit,
          selectableColumnNames,
          role,
          account,
          includeSimilarity
        )
      );
    } else {
      setColumns(
        getSelectedColumns(
          'search',
          measurementUnit,
          role,
          selectableColumnNames,
          account,
          includeSimilarity
        )
      );
    }
  }, [isMobileFlag, measurementUnit, selectableColumnNames, includeSimilarity]);

  // Set translated data
  useEffect(() => {
    const translationData = getTranslatedValuesAndUpdateData(
      initialData,
      fabricPropertyArray
    ) as intialDataProp;
    setTranslatedData(translationData);
  }, [initialData]);

  // Get Filtered data
  useEffect(() => {
    // Check if data has items and filterableColumns is not an empty object
    if (initialData.length > 0 && Object.keys(filterableColumns).length > 0) {
      //Call custom function here
      getFilteredFabrics();
    }
  }, [filterableColumns]);

  // Resets selected keys when filter updates data
  useEffect(() => {
    setSelectedRowKeys([]);
  }, [initialData]);

  // Get construction translation data
  useEffect(() => {
    if (construction && construction.length > 0) {
      const contructionPropertyArray = [
        {
          name: 'construction',
          namespaceKey: 'construction_values',
          isTranslatable: true,
        },
      ];
      const constructionData: constructionDataProp = [
        { construction: construction as string },
      ];
      const translatedContruction = getTranslatedValuesAndUpdateData(
        constructionData,
        contructionPropertyArray
      );
      setTranslatedConstruction(
        translatedContruction[0].construction as string
      );
    } else {
      setTranslatedConstruction(construction);
    }
  }, [construction]);

  // Get construction translation data
  useEffect(() => {
    if (construction && construction.length > 0) {
      const contructionPropertyArray = [
        {
          name: 'construction',
          namespaceKey: 'construction_values',
          isTranslatable: true,
        },
      ];
      const constructionData: constructionDataProp = [
        { construction: construction as string },
      ];
      const translatedContruction = getTranslatedValuesAndUpdateData(
        constructionData,
        contructionPropertyArray
      );
      setTranslatedConstruction(
        translatedContruction[0].construction as string
      );
    } else {
      setTranslatedConstruction(construction);
    }
  }, [construction]);

  const onSelectChange = (newSelectedRowKeys: number[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // Resets data when all data filtered out
  useEffect(() => {
    if (initialData.length === 0 && Object.keys(filterableColumns).length > 0) {
      getInitalData();
    }
  }, [filterableColumns]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange as (
      selectedRowKeys: React.Key[],
      selectedRows: record[]
    ) => void,
  };

  // Makes sure something is selected before the compare button can be selected
  const hasSelected = selectedRowKeys.length > 0;

  const items = [
    {
      key: 'filter',
      label: (
        <DropdownButton onClick={() => setFilterOpen(true)}>
          {i18n.t('buttons:filter')}
        </DropdownButton>
      ),
    },
    {
      key: 'compare',
      label: (
        <CompareButton
          filteredData={initialData}
          selectedRowKeys={selectedRowKeys}
          isMobile={true}
        />
      ),
    },
    {
      key: 'select-columns',
      label: (
        <DropdownButton onClick={() => setColumnModal({ visible: true })}>
          {i18n.t('buttons:select_columns')}
        </DropdownButton>
      ),
    },
    {
      key: 'export',
      label: (
        <ExportSelectedRowsButton
          disabled={!hasSelected}
          data={initialData}
          selectedRowKeys={selectedRowKeys}
          pageType={i18n.t('buttons:all_fabrics')}
        />
      ),
    },
    {
      key: 'add-to-collection',
      label: account !== 'viewer' && role === 'supplier' && (
        <DropdownButton
          onClick={() => setCollectionModal({ visible: true })}
          disabled={!hasSelected}
        >
          {i18n.t('buttons:add_to_collection')}
        </DropdownButton>
      ),
    },
    {
      key: 'add-to-project',
      label: role === 'designer' && (
        <DropdownButton
          onClick={() => setProjectModal({ visible: true })}
          disabled={!hasSelected}
        >
          {i18n.t('buttons:add_to_project')}
        </DropdownButton>
      ),
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      {isMobileFlag ? (
        <SelectMobileFabricColumns
          modal={columnModal}
          setModal={setColumnModal}
          setSelectedCol={setColumns}
          pageType={'search'}
          selectableColumnNames={selectableColumnNames}
        />
      ) : (
        <SelectFabricColumns
          modal={columnModal}
          setModal={setColumnModal}
          setSelectedCol={setColumns}
          pageType={'search'}
          selectableColumnNames={selectableColumnNames}
          includeSimilarity={includeSimilarity}
        />
      )}
      <AddToProjectModal
        modal={projectModal}
        setModal={setProjectModal}
        hashedNames={selectedHashedNames}
      />
      <AddToCollectionModal
        modal={collectionModal}
        setModal={setCollectionModal}
        fabricHashedNames={selectedHashedNames}
      />
      <Row justify="start" gutter={[16, 16]}>
        <Col span={isMobileFlag ? 24 : undefined}>
          {isMobileFlag ? (
            <Dropdown menu={{ items }}>
              <ButtonBasic>
                <Space>
                  {i18n.t('buttons:Options')}
                  <DownOutlined />
                </Space>
              </ButtonBasic>
            </Dropdown>
          ) : (
            <Space direction="horizontal" size="small">
              <ButtonBasic
                onClick={() => setFilterOpen(true)}
                disabled={
                  initialData.length === 0 &&
                  Object.keys(filterableColumns).length === 0
                }
              >
                {i18n.t('buttons:filter')}
              </ButtonBasic>
              <CompareButton
                filteredData={translatedData}
                selectedRowKeys={selectedRowKeys}
              />
              <ButtonBasic
                onClick={() => setColumnModal({ visible: true })}
                disabled={initialData.length === 0}
              >
                {i18n.t('buttons:select_columns')}
              </ButtonBasic>
              <ExportSelectedRowsButton
                disabled={!hasSelected}
                data={translatedData}
                selectedRowKeys={selectedRowKeys}
                pageType={i18n.t('buttons:all_fabrics')}
              />
              {account !== 'viewer' &&
                (role === 'supplier' ? (
                  <>
                    <ButtonBasic
                      block
                      disabled={!hasSelected}
                      onClick={() => setCollectionModal({ visible: true })}
                    >
                      {i18n.t('headers:add_to_collection')}
                    </ButtonBasic>
                  </>
                ) : (
                  <>
                    <ButtonBasic
                      block
                      disabled={!hasSelected}
                      onClick={() => setProjectModal({ visible: true })}
                    >
                      {i18n.t('headers:add_to_project')}
                    </ButtonBasic>
                  </>
                ))}
            </Space>
          )}
        </Col>
        {/* Render confidence and construction info */}
        {isMobileFlag && confidence && construction && (
          <Col span={24} style={{ marginTop: '20px' }}>
            <Tag>
              {i18n.t('fabric_fields:construction')}: {translatedConstruction} (
              {i18n.t('headers:probability')} {confidence}%)
            </Tag>
          </Col>
        )}
      </Row>
      {/* Render confidence and construction info below buttons for desktop */}
      {!isMobileFlag && confidence && construction && (
        <Row justify="center" style={{ marginTop: '20px' }}>
          <Col span={24}>
            <Tag>
              {i18n.t('fabric_fields:construction')}: {translatedConstruction} (
              {i18n.t('headers:probability')} {confidence}%)
            </Tag>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <FabricFilterDrawer
            initialData={initialData}
            filterOpen={filterOpen}
            closeFilter={() => setFilterOpen(false)}
            setFilterableColumns={setFilterableColumns}
            filterableFields={filterableFields}
          />
          <TableGeneric
            rowSelection={rowSelection}
            columns={columns}
            data={translatedData}
            tableState={tableState}
            setTableState={setTableState}
            isBackendPagination={true}
            sortableColumns={sortableColumns}
          />
        </Col>
      </Row>
    </div>
  );
}
