import i18n from 'i18next';

import { TabTitle } from '../../components/TabTitle';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { TitleH7 } from '../../elements/typography/TitleH7';
import { MainLayout } from '../../layouts/Main';
import { SearchWrapper } from '../../styles/wrappers/SearchWrapper';
import { TitleProp } from '../PagesTypes';

export function SearchResultsMobilePage({ title }: TitleProp) {
  return (
    <MainLayout>
      <HeaderTitle title={i18n.t('page_titles:search_results_page')} />
      <TabTitle title={title} />
      <SearchWrapper>
        <TitleH7>{i18n.t('headers:image_search')}</TitleH7>
        <div>{i18n.t('long_message:message_image_search_desktop')}</div>
      </SearchWrapper>
    </MainLayout>
  );
}
