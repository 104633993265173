import { configureStore } from '@reduxjs/toolkit';
import { authSliceReducer } from './auth';
import { commonSliceReducer } from './common';
import { fabricLibrarySliceReducer } from './fabricLibrary';
import { projectSliceReducer } from './project';

export const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    common: commonSliceReducer,
    fabricLibrary: fabricLibrarySliceReducer,
    project: projectSliceReducer,
  },
});
