import { message } from 'antd';
import axios from 'axios';
import i18n from 'i18next';
import { routesNamedDict } from '../routes/index';
import { store } from './redux';
import { finishApiCall, startApiCall } from './redux/common';

const axiosNoAuth = axios.create();

// Gives default timeout of 30 seconds
axiosNoAuth.defaults.timeout = 30000;

/**
 * Response interceptor
 * Anything outside 2xx range, will trigger an error.
 * On a 401 error the user is redirected to the login page with an added param of 'tokenExpired'.
 *
 * @param {Axios.Response}: Axios call response.
 * @return {Axios.Response|Promise}: Axios call response or Promise in case of error.
 */
axiosNoAuth.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Gateway Timeout or unauthenticated
    if (error.response === undefined) {
      message.error(i18n.t('error_messages:error_server_response'));
    } else if (
      error &&
      error.response.status === 401 &&
      error.response.data.msg !== 'Wrong credentials were entered'
    ) {
      window.location.href = `${routesNamedDict.LOGIN}?tokenExpired=1`;
    }
    return Promise.reject(error);
  }
);

// Request interceptor to increment ongoing api calls count
axiosNoAuth.interceptors.request.use(
  (config) => {
    store.dispatch(startApiCall());
    return config;
  },
  (error) => {
    store.dispatch(finishApiCall());
    return Promise.reject(error);
  }
);

// Response interceptor to decrement ongoing api calls count
axiosNoAuth.interceptors.response.use(
  (response) => {
    store.dispatch(finishApiCall());
    return response;
  },
  (error) => {
    store.dispatch(finishApiCall());
    return Promise.reject(error);
  }
);

// Note: Use this axios instance only when no bearer token is required.
export { axiosNoAuth };
