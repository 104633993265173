import { message } from 'antd';

import { api } from '../api/api';
import { getColoursResponse } from './ColourTypes';

/**
 * Gets all colours available to the supplier user.
 */
export async function getColours(): Promise<getColoursResponse> {
  try {
    return await api({
      method: 'GET',
      url: `v1/colour`,
    }).then((res: getColoursResponse) => {
      return res;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
