import { FileImageOutlined } from '@ant-design/icons';
import i18n from 'i18next';

import { UploadTitleWrapper } from '../../elements/wrapper/UploadTitleWrapper';

export function AddImageTitle() {
  return (
    <UploadTitleWrapper>
      <div>
        <FileImageOutlined />
      </div>
      <div>{i18n.t('headers:add_image')}</div>
    </UploadTitleWrapper>
  );
}
