import { message } from 'antd';
import i18n from 'i18next';

/**
 * Checks if a string is valid.
 *
 * It is valid if:
 *  - Is not a blank string Contains less than the specified number of characters If specified
 *
 * Performs next checks:
 * - String contains less than the specified number of characters If specified, consists of alphanumeric characters or spaces only If specified, is not a blank, null or undefined string
 */

export const stringValid = (
  text: string | null,
  maxLength: number,
  title: string,
  isOptional: boolean
): boolean => {
  let validFlag = false;

  // Styling to prevent message from overlaying across the search bar
  const errorBody = (string: string) => {
    return {
      content: string,
      style: {
        marginTop: '41px',
      },
    };
  };

  if (!isOptional && !text) {
    message.error(
      errorBody(
        i18n.t('error_messages:error_string_field_empty', { field: title })
      )
    );
  } else if (text === null && isOptional) {
    validFlag = true;
  } else if (text !== null && text.length > maxLength) {
    message.error(
      errorBody(
        i18n.t('error_messages:error_string_field_too_long', {
          field: title,
          maxLength: maxLength,
        })
      )
    );
  } else {
    validFlag = true;
  }

  return validFlag;
};
