import { LoginValidation } from '../AuthTypes';

export const validation: LoginValidation = {
  email: [
    {
      required: true,
      message: 'Please input your email!',
    },
  ],
  password: [
    {
      required: true,
      message: 'Please input your Password!',
    },
  ],
};
