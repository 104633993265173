import {
  CertificationBinBody,
  CollectionBinBody,
  FabricBinBody,
  LibraryBinBody,
  ProjectBinBody,
  UserUploadBinBody,
} from '../../../components/bin/BinTypes';
import { parseBinBody } from '../../parsers/parseBinBody';
import { DataProp } from '../../parsers/ParserTypes';
import { deleteCertification } from '../certifications/deleteCertification';
import { deleteCollection } from '../collection/deleteCollection';
import { deleteFabric } from '../fabric/deleteFabric';
import { deleteLibrary } from '../library/deleteLibrary';
import { deleteProject } from '../project/deleteProject';
import { deleteUserUpload } from '../userUploads/deleteUserUpload';

/**
 * Backend request to permanently delete the requested bin resources. body is created by the
 * utils function 'parseBinBody'. If successful return true and display a success message.
 * If an error occurs, return false and display the error message.
 */
export const permanentlyDeleteBin = async (
  type: string,
  binItems: DataProp,
  selectedRowKeys: number[]
) => {
  const deleteBody = parseBinBody(type, selectedRowKeys, binItems);
  let successFlag;
  switch (type) {
    case 'uploads':
      successFlag = await deleteUserUpload(deleteBody as UserUploadBinBody);
      break;
    case 'fabrics':
      successFlag = await deleteFabric(deleteBody as FabricBinBody);
      break;
    case 'libraries':
      successFlag = await deleteLibrary(deleteBody as LibraryBinBody);
      break;
    case 'collections':
      successFlag = await deleteCollection(deleteBody as CollectionBinBody);
      break;
    case 'certifications':
      successFlag = await deleteCertification(
        deleteBody as CertificationBinBody
      );
      break;
    case 'projects':
      successFlag = await deleteProject(deleteBody as ProjectBinBody);
      break;
    default:
      successFlag = false;
  }
  return successFlag;
};
