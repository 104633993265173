import { Col, Row, Select } from 'antd';
import i18n from 'i18next';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { setLanguage } from '../../plugins/redux/common';
import { languageChoices } from '../../utils/multiLanguage/antdLocale';
import { sortKeysByTranslation } from '../../utils/parsers/sortByTranslation';
import { RootState } from '../../utils/types/Types';

const { Option } = Select;

export const LanguageSelect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { language } = useSelector((state: RootState) => state.common);

  const sortedLanguageOptions = useMemo(
    () =>
      sortKeysByTranslation(
        Object.entries(languageChoices).map(([key]) => key)
      ),
    []
  );

  const languageOptions = sortedLanguageOptions.map((key) => (
    <Option key={key} value={key}>
      {i18n.t(`language_options:${key}`)}
    </Option>
  ));

  const onChangeLanguage = (selectedLanguageValue: string) => {
    dispatch(setLanguage(selectedLanguageValue));
    i18n.changeLanguage(selectedLanguageValue);
    navigate(0);
  };

  return (
    <Row>
      <Col span={8}>{i18n.t('headers:language')}</Col>
      <Col span={16}>
        <Select
          style={{ width: '100%' }}
          value={language}
          onChange={onChangeLanguage}
        >
          {languageOptions}
        </Select>
      </Col>
    </Row>
  );
};
