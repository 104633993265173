import { message } from 'antd';

import { TransformedFilterProps } from '../../../components/filter/FilterTypes';
import { FabricTableReqProps, TableStateObj } from '../../types/Types';
import { api } from '../api/api';
import { Librarydata } from './LibraryTypes';

/**
 * Returns fabrics for a given Fabric Library
 */
export async function getFabrics(
  libHashedName: string,
  tableState: TableStateObj,
  filterableColumns: TransformedFilterProps
): Promise<Librarydata> {
  const params: FabricTableReqProps = {
    fabrics_page: tableState.current,
    fabrics_page_size: tableState.pageSize,
  };
  // Add sort parameters only if both sortField and sortOrder have values
  if (tableState.sortField && tableState.sortOrder) {
    params.fabrics_sort_by = tableState.sortField;
    params.fabrics_sort_order = tableState.sortOrder;
  }
  if (filterableColumns) {
    params.fabrics_filter = JSON.stringify(filterableColumns);
  }
  try {
    return await api({
      method: 'GET',
      url: `v1/library/fabric/${libHashedName}`,
      params: params,
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
