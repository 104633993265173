import { parseFirstCharToUpper } from './parseFirstCharToUpper';

/**
 * Parses labels to remove _ , 'id', and capitalizes the first char
 * TODO: Should be deprecated, we have translations logic now so this is not suffucient.
 */
export const parseFormLabel = (label: string): string => {
  label = label
    .replace(/(_|id(?=$)|cm|grams)/g, ' ')
    .replace(/(\D)(\d)/g, '$1 $2')
    .replace(/(percentage)/g, '(%)')
    .replace(/\s+/g, ' ')
    .trim();

  return parseFirstCharToUpper(label);
};
