import { Helmet } from 'react-helmet';

/**
 * Over-ride parent component's title with child component's title
 */

interface TabTitleProps {
  title: string;
  id?: string;
}

export function TabTitle({ title, id }: TabTitleProps) {
  return (
    <Helmet>
      <title>{id ? title.replace(/Id/g, `${id}`) : title}</title>
      <meta name="description" content={`${title} Page`} />
    </Helmet>
  );
}
