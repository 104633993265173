import { formFieldsProp } from '../../form/FormTypes';

export const certificationFields: formFieldsProp[] = [
  {
    name: 'name',
    fieldType: 'string',
    required: true,
    stringMax: 50,
  },
  {
    name: 'certification_type',
    fieldType: 'singleDropdown',
    required: true,
    isTranslatable: true,
  },
];

export const initialFormFields = (formFields: formFieldsProp[]) => {
  return {
    ...Object.fromEntries(formFields.map((field) => [field.name, ''])),
  };
};
