import { Image } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';

export const ImageCropperUpload = styled.img<{
  rotation: number;
}>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: ${({ rotation }) => `rotate(${rotation}deg)`};
`;

export const ImageUploadStyle = styled.img`
  max-height: 100% !important;
  max-width: 100% !important;
  object-fit: contain !important;
`;

export const ImageUploadPreviewBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const ImageUploadPreviewContainer = styled.div<{
  previewWidth: number;
  previewHeight: number;
}>`
  width: ${({ previewWidth }) => `${previewWidth}px`};
  height: ${({ previewHeight }) => `${previewHeight}px`};
  flex: 0 0 auto;
  position: relative;
`;

export const CropperPropertiesContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const CropperRotateContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CropperRotateTitle = styled.label`
  margin-left: 5px;
`;

const CropperSliderContainer = styled.div`
  width: 300px;
  margin-left: 20px;
`;

interface RotationSliderProps {
  children: ReactNode;
  title: string;
}

export const RotationSlider = ({ children, title }: RotationSliderProps) => {
  return (
    <CropperRotateContainer>
      <CropperRotateTitle>{title}</CropperRotateTitle>
      <CropperSliderContainer>{children}</CropperSliderContainer>
    </CropperRotateContainer>
  );
};

// Display images of fabrics Details
interface StyledDetailsImageProps {
  isMobile: boolean;
  src: string;
  key: number;
  isSelected: number;
  onClick?: () => void;
}

// Styled component for ImageSide
const StyledImageSideBase = styled(Image)<{ isSelected: number; key: number }>`
  cursor: pointer;
  filter: ${({ isSelected, key }) =>
    isSelected === key ? 'brightness(65%)' : ''};
  object-fit: contain;
  object-position: center;
`;

export const StyledImageSide = (props: StyledDetailsImageProps) => {
  return (
    <StyledImageSideBase
      key={props.key}
      alt="Preview image"
      src={props.src}
      width={props.isMobile ? '18vw' : '7vw'}
      height={props.isMobile ? '18vw' : '7vw'}
      preview={false}
      isSelected={props.isSelected}
      onClick={props.onClick}
    />
  );
};

type StyledDetailsImageMainProps = {
  isMobile: boolean;
  src: string;
};

// Styled component for ImageMain
const StyledImageMainBase = styled(Image)`
  object-fit: contain;
  object-position: center;
`;

export const StyledImageMain = (props: StyledDetailsImageMainProps) => {
  return (
    <StyledImageMainBase
      width={props.isMobile ? '75vw' : '25vw'}
      height={props.isMobile ? '75vw' : '25vw'}
      preview={false}
      src={props.src}
      alt="Main image"
      key="mainImage"
    />
  );
};

// Fabric cards used for reccomendations
export const ImageFabricCardStyle = styled(Image)`
  padding: 10px 10px 0 0;
  object-fit: contain;
  object-position: center;
`;

interface ImageFabricCardProps {
  isMobile: boolean;
  src: string;
  alt: string;
}

export const ImageFabricCard = ({
  isMobile,
  src,
  alt,
}: ImageFabricCardProps) => (
  <ImageFabricCardStyle
    width={isMobile ? '70vw' : '12vw'}
    height={isMobile ? '70vw' : '12vw'}
    src={src}
    alt={alt}
    preview={false}
  />
);

// Fabric Image
export const FrontImageStyled = styled(Image)`
  object-fit: contain;
  object-position: center;
`;
