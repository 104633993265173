import { Button, Checkbox, Col, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import i18n from 'i18next';

import { CheckboxFilterProps, FilterValues } from '../FilterTypes';

export function CheckboxFilter({
  choices,
  field,
  isTranslatable,
  currentFilterValues,
  setCurrentFilterValues,
  updateFilteredData,
}: CheckboxFilterProps) {
  /**
   * Any time a checkbox value is changed, this function is called.
   * The new filter values are set to the currentFilterValues state
   * and the updateFilteredData function is called to update the data
   * which gets filtered.
   */
  const _onApplyFilter = (checkedValues: string[]) => {
    const newFilterValues: FilterValues = {
      ...currentFilterValues,
      [field]: {
        ...currentFilterValues[field],
        values: checkedValues.map((value) => String(value)),
      },
    };

    setCurrentFilterValues(newFilterValues);
    if (updateFilteredData) updateFilteredData(newFilterValues);
  };

  const checkboxOptions = choices.map((element) => (
    <Row key={element} style={{ paddingTop: '5px' }}>
      <Checkbox value={element}>
        <div style={{ wordBreak: 'break-word' }}>
          {/* Translate only translatable fields*/}
          {isTranslatable ? i18n.t(element) : element}
        </div>
      </Checkbox>
    </Row>
  ));

  const onCheckboxUpdate = (event: CheckboxChangeEvent) => {
    const newFilterValues = {
      ...currentFilterValues,
      [field]: {
        ...currentFilterValues[field],
        includeNull: event.target.checked,
      },
    };
    setCurrentFilterValues(newFilterValues);
    if (updateFilteredData) updateFilteredData(newFilterValues);
  };
  return choices.length === 0 ? (
    <div>{i18n.t('long_messages:null_data_filter')}</div>
  ) : (
    <>
      <Checkbox.Group
        onChange={_onApplyFilter}
        value={currentFilterValues[field].values}
      >
        <Col>{checkboxOptions}</Col>
      </Checkbox.Group>
      <Row style={{ padding: '10px 0px 10px 0px' }}>
        <Button.Group>
          <Button
            disabled={
              currentFilterValues[field].values.length === choices.length
            }
            onClick={() => _onApplyFilter(choices)}
          >
            {i18n.t('buttons:select_all')}
          </Button>
          <Button
            disabled={currentFilterValues[field].values.length === 0}
            onClick={() => _onApplyFilter([])}
          >
            {i18n.t('buttons:deselect_all')}
          </Button>
        </Button.Group>
      </Row>
      {!currentFilterValues[field].required && ( // Do not show include null checkbox if the field is required
        <Row>
          <Checkbox
            checked={currentFilterValues[field].includeNull}
            onChange={onCheckboxUpdate}
          >
            {i18n.t('long_messages:show_null_rows')}
          </Checkbox>
        </Row>
      )}
    </>
  );
}
