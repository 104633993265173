import { Popconfirm, Space, message } from 'antd';
import i18n from 'i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ButtonBasic,
  SearchButton,
} from '../../../elements/buttons/StyledButtons';
import { searchLaceImage } from '../../../utils/backendRequests/imageSearch/createLaceImageSearch';
import { ImageUpload } from '../../upload/ImageUpload';

export function MobileFabricSearch() {
  const [frontImg, setFrontImg] = useState<File | null>(null);
  const [frontImgUrl, setFrontImgUrl] = useState<string | null>(null);
  const navigate = useNavigate();

  const uploadFront = (
    <ImageUpload
      fieldName={i18n.t('column_titles:front_image')}
      setImage={setFrontImg}
      imageUrl={frontImgUrl}
      setImageUrl={setFrontImgUrl}
    />
  );

  const submitSearchButton = () => {
    const submitSearch = (isMobile: boolean) => {
      if (frontImg === null) {
        message.error(i18n.t('error_message:error_null_front_image'));
        return;
      }
      const data = new FormData();
      const frontImgFile = new File([frontImg!], 'search_image_front.jpeg', {
        type: frontImg.type,
      });
      data.append('search_image', frontImgFile);
      searchLaceImage(data, navigate, isMobile);
    };

    return (
      <Popconfirm
        title={i18n.t('headers:view_results')}
        description={i18n.t('long_message:message_view_results_now')}
        okText={i18n.t('buttons:yes')}
        cancelText={i18n.t('buttons:no')}
        onConfirm={() => submitSearch(false)}
        onCancel={() => submitSearch(true)}
      >
        <SearchButton $small={true}>{i18n.t('buttons:search')}</SearchButton>
      </Popconfirm>
    );
  };

  const handleClear = () => {
    setFrontImgUrl(null);
    setFrontImg(null);
  };

  return (
    <Space direction="vertical" size="middle" align="center">
      <span>{i18n.t('fabric_fields:image_url_front')}</span>
      {uploadFront}
      <div>
        <ButtonBasic $small={true} onClick={handleClear}>
          {i18n.t('buttons:clear_all')}
        </ButtonBasic>
        {submitSearchButton()}
      </div>
    </Space>
  );
}
