import { UserUploadsListPage } from '../pages/userUploads/UserUploadsListPage';
import { RouteProps } from './RouteTypes';

export const routesUserUploads: RouteProps[] = [
  {
    path: '/user_uploads',
    title: 'User Uploads',
    key: 'user_uploads_page',
    page: UserUploadsListPage,
  },
];
