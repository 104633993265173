import { Button, Input, Row, Select, Space } from 'antd';
import { useEffect, useState } from 'react';

import { Modal } from '../../components/Modal';
import { TabTitle } from '../../components/TabTitle';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { HelpIcon } from '../../elements/icons/StyledIcons';
import { AdminLayout } from '../../layouts/Admin';
import i18n from '../../plugins/i18n';
import {
  Brand,
  ImageBulkUploadResponse,
} from '../../utils/backendRequests/admin/AdminTypes';
import {
  getBrands,
  parseBrandsResponse,
} from '../../utils/backendRequests/admin/getBrands';
import { imageBulkRename } from '../../utils/backendRequests/admin/imageBulkRename';
import { imageBulkUpload } from '../../utils/backendRequests/admin/imageBulkUpload';
import { TitleProp } from '../PagesTypes';

export function AdminImageBulkUploadFormPage({ title }: TitleProp) {
  const [supplierBrands, setSuppplierBrands] = useState<
    Brand[] | parseBrandsResponse[]
  >([]);
  const [selectedBrandId, setSelectedBrandId] = useState<number | null>(null);
  const [folderName, setFolderName] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [requestType, setRequestType] = useState<'upload' | 'rename'>();
  const [resultText, setResultText] = useState<ImageBulkUploadResponse | null>(
    null
  );

  async function getSupplierBrands() {
    const brandsData = await getBrands('supplier');
    setSuppplierBrands(brandsData);
  }

  useEffect(() => {
    getSupplierBrands();
  }, []);

  const handleRename = async () => {
    if (folderName && selectedBrandId !== null) {
      const res = await imageBulkRename(selectedBrandId, folderName);
      setResultText(res);
    }
  };

  const handleUpload = async () => {
    if (folderName && selectedBrandId !== null) {
      const res = await imageBulkUpload(selectedBrandId, folderName);
      setResultText(res);
    }
  };

  const handleRequest = (type: 'upload' | 'rename') => {
    setRequestType(type);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = () => {
    setResultText(null);
    if (requestType === 'upload') {
      handleUpload();
    } else if (requestType === 'rename') {
      handleRename();
    }
    setIsModalOpen(false);
  };

  return (
    <AdminLayout>
      <HeaderTitle
        title={i18n.t('page_titles:admin_bulk_image_upload')}
        subtitle=""
      />
      <TabTitle title={title} />
      <Modal
        title={i18n.t('headers:confirm_action')}
        open={isModalOpen}
        okText={requestType === 'upload' ? 'Upload' : 'Rename'}
        onOk={handleConfirm}
        onCancel={handleCancel}
      >
        {i18n.t('headers:admin_bulk_image_confirm')}
      </Modal>
      <Row>
        <div style={{ fontFamily: 'inherit', fontSize: 'inherit' }}>
          To bulk upload images, follow these steps:
          <ol>
            <li>
              Rename all your images to the format
              &#123;article_name&#125;_&#123;image_type&#125;. Fabrics with
              duplicate article_name values will not be updated.
            </li>
            <li>
              Place all your images in one folder in the appropriate working
              folder on AWS S3 (see the list below). Ensure that folder names do
              not contain spaces.
            </li>
            <li>
              Select the supplier brand that owns the fabrics and specify the
              path to the folder you created.
            </li>
            <li>
              Click “Rename” to create a new folder named renamed_images, where
              all images will be moved and renamed to the format
              &#123;fabric_hashed_name&#125;_&#123;image_type&#125;.
            </li>
            <li>
              Update the folder name to the newly created folder and click
              “Upload” to upload the images to the fabrics and delete them from
              AWS S3.
            </li>
          </ol>
          Note: If a fabric already has an image of this type, then the new
          image won’t be uploaded.
          <p> Working Folders:</p>
          <ul>
            <li>delvify-dobby/image_bulk_upload - Production AWS S3</li>
            <li>delvify-dobby-staging/image_bulk_upload - Staging AWS S3</li>
          </ul>
          Available image types: front, back, header, macro
        </div>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Space>
          <label htmlFor="brandSelect">Select Supplier Brand</label>
          <Select
            id="brandSelect"
            style={{ width: 200 }}
            onChange={(value) => setSelectedBrandId(value)}
            value={selectedBrandId || undefined}
            placeholder="Select Supplier Brand"
          >
            {supplierBrands.map((brand) => (
              <Select.Option key={brand.id} value={brand.id}>
                {brand.name}
              </Select.Option>
            ))}
          </Select>
        </Space>
      </Row>
      <Row style={{ marginTop: '15px' }}>
        <Space>
          <label htmlFor="folderNameInput">
            {i18n.t('headers:folder_name')}
            {
              <HelpIcon
                title={
                  'Specify the relative path to the folder containing images, starting from the image_bulk_upload folder on AWS. Do not include a leading /. For example, if the images are in the directory delvify-dobby-staging/image_bulk_upload/Talent/2nd_upload where delvify-dobby-staging is the bucket name, the value should be Talent/2nd_upload.'
                }
                margin="'0 3px 0 3px'"
              />
            }
            :
          </label>
          <Input
            id="folderNameInput"
            style={{ width: 200 }}
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            placeholder={i18n.t('headers:enter_folder_name')}
          />
        </Space>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Space>
          <Button type="primary" onClick={() => handleRequest('rename')}>
            {i18n.t('buttons:rename')}
          </Button>
          <Button type="primary" onClick={() => handleRequest('upload')}>
            {i18n.t('buttons:upload')}
          </Button>
        </Space>
      </Row>
      <Row>
        {resultText && (
          <div
            style={{
              color: resultText.isSuccess ? 'green' : 'red',
              marginTop: '10px',
            }}
          >
            *{resultText.text}
          </div>
        )}
      </Row>
    </AdminLayout>
  );
}
