import { message } from 'antd';
import { api } from '../api/api';
import { DesignerUserValues } from './AdminTypes';

/**
 * Updates an existing designer user with new user
 * values submitted form a form using the ID to update
 */
export async function editDesignerUser(
  user: DesignerUserValues,
  userId: string
): Promise<boolean> {
  try {
    return await api({
      method: 'PUT',
      url: `v1/superuser/user/designer/${userId}`,
      body: user,
    }).then(() => {
      message.success('Designer User successfully updated.');
      return true;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return false;
  }
}
