import { Tabs } from 'antd';
import i18n from 'i18next';

import { TabTitle } from '../../../components/TabTitle';
import { ImageSearchList } from '../../../components/table/searchTable/imageSearchList';
import { TextSearchList } from '../../../components/table/searchTable/textSearchList';
import { HeaderTitle } from '../../../components/title/HeaderTitle';
import { MainLayout } from '../../../layouts/Main';
import { TitleProp } from '../../PagesTypes';

export const HistoryListPage = ({ title }: TitleProp) => {
  const searchItems = [
    {
      key: '0',
      label: i18n.t('headers:text_search'),
      children: <TextSearchList />,
    },
    {
      key: '1',
      label: i18n.t('headers:image_search'),
      children: <ImageSearchList />,
    },
  ];

  return (
    <MainLayout>
      <HeaderTitle title={i18n.t('page_titles:search_history_page')} />
      <TabTitle title={title} />
      <Tabs items={searchItems} />
    </MainLayout>
  );
};
