import { Col, Row } from 'antd';

import { UserInformation } from './UserInformation';

export function ProfileCards() {
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col flex={'1 0 50%'}>
          <UserInformation />
        </Col>
      </Row>
    </>
  );
}
