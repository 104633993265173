import {
  returnFieldPropertyObj,
  returnOrderedFields,
} from '../../utils/fabrics/parseFabricFields';
import { BasicFields } from './FabricTypes';

export const formFields = returnOrderedFields([
  'fabric_type',
  'name',
  'original_supplier',
  'name_from_original_supplier',
  'weight_grams_per_sqm',
  'width_cm',
  'composition1_id',
  'composition1_percentage',
  'composition2_id',
  'composition2_percentage',
  'composition3_id',
  'composition3_percentage',
  'composition4_id',
  'composition4_percentage',
  'construction_id',
  'description',
  'notes',
  'colour_ids',
  'country_of_origin_id',
  'colour_fastness',
  'wash_care',
  'piling',
  'gauge_inch',
  'yarn_count',
  'stretch',
  'functional_finish',
  'season',
  'creation_date',
  'usage_category',
  'look',
  'special_yarn',
  'sustainability',
  'hand_feel',
  'repeat',
  'lace_structure',
  'pattern_design',
  'aesthetic_finish',
  'edge_finish',
  'price_per_sqm',
  'price_per_m',
  'price_per_kg',
  'price_per_piece',
  'currency_id',
  'lead_time_days',
  'availability',
  'moq_m',
  'mcq_m',
  'moq_sqm',
  'mcq_sqm',
  'moq_kg',
  'mcq_kg',
  'moq_piece',
  'mcq_piece',
  'stock_m',
  'stock_sqm',
  'stock_kg',
  'stock_piece',
  'weight_grams_per_m',
  'weight_grams_per_piece',
  'length_cm_per_piece',
  'certifications',
]);

// initialises the fields: singleDropdown as null, multiDropdown as [], and everything else as ''
export const initialFabricFormFields = Object.fromEntries(
  formFields.map((field: BasicFields) => {
    let initialValue: string | unknown[] | null = '';

    switch (field.fieldType) {
      case 'singleDropdown':
        initialValue = null;
        break;
      case 'multiDropdown':
        initialValue = [];
        break;
      default:
        initialValue = '';
    }

    return [field.name, initialValue];
  })
);

// The formFields which contain a stringMax property
export const fieldMaxLengths = returnFieldPropertyObj(formFields, 'stringMax');

// The formFields which contain a numberRange property
export const fieldNumberMinMax = returnFieldPropertyObj(
  formFields,
  'numberRange'
);

// The formFields which contain a DateRange property
export const fieldValidDateRange = returnFieldPropertyObj(
  formFields,
  'dateRange'
);
