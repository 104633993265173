import { Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { TabTitle } from '../../../components/TabTitle';
import { AuthForm } from '../../../components/form/AuthForm';
import { HeaderTitle } from '../../../components/title/HeaderTitle';
import { AuthLayout } from '../../../layouts/auth/Auth';
import i18n from '../../../plugins/i18n';
import { routesNamedDict } from '../../../routes';
import { resetPassword } from '../../../utils/backendRequests/auth/resetPassword';
import { TitleProp } from '../../PagesTypes';
import { ResetValues } from '../AuthTypes';
import { validation } from './validation';

export function PasswordResetPage({ title }: TitleProp) {
  const navigate = useNavigate();

  const handleSubmit = async (values: ResetValues) => {
    const email = values.email;
    await resetPassword(email, navigate, routesNamedDict);
  };

  return (
    <AuthLayout>
      <TabTitle title={title} />
      <Space direction="vertical" size="small">
        <HeaderTitle
          title={i18n.t('page_titles:password_reset_page')}
          subtitle={i18n.t('page_subtitles:password_reset_sub')}
        />
        <AuthForm
          formType="reset"
          handleSubmit={handleSubmit}
          routesNamedDict={routesNamedDict}
          validation={validation}
        />
      </Space>
    </AuthLayout>
  );
}
