import { message } from 'antd';

import { api } from '../api/api';

/**
 * Gets possible fabric types.
 */
export const getFabricTypes = async (): Promise<Record<string, string>> => {
  try {
    return await api({
      method: 'GET',
      url: `v1/list/fabric_types`,
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
};
