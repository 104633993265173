import { useState } from 'react';

import i18n from '../../../plugins/i18n';
import { UserProps } from '../../../utils/backendRequests/user/getCurrentUser';
import { EditUserModal } from '../../modal/userManagement/EditUserModal';

interface EditUserProps {
  user: UserProps;
  onSuccess: () => void;
}

export function EditUser({ user, onSuccess }: EditUserProps) {
  const [updateModal, setUpdateModal] = useState({ visible: false });
  const handleUpdate = () => {
    setUpdateModal({ visible: true });
  };

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <a onClick={handleUpdate} role="button">
          {i18n.t('buttons:edit')}
        </a>
      </div>
      {updateModal.visible && (
        <EditUserModal
          modal={updateModal}
          setModal={setUpdateModal}
          user={user}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
}
