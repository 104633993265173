import { message } from 'antd';

import { api } from '../api/api';
import { UserProps } from './getCurrentUser';

/**
 * Return all users for user management page
 */

export async function getAllUsers(): Promise<UserProps[]> {
  try {
    return await api({
      method: 'GET',
      url: `v1/user`,
    }).then((res: UserProps[]) => {
      return res;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
