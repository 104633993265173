import { record } from '../types/Types';

/**
 * Filters out keys from the records where all values are null or empty arrays.
 */
export const filterNullFields = (data: record[] | undefined): string[] => {
  if (!data || !data.length) return []; // Check if data is undefined or empty

  const nullKeys: { [key: string]: number } = {};

  // Iterate through each fabric
  data.forEach((fabric: record) => {
    // Iterate through each property of the fabric
    for (const key in fabric) {
      const value = fabric[key as keyof record];
      // Check if the property value is null or an empty array
      if (value === null || (Array.isArray(value) && value.length === 0)) {
        // Add the key to the nullKeys object and increment the count
        nullKeys[key] = (nullKeys[key] || 0) + 1;
      }
    }
  });

  // Filter keys that are null in all records
  const nullKeysAllRecords = Object.keys(data[0]).filter(
    (key) => nullKeys[key] === data!.length
  );

  return nullKeysAllRecords;
};
