import { message } from 'antd';
import { api } from '../api/api';
import { ImageBulkUploadResponse } from './AdminTypes';

/**
 * Renames images in bulk for a specified supplier and folder.
 */
export async function imageBulkRename(
  supplierId: number,
  folderName: string
): Promise<ImageBulkUploadResponse | null> {
  const data = {
    supplier_brand_id: supplierId,
    folder_name: folderName,
  };

  return api({
    method: 'PUT',
    url: 'v1/superuser/fabric/image_bulk_upload/rename_images',
    body: data,
  })
    .then((response) => {
      // Check if the renaming was unsuccessful
      if (response.Errors) {
        const errorsString = JSON.stringify(response.Errors);
        message.error(errorsString);
        return {
          text: `${JSON.stringify(response.msg)}\n${errorsString}`,
          isSuccess: false,
        };
      } else {
        message.success('Bulk Image Folder successfully renamed.');
        return {
          text: 'Bulk Image Folder successfully renamed.',
          isSuccess: true,
        };
      }
    })
    .catch((error) => {
      if (error instanceof Error) {
        message.error(error.message);
        return { text: error.message, isSuccess: false };
      }
      return null;
    });
}
