import styled from 'styled-components';

import { Color } from '../base/Color';
import { FontSize, FontWeight } from '../base/Font';

export const SloganH4 = styled.h4`
  color: ${Color.White} !important;
  font-size: ${FontSize.H4};
  font-weight: ${FontWeight.Bold};
  margin: 50px;
`;
