import { CameraOutlined } from '@ant-design/icons';
import { Button, Input, message, Space } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  ButtonBasic,
  SearchButton,
} from '../../elements/buttons/StyledButtons';
import { SearchBarWrapper } from '../../styles/wrappers/SearchbarWrapper';
import { searchLaceImage } from '../../utils/backendRequests/imageSearch/createLaceImageSearch';
import { Modal } from '../Modal';
import { ImageUpload } from '../upload/ImageUpload';
import { SearchbarProps } from './SearchTypes';

export function DesktopSearchbar({ onSearch }: SearchbarProps) {
  const [modal, setModal] = useState({ visible: false });
  const [frontImg, setFrontImg] = useState<File | null>(null);
  const [frontImgUrl, setFrontImgUrl] = useState<string | null>(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { Search } = Input;

  const uploadFront = (
    <ImageUpload
      fieldName={i18n.t('column_titles:front_image')}
      setImage={setFrontImg}
      imageUrl={frontImgUrl}
      setImageUrl={setFrontImgUrl}
    />
  );

  const submitSearchButton = () => {
    const submitSearch = () => {
      if (frontImg === null) {
        message.error(i18n.t('error_message:error_null_front_image'));
        return;
      }
      const data = new FormData();
      const frontImgFile = new File([frontImg!], 'search_image_front.jpeg', {
        type: frontImg.type,
      });
      data.append('search_image', frontImgFile);
      searchLaceImage(data, navigate, false);
    };

    return (
      <SearchButton $small={true} onClick={() => submitSearch()}>
        {i18n.t('buttons:search')}
      </SearchButton>
    );
  };

  const handleClear = () => {
    setFrontImgUrl(null);
    setFrontImg(null);
  };

  /**
   * Covers the situation where a user is on the search page
   * and makes a new search. This ensures the image search modal gets closed.
   * Refactoring is recommended but will take time.
   */
  useEffect(() => {
    if (state?.closeModal) {
      setModal({
        visible: false,
      });
      setFrontImgUrl(null);
      setFrontImg(null);
    }
  }, [state]);

  const showModal = () => {
    setModal({
      visible: true,
    });
  };

  const handleCancel = () => {
    setModal({
      visible: false,
    });
    setFrontImgUrl(null);
    setFrontImg(null);
  };

  return (
    <>
      <Modal
        title={i18n.t('headers:lace_image_search')}
        open={modal.visible}
        onCancel={handleCancel}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Space direction="vertical" size="middle" align="center">
          <span>{i18n.t('fabric_fields:image_url_front')}</span>
          {uploadFront}
          <div>
            <ButtonBasic $small={true} onClick={handleClear}>
              {i18n.t('buttons:clear_all')}
            </ButtonBasic>
            {submitSearchButton()}
          </div>
        </Space>
      </Modal>
      <Space.Compact>
        <SearchBarWrapper>
          <Search
            onSearch={onSearch}
            placeholder={i18n.t('long_message:message_input_search_text')}
          />
        </SearchBarWrapper>
        <Button onClick={showModal}>
          <CameraOutlined />
        </Button>
      </Space.Compact>
    </>
  );
}
