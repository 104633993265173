import styled from 'styled-components';
import { TextB2 } from '../typography/TextB2';

export const ColorCircle = styled.div<{
  $hexValue: string;
  $tooltipShow: boolean;
}>`
  width: 20px;
  height: 20px;
  background-color: ${({ $hexValue }) => $hexValue};
  border-radius: 50%;
  outline: #d3d3d3 0.5px solid;
  cursor: ${({ $tooltipShow }) => ($tooltipShow ? 'pointer' : 'default')};
`;

export const ColourTableText = styled(TextB2)`
  word-wrap: break-word;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
