import { message } from 'antd';
import { isAxiosError } from 'axios';

import { api } from '../api/api';

/**
 * Gets the list of approved users that the user's brand has approved
 */

export type ApprovedUsers = {
  key: string;
  value: string;
}[];

export async function getApprovedUsers(): Promise<ApprovedUsers> {
  try {
    const response = await api({
      method: 'GET',
      url: 'v1/list/approved_users',
    });
    // Response returns a dictionary with {User ID: User} as key and value pairs
    // This maps the dictionary into an array of objects with key: User Id, value: User
    const approvedUsers = Object.entries(response).map(([key, value]) => ({
      key,
      value,
    })) as ApprovedUsers;
    return approvedUsers;
  } catch (error) {
    if (isAxiosError(error)) {
      message.error((error.response?.data as { msg?: string })?.msg);
    }
    throw error;
  }
}
