import { Row, Space } from 'antd';
import { Key } from 'antd/lib/table/interface'; // Import Key type
import i18n from 'i18next';
import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { TableGeneric } from '../../components/table/TableGeneric';
import { ButtonBasic } from '../../elements/buttons/StyledButtons';
import { RootAuth } from '../../utils/types/Types';
import { PermanentlyDeleteModal } from '../modal/bin/PermanentlyDeleteModal';
import { RestoreModal } from '../modal/bin/RestoreModal';
import { BinTabProps } from './BinTypes';

export function BinTab({
  binData,
  binColumns,
  getBinContents,
  binType,
}: BinTabProps) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]); // Ensure selectedRowKeys is initialized with Key[] type
  const [restoreModal, setRestoreModal] = useState({ visible: false });
  const [deleteModal, setDeleteModal] = useState({ visible: false });
  const account = useSelector((state: RootAuth) => state.auth.accountType);

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys: Key[]) => setSelectedRowKeys(keys), // Correctly handle onChange event
  };

  // Convert selectedRowKeys from Key[] to number[]
  const selectedRowKeysAsNumbers: number[] = selectedRowKeys.map((key) =>
    Number(key)
  );

  // Reset selected rows after data updates
  useEffect(() => {
    setSelectedRowKeys([]);
  }, [binData]);

  return (
    <>
      <Row justify="start">
        {account !== 'viewer' && (
          <Space>
            <ButtonBasic
              disabled={selectedRowKeys.length === 0}
              onClick={() => setRestoreModal({ visible: true })}
            >
              {i18n.t('buttons:restore')}
            </ButtonBasic>
            <ButtonBasic
              danger
              disabled={selectedRowKeys.length === 0}
              onClick={() => setDeleteModal({ visible: true })}
            >
              {i18n.t('buttons:permanently_delete')}
            </ButtonBasic>
          </Space>
        )}
      </Row>
      <Row>
        <TableGeneric
          columns={binColumns}
          data={binData}
          rowSelection={rowSelection}
        />
        <RestoreModal
          modal={restoreModal}
          setModal={setRestoreModal}
          type={binType}
          selectedRowKeys={selectedRowKeysAsNumbers} // Pass selectedRowKeys as numbers
          data={binData}
          getBinContents={getBinContents}
        />
        <PermanentlyDeleteModal
          modal={deleteModal}
          setModal={setDeleteModal}
          type={binType}
          selectedRowKeys={selectedRowKeysAsNumbers} // Pass selectedRowKeys as numbers
          data={binData}
          getBinContents={getBinContents}
        />
      </Row>
    </>
  );
}
