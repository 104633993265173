import { Row } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TabTitle } from '../../components/TabTitle';
import { AdminFabricList } from '../../components/table/adminFabricList/AdminFabricList';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { ButtonBasic } from '../../elements/buttons/StyledButtons';
import { AdminLayout } from '../../layouts/Admin';
import i18n from '../../plugins/i18n';
import {
  AdminLabel,
  Fabrics,
} from '../../utils/backendRequests/admin/AdminTypes';
import { getAllFabrics } from '../../utils/backendRequests/admin/getAllFabrics';
import { TitleProp } from '../PagesTypes';

/**
 * Admin page for viewing and updating the machine learning training label
 * of all lace fabrics.
 */
export function AdminFabricListPage({ title }: TitleProp) {
  const [data, setData] = useState<Fabrics>([]);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const location = useLocation();
  // Extract label from location.state
  const label: AdminLabel = location.state.label;
  const isAllLabels: boolean = location.state.isAllLabels;

  const getMLTrainingLaceFabrics = async () => {
    if (label === undefined) return;
    const fabricData = await getAllFabrics(label.name, label.isNull);
    setData(fabricData);
  };

  const getAllLabelFabrics = async () => {
    const fabricData = await getAllFabrics();
    setData(fabricData);
  };

  useEffect(() => {
    if (isAllLabels) {
      getAllLabelFabrics();
    } else {
      getMLTrainingLaceFabrics();
    }
  }, [label, isAllLabels]);

  return (
    <AdminLayout>
      <HeaderTitle title={i18n.t('page_titles:admin_all_fabrics')} />
      <TabTitle title={title} />
      <Row>
        <div
          style={{
            fontFamily: 'inherit',
            fontSize: 'inherit',
            maxWidth: '40vw',
            paddingBottom: '20px',
          }}
        >
          Double-click on the label to edit it, then click outside or press
          Enter to save your changes.
        </div>
      </Row>
      <ButtonBasic
        disabled={data.length === 0}
        onClick={() => setUpdateModal(true)}
      >
        {i18n.t('buttons:update_training_label')}
      </ButtonBasic>
      <AdminFabricList
        title={title}
        data={data}
        isModalOpen={updateModal}
        setIsModalOpen={setUpdateModal}
        setData={setData}
      />
    </AdminLayout>
  );
}
