import styled from 'styled-components';
import { Color } from '../../elements/base/Color';

export const HeaderWrapper = styled.div`
  align-items: center;
  background: ${Color.G1};
  display: flex;
  height: 50px;
  position: fixed; /* Makes the header fixed */
  top: 0; /* Positions it at the top of the viewport */
  width: 100%; /* Ensures it spans the full width */
  z-index: 1000; /* Ensures it stays above other content */
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow for separation */
`;
