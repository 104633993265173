type FontSizeType = {
  H1: string;
  H2: string;
  H3: string;
  H4: string;
  H5: string;
  H6: string;
  H7: string;
  Body1: string;
  Body2: string;
  Caption: string;
  Small: string;
};

type FontWeightType = {
  Regular: number;
  SemiBold: number;
  Bold: number;
};

export const FontSize: FontSizeType = {
  H1: '56px',
  H2: '36px',
  H3: '32px',
  H4: '28px',
  H5: '24px',
  H6: '20px',
  H7: '18px',
  Body1: '16px',
  Body2: '14px',
  Caption: '12px',
  Small: '10px',
};

export const FontWeight: FontWeightType = {
  Regular: 400,
  SemiBold: 600,
  Bold: 700,
};
