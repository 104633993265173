import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Form, Input } from 'antd';

import { AuthFormProps } from '../../components/form/FormTypes';
import { SubmitButton } from '../../elements/buttons/StyledButtons';
import { LinkB1 } from '../../elements/typography/LinkB1';
import { FormWrapper } from '../../elements/wrapper/FormWrapper';

/**
 * @param {string} formType: either 'login' or 'reset'
 * @param {function} handlesubmit
 * @param {object} initialValuesForm: initial values for the form
 * @param {object} routesNamedDict: object with react router routes
 * @param {object} validation: object with form validation rules
 */
export function AuthForm({
  formType,
  handleSubmit,
  routesNamedDict,
  validation,
}: AuthFormProps) {
  return (
    <FormWrapper>
      <Form name={formType} onFinish={handleSubmit}>
        <Form.Item name="email" rules={validation.email}>
          <Input prefix={<UserOutlined />} placeholder="email" />
        </Form.Item>
        {formType === 'login' && (
          <Form.Item name="password" rules={validation.password}>
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
        )}
        <Form.Item>
          <SubmitButton htmlType="submit">
            {formType === 'login' ? 'Log in' : 'Submit'}
          </SubmitButton>
        </Form.Item>
        <Form.Item style={{ float: 'right' }}>
          <LinkB1
            to={
              formType === 'login'
                ? routesNamedDict.PASSWORD_RESET
                : routesNamedDict.LOGIN
            }
            className="link"
          >
            {formType === 'login' ? 'Forgot Password' : 'Back To Login'}
          </LinkB1>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
}
