import { DownloadOutlined } from '@ant-design/icons';
import { Row, Space } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TabTitle } from '../components/TabTitle';
import { CreateBulkUploadModal } from '../components/modal/bulkUploadModal/CreateBulkUploadModal';
import { BulkUploadList } from '../components/table/bulkUploadList/BulkUploadList';
import { HeaderTitle } from '../components/title/HeaderTitle';
import {
  ButtonBasic,
  DownloadTemplateButton,
} from '../elements/buttons/StyledButtons';
import { MainLayout } from '../layouts/Main';
import { routesNamedDict } from '../routes';
import { BulkUpload } from '../utils/backendRequests/bulkUpload/BulkUploadTypes';
import { getBulkUploads } from '../utils/backendRequests/bulkUpload/getBulkUploads';
import { configureLanguageTemplates } from '../utils/parsers/parseDownloadTemplate';
import {
  DownloadTemplateProp,
  RootAuth,
  RootState,
} from '../utils/types/Types';
import { TitleProp } from './PagesTypes';

export const BulkUploadPage = ({ title }: TitleProp) => {
  const [data, setData] = useState<BulkUpload[]>([]);
  const [newModal, setNewModal] = useState({ visible: false });
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  const language = useSelector((state: RootState) => state.common.language);
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  const { role } = useSelector((state: RootAuth) => state.auth);
  const [downloadTemplateURLS, setDownloadTemplateURLS] =
    useState<DownloadTemplateProp>({
      knit: '',
      lace: '',
      woven: '',
    });

  // Set URLs for template download using language
  useEffect(() => {
    configureLanguageTemplates(language, setDownloadTemplateURLS);
  }, [language]);

  const fetchData = async () => {
    setData(await getBulkUploads());
  };

  useEffect(() => {
    fetchData();
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    // Redirect if role is not 'supplier'
    if (role !== 'supplier') {
      navigate('/' + routesNamedDict.NOT_FOUND);
    }
  }, [role, navigate]);

  return (
    <MainLayout>
      <HeaderTitle
        title={i18n.t('page_titles:import_page')}
        subtitle={i18n.t('page_subtitles:import_sub')}
      />
      <TabTitle title={title} />
      <CreateBulkUploadModal
        modal={newModal}
        setModal={setNewModal}
        fetchData={fetchData}
      />
      <Row justify="start">
        <Space direction={isMobileFlag ? 'vertical' : 'horizontal'}>
          {account !== 'viewer' && (
            <ButtonBasic onClick={() => setNewModal({ visible: true })}>
              {i18n.t('buttons:create')}
            </ButtonBasic>
          )}
          <DownloadTemplateButton
            rel="noopener noreferrer"
            href={downloadTemplateURLS.knit}
          >
            <ButtonBasic>
              <DownloadOutlined />
              {i18n.t('buttons:knit_template')}
            </ButtonBasic>
          </DownloadTemplateButton>
          <DownloadTemplateButton
            rel="noopener noreferrer"
            href={downloadTemplateURLS.lace}
          >
            <ButtonBasic>
              <DownloadOutlined />
              {i18n.t('buttons:lace_template')}
            </ButtonBasic>
          </DownloadTemplateButton>
          <DownloadTemplateButton
            rel="noopener noreferrer"
            href={downloadTemplateURLS.woven}
          >
            <ButtonBasic>
              <DownloadOutlined />
              {i18n.t('buttons:woven_template')}
            </ButtonBasic>
          </DownloadTemplateButton>
        </Space>
      </Row>
      <Row>
        <BulkUploadList title={title} data={data} />
      </Row>
    </MainLayout>
  );
};
