import { store } from '../../plugins/redux';
import { setLibraryList } from '../../plugins/redux/fabricLibrary';
import { setProjectList } from '../../plugins/redux/project';
import { getLibraries } from '../backendRequests/list/libraries';
import { getProjects } from '../backendRequests/project/getProjects';
import { FabricLibraries, FabricProjects } from './ReduxTypes';
/**
 * Makes an API call for available fabric libraries the current
 * user can view. The results of the call are stored
 * in redux.
 */
export const refreshFabricLibraryList = async () => {
  await getLibraries().then((libraries: FabricLibraries) => {
    store.dispatch(setLibraryList(libraries));
  });
};

/**
 * Makes an API call for available projects the current
 * user can view. The results of the call are stored
 * in redux.
 */
export const refreshProjectList = async () => {
  await getProjects().then((projects: FabricProjects) => {
    store.dispatch(setProjectList(projects));
  });
};
