import { message } from 'antd';
import { MsgProp } from '../../types/Types';
import { api } from '../api/api';
import { AdminCertReviewBodyProps } from './AdminTypes';

/**
 * Verify a certification details by id.
 */
export async function verifyCertificationDetails(
  cert_details_id: number,
  body: AdminCertReviewBodyProps
): Promise<MsgProp> {
  try {
    return await api({
      method: 'PUT',
      url: `v1/superuser/certification/verify/${cert_details_id}`,
      body: body,
    }).then((res: MsgProp) => {
      return res;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
