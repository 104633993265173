import { isAxiosError } from 'axios';
import { apiAuth } from '../api/apiAuth';

/**
 * Verifies if the user admin token is still valid.
 *
 * @return {boolean} - true if admin token is valid, false otherwise.
 */
export async function verifyAdmin(): Promise<boolean> {
  try {
    await apiAuth({
      method: 'get',
      url: 'v1/superuser/verify',
    });
    return true; // If the request is successful, token is valid
  } catch (error) {
    if (isAxiosError(error)) {
      return false; // If an Axios error occurs, token is invalid
    }
    return false; // Handle other errors if needed
  }
}
