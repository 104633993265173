import { parseFormLabel } from '../../../utils/parsers/parseFormLabel';
import { RulesObject } from '../../../utils/types/Types';
import {
  maxLengthRule,
  requiredRule,
} from '../../../utils/validations/formRules';
import { formFieldsProp } from '../../form/FormTypes';

export const userFieldRules = (inputFields: formFieldsProp[]) => {
  const rules: RulesObject = {};

  // Iterate over inputFields array using a regular for loop
  for (const field of inputFields) {
    // initialize rules object with empty arrays for each field
    rules[field.name] = [];
    // Add required rule
    if (field.required)
      rules[field.name] = rules[field.name].concat(
        requiredRule(parseFormLabel(field.name))
      );
    // Add max length rules
    if (field.fieldType === 'string' && field.stringMax) {
      rules[field.name] = rules[field.name].concat(
        maxLengthRule(parseFormLabel(field.name), field.stringMax)
      );
    }
  }

  return rules;
};
