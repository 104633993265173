import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { TabTitle } from '../../components/TabTitle';
import { TransformedFilterProps } from '../../components/filter/FilterTypes';
import { FabricSearchResults } from '../../components/search/fabric/FabricSearchResults';
import { defaultPageSize } from '../../components/table/TableGeneric';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { MainLayout } from '../../layouts/Main';
import i18n from '../../plugins/i18n';
import { getImageSearch } from '../../utils/backendRequests/imageSearch/getImageSearch';
import { getFabricTypes } from '../../utils/backendRequests/list/getFabricTypes';
import { refreshTextFabric } from '../../utils/backendRequests/textSearch/fabricRefresh';
import { record } from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export function SearchResultsPage({ title }: TitleProp) {
  const { state } = useLocation();
  const { textHashedId, imageHashedId } = useParams();
  const [searchResults, setSearchResults] = useState<record[]>([]);
  const [confidence, setConfidence] = useState<number | null>(null);
  const [construction, setConstruction] = useState<string | null>(null);
  const [fabricTypes, setFabricTypes] = useState<Record<string, string>>({});
  const [fabricTypeID, setFabricTypeID] = useState<number>();
  const [textSearchQuery, setTextSearchQuery] = useState<string>();
  const [selectableColumnNames, setSelectableColumnNames] = useState<string[]>(
    []
  );
  const [sortableColumnNames, setSortableColumnNames] = useState<string[]>([]);
  const [filterableFields, setFilterableFields] =
    useState<TransformedFilterProps>({});
  const [filterableColumns, setFilterableColumns] =
    useState<TransformedFilterProps>({});

  // Pagination and Sorter state
  const [tableState, setTableState] = useState({
    current: 1,
    pageSize: defaultPageSize,
    total: 0,
    sortField: '',
    sortOrder: '',
  });

  const getImageSearchResults = async () => {
    if (imageHashedId === undefined) return;
    const results = await getImageSearch(
      imageHashedId,
      tableState,
      filterableColumns
    );
    setSearchResults(results.result_fabrics);
    setConfidence(results.confidence);
    setConstruction(results.construction);
    setFabricTypeID(results.fabric_type);
    setTableState((prevState) => ({
      ...prevState,
      total: results.fabrics_count,
    }));
    setSelectableColumnNames(results.selectable_columns);
    setSortableColumnNames(results.sortable_columns);
    if (Object.keys(filterableFields).length === 0) {
      setFilterableFields(results.filterable_columns);
    }
  };

  const getTextSearchResults = async () => {
    if (textHashedId === undefined) return;
    const results = await refreshTextFabric(
      textHashedId,
      tableState,
      filterableColumns
    );
    setTextSearchQuery(results.text_query);
    setSearchResults(results.result_fabrics);
    setTableState((prevState) => ({
      ...prevState,
      total: results.fabrics_count,
    }));
    setSelectableColumnNames(results.selectable_columns);
    setSortableColumnNames(results.sortable_columns);
    if (Object.keys(filterableFields).length === 0) {
      setFilterableFields(results.filterable_columns);
    }
  };

  const fetchFilteredData = async () => {
    if (imageHashedId !== undefined) {
      setTableState((prevState) => ({
        ...prevState,
        current: 1,
      }));
      const results = await getImageSearch(
        imageHashedId,
        tableState,
        filterableColumns
      );
      setSearchResults(results.result_fabrics);
      setConfidence(results.confidence);
      setConstruction(results.construction);
      setFabricTypeID(results.fabric_type);
      setTableState((prevState) => ({
        ...prevState,
        current: 1,
        total: results.fabrics_count,
      }));
      setSelectableColumnNames(results.selectable_columns);
      setSortableColumnNames(results.sortable_columns);
    } else if (textHashedId !== undefined) {
      setTableState((prevState) => ({
        ...prevState,
        current: 1,
      }));
      const results = await refreshTextFabric(
        textHashedId,
        tableState,
        filterableColumns
      );
      setTextSearchQuery(results.text_query);
      setSearchResults(results.result_fabrics);
      setTableState((prevState) => ({
        ...prevState,
        current: 1,
        total: results.fabrics_count,
      }));
      setSelectableColumnNames(results.selectable_columns);
      setSortableColumnNames(results.sortable_columns);
    }
  };

  const fetchData = async () => {
    if (state === null) {
      if (textHashedId !== undefined) {
        getTextSearchResults();
      } else if (imageHashedId !== undefined) {
        // Pass setConfidence and setConstruction to setImageSearch
        getImageSearchResults();
      }
    } else {
      setSearchResults(state.result_fabrics);
      setConfidence(state.confidence || null);
      setConstruction(state.construction || null);
      setTextSearchQuery(state.text_query || null);
      setFabricTypeID(state.fabric_type || null);
      setTableState((prevState) => ({
        ...prevState,
        total: state.fabrics_count || 0,
      }));
      setSelectableColumnNames(state.selectable_columns);
      setSortableColumnNames(state.sortable_columns);
      if (Object.keys(filterableFields).length === 0) {
        setFilterableFields(state.filterable_columns);
      }
    }
    window.history.replaceState({}, '');
  };

  useEffect(() => {
    fetchData();
  }, [imageHashedId, textHashedId, state]);

  // Fetch image search results when pagination or sorter changes
  useEffect(() => {
    if (searchResults.length > 0 && imageHashedId !== undefined) {
      getImageSearchResults();
    } else if (searchResults.length > 0 && textHashedId !== undefined) {
      getTextSearchResults();
    }
  }, [
    tableState.current,
    tableState.pageSize,
    tableState.sortField,
    tableState.sortOrder,
  ]);

  useEffect(() => {
    const getFabricTypesMap = async () => {
      const fabricSearchType = await getFabricTypes();
      setFabricTypes(fabricSearchType);
    };
    getFabricTypesMap();
  }, []);

  const searchTitle = textHashedId
    ? i18n.t('page_titles:text_search_results_page')
    : i18n.t('page_titles:image_search_results_page');

  const searchSubtitle = textHashedId
    ? i18n.t('page_subtitles:text_search_sub', { query: textSearchQuery })
    : i18n.t('page_subtitles:image_search_sub', {
        search_type: fabricTypes[fabricTypeID as number],
      });

  return (
    <MainLayout>
      <HeaderTitle title={searchTitle} subtitle={searchSubtitle} />
      <TabTitle title={title} />
      <FabricSearchResults
        confidence={confidence}
        construction={construction}
        initialData={searchResults}
        searchType={fabricTypes[fabricTypeID as number]}
        tableState={tableState}
        setTableState={setTableState}
        selectableColumnNames={selectableColumnNames}
        sortableColumns={sortableColumnNames}
        filterableFields={filterableFields}
        filterableColumns={filterableColumns}
        setFilterableColumns={setFilterableColumns}
        getFilteredFabrics={fetchFilteredData}
        getInitalData={fetchData}
      />
    </MainLayout>
  );
}
