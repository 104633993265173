import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { TabTitle } from '../../components/TabTitle';
import { EditFabricForm } from '../../components/fabric/edit/EditFabricForm';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { MainLayout } from '../../layouts/Main';
import i18n from '../../plugins/i18n';
import { routesNamedDict } from '../../routes';
import { getFabricDetails } from '../../utils/backendRequests/fabric/getFabricDetails';
import { RootAuth, RootState, record } from '../../utils/types/Types';

export function EditFabricPage() {
  const navigate = useNavigate();
  const { fabHashedName } = useParams<{ fabHashedName?: string }>();
  const [fabric, setFabric] = useState<record | Record<string, never>>({});
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  const { role, accountType } = useSelector((state: RootAuth) => state.auth);

  const getFabricData = async () => {
    const fabricData = await getFabricDetails(
      fabHashedName as string,
      navigate
    );
    setFabric(fabricData as record);
  };

  useEffect(() => {
    if (!fabHashedName || role !== 'supplier' || accountType === 'viewer') {
      navigate('/' + routesNamedDict.NOT_FOUND);
    } else {
      getFabricData();
    }
  }, [fabHashedName, navigate, role]);

  // Only return the FabricForm if fabric has been fully populated
  return (
    Object.keys(fabric).length !== 0 && (
      <MainLayout>
        <HeaderTitle title={i18n.t('page_titles:edit_fabric_page')} />
        <TabTitle title={'Edit Fabric'} />
        <EditFabricForm
          formType="edit"
          fabHashedName={fabHashedName || ''}
          fabric={fabric as record}
          isMobile={isMobileFlag}
        />
      </MainLayout>
    )
  );
}
