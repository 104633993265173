import { formFieldsProp } from '../../form/FormTypes';

export const updateUserFields: formFieldsProp[] = [
  {
    name: 'name',
    fieldType: 'string',
    required: true,
    stringMax: 50,
  },
  {
    name: 'email',
    fieldType: 'string',
    required: true,
    stringMax: 50,
  },
  {
    name: 'is_active',
    fieldType: 'boolean',
    required: true,
  },
  {
    name: 'is_email_notified',
    fieldType: 'boolean',
    required: true,
  },
  {
    name: 'is_2fa_enabled',
    fieldType: 'boolean',
    required: true,
  },
  {
    name: 'account_type',
    fieldType: 'singleDropdown',
    required: true,
    isTranslatable: true,
  },
  {
    name: 'measurement_unit',
    fieldType: 'singleDropdown',
    required: true,
    isTranslatable: true,
  },
];
