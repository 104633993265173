import { useEffect, useState } from 'react';

import { TableGeneric } from '../../../components/table/TableGeneric';
import { BulkUpload } from '../../../utils/backendRequests/bulkUpload/BulkUploadTypes';
import { TabTitle } from '../../TabTitle';
import { columnFields } from '../../table/TableTypes';
import { BulkUploadColumns } from './BulkUploadColumns';

interface BulkUploadListProps {
  title: string;
  data: BulkUpload[];
}

export function BulkUploadList({ title, data }: BulkUploadListProps) {
  const [columns, setColumns] = useState<columnFields[]>([]);

  useEffect(() => {
    setColumns(BulkUploadColumns);
  }, []);

  return (
    <>
      {<TabTitle title={title} />}
      <TableGeneric columns={columns} data={data} isFabric={false} />
    </>
  );
}
