import i18n from 'i18next';
import { useEffect, useState } from 'react';

import { InputRedOnError } from '../../elements/InputRedOnError';
import { createFabricLib } from '../../utils/backendRequests/library/createFabricLib';
import { refreshFabricLibraryList } from '../../utils/redux/list';
import { Setter } from '../../utils/types/Types';
import { stringValid } from '../../utils/validations/stringValidator';
import { Modal } from '../Modal';
import { LibraryType } from './libraryModal/LibraryModalTypes';

interface CreateFabricLibraryModalProps {
  modal: {
    visible: boolean;
  };
  setModal: Setter<{ visible: boolean }>;
  moveFabricsToNewLibrary?: (library: LibraryType) => Promise<void>;
}

export function CreateFabricLibraryModal({
  modal,
  setModal,
  moveFabricsToNewLibrary,
}: CreateFabricLibraryModalProps) {
  const [errorName, setErrorName] = useState(false);
  const [errorDescription, setErrorDescription] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  // Reset state when the modal visibility changes
  useEffect(() => {
    if (!modal.visible) {
      setName('');
      setDescription('');
      setErrorName(false);
      setErrorDescription(false);
    }
  }, [modal.visible]);

  // Close modal
  const handleCancel = () => {
    setModal({
      visible: false,
    });
  };

  const handleOk = async () => {
    setErrorName(false);
    setErrorDescription(false);

    if (
      stringValid(name, 50, i18n.t('common:name'), false) &&
      stringValid(description, 100, i18n.t('column_titles:description'), true)
    ) {
      const descriptionValue = description === '' ? null : description;

      const createdLibrary = await createFabricLib(descriptionValue, name);
      if (createdLibrary !== null && moveFabricsToNewLibrary) {
        moveFabricsToNewLibrary(createdLibrary);
        refreshFabricLibraryList();
      }

      setModal({
        visible: false,
      });
    } else {
      if (!stringValid(name, 50, i18n.t('common:name'), false)) {
        setErrorName(true);
      }
      if (
        !stringValid(
          description,
          100,
          i18n.t('column_titles:description'),
          true
        )
      ) {
        setErrorDescription(true);
      }
    }
  };

  return (
    <Modal
      title={i18n.t('headers:new_library')}
      open={modal.visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {`${i18n.t('common:name')}:`}
      <InputRedOnError
        $err={errorName}
        value={name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setName(e.target.value)
        }
      />
      {`${i18n.t('column_titles:description')}:`}
      <InputRedOnError
        $err={errorDescription}
        value={description}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setDescription(e.target.value)
        }
        style={{ marginTop: '10px' }}
      />
    </Modal>
  );
}
