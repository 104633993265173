import { Spin } from 'antd';
import { ReactNode } from 'react';
import { StyledLoadingOutlined } from '../elements/icons/StyledIcons';

interface SpinnerProps {
  children: ReactNode;
  spinning?: boolean;
}

export const Spinner = ({ children, spinning = false }: SpinnerProps) => {
  const antIcon = <StyledLoadingOutlined spin />;

  return (
    <Spin
      indicator={antIcon}
      spinning={spinning}
      style={{ maxHeight: 'inherit' }}
    >
      {children}
    </Spin>
  );
};
