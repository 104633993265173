import { Space } from 'antd';
import i18n from '../../../plugins/i18n';
import { UserProps } from '../../../utils/backendRequests/user/getCurrentUser';
import { columnFields } from '../TableTypes';
import { EditUser } from './EditUser';

export const UserManagementColumns = (
  account: string | null,
  onSuccess: () => void
) => {
  const commonColumns: columnFields[] = [
    {
      dataIndex: 'name',
      title: i18n.t('common:name'),
      columnType: 'string',
    },
    {
      dataIndex: 'email',
      title: i18n.t('common:email'),
      columnType: 'string',
    },
    {
      dataIndex: 'is_active',
      title: i18n.t('common:is_active'),
      columnType: 'boolean',
    },
    {
      dataIndex: 'is_email_notified',
      title: i18n.t('common:email_notifications'),
      columnType: 'boolean',
    },
    {
      dataIndex: 'is_2fa_enabled',
      title: i18n.t('column_titles:is_2fa_enabled'),
      columnType: 'boolean',
    },
    {
      dataIndex: 'account_type',
      name: 'account_type',
      title: i18n.t('common:account_type'),
      columnType: 'string',
      namespaceKey: 'account_types',
      isTranslatable: true,
    },
    {
      dataIndex: 'measurement_unit',
      name: 'measurement_unit',
      title: i18n.t('headers:measurement_unit'),
      columnType: 'string',
      namespaceKey: 'measurement_unit_values',
      isTranslatable: true,
    },
  ];

  const columnWithAction = [
    ...commonColumns,
    {
      key: 'action',
      title: i18n.t('column_titles:action'),
      render: (record: UserProps) => {
        if (
          account === 'manager' &&
          record.account_type !== i18n.t('account_types:manager')
        ) {
          return (
            <Space size="middle">
              <EditUser user={record} onSuccess={onSuccess} />
            </Space>
          );
        }
        return null;
      },
    },
  ];

  return account !== 'viewer' ? columnWithAction : commonColumns;
};
