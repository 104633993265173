import { Image } from 'antd';

import placeholderImage from '../../../assets/images/placeholder-image.png';
import { parseFabricTitles } from '../../../utils/fabrics/parseFabricFields';
import { record } from '../../../utils/types/Types';
import { FabricColumn } from '../TableTypes';
import { EditableLabel } from './EditableLabel';

export type HandleSubmitEditableLabel = (
  label: string | null,
  hashedName: string[]
) => Promise<boolean>;

export const fabricColumns = (
  handleEditFabric: (rowNumber: number) => void,
  handleSubmitEditableLabel: HandleSubmitEditableLabel
): FabricColumn[] => [
  {
    dataIndex: 'name',
    title: parseFabricTitles('name'),
    columnType: 'string',
  },
  {
    dataIndex: 'image_url_front',
    title: parseFabricTitles('image_url_front'),
    render: (_: unknown, record: record) => {
      const text = record.image_url_front;
      return (
        <Image
          width={128}
          src={text ? text : placeholderImage}
          alt="front image"
        />
      );
    },
  },
  {
    dataIndex: 'ml_training_label',
    title: parseFabricTitles('ml_training_label'),
    columnType: 'string',
    render: (_: unknown, record: record) => {
      return (
        <EditableLabel
          label={record.ml_training_label as string}
          handleSubmitEditableLabel={handleSubmitEditableLabel}
          hashedName={record.hashed_name}
        />
      );
    },
  },
];
