import styled from 'styled-components';

import { Col, Row, Space } from 'antd';

// Styled for file upload/edit actions
export const FileActionCol = styled(Col)<{ isMobileFlag: boolean }>`
  display: flex;
  margin-top: -5px;
  justify-content: center;
  align-items: center;
  margin-left: ${(isMobileFlag) => (isMobileFlag ? '-200px' : '0')};
`;

export const FileNameCol = styled(Col)<{ isMobileFlag: boolean }>`
  justify-content: center;
  align-items: center;
  margin-left: ${(isMobileFlag) => (isMobileFlag ? '-10px' : '0')};
`;

export const FileText = styled.div`
  font-size: 14px;
  margin-top: 2px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// Bulk upload rows
export const CreateBulkUploadRow = styled(Row)`
  margin-top: 20px;
`;

// User upload columns
export const CreateUserUploadCol = styled(Col)`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ImageUploadWrapper = styled.div`
  width: auto; /* Set desired width or auto */
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    /* Target the direct child of the wrapper, which should be the ImageUpload component */
    width: 50%; /* Adjust this percentage as needed */
  }
`;

// Create/Edit collection
export const CollectionModalContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 2px;
`;

// Update Admin Brands
export const UpdateBrandImage = styled(Space)`
  width: 200px;
  margin: auto;
  display: block;
`;
