import { message } from 'antd';
import { isAxiosError } from 'axios';
import { api } from '../api/api';
import { EditFabricsProps } from './AdminTypes';

/**
 * Updates ML Training label for selected fabrics
 */
export async function editFabrics({
  hashedNames,
  label,
}: EditFabricsProps): Promise<boolean> {
  try {
    const parsedData = {
      fabrics_hashed_names: hashedNames,
      ml_training_label: label,
    };
    await api({
      method: 'PUT',
      url: 'v1/superuser/training_label/fabric',
      body: parsedData,
    });
    message.success('Fabric details updated.');
    return true;
  } catch (error) {
    if (isAxiosError(error)) {
      message.error(error.response?.data.msg);
    }
    return false;
  }
}
