import { Col, Row, Space } from 'antd';
import { format } from 'date-fns';
import i18n from 'i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Color } from '../../../elements/base/Color';
import { ButtonBasic } from '../../../elements/buttons/StyledButtons';
import { HelpIcon } from '../../../elements/icons/StyledIcons';
import { TextB2 } from '../../../elements/typography/TextB2';
import { routesNamedDict } from '../../../routes';
import { deleteToBinFabric } from '../../../utils/backendRequests/fabric/deleteToBinFabric';
import { priceParser } from '../../../utils/fabrics/parseFabric';
import {
  parseFabricTitles,
  returnOrderedFields,
} from '../../../utils/fabrics/parseFabricFields';
import {
  compositionsProp,
  parseTranslatedCompositions,
} from '../../../utils/parsers/parseTranslatedCompositions';
import { RootAuth } from '../../../utils/types/Types';
import { Modal } from '../../Modal';
import { CreateHangerRequestModal } from '../../modal/CreateHangerRequestModal';
import { AddToCollectionModal } from '../../modal/collectionModal/AddToCollectionModal';
import { AddToProjectModal } from '../../modal/projectModal/AddToProjectModal';
import { BasicInfoComponentProps, FabricBasicProps } from '../FabricTypes';

export function FabricBasicInfo({ fabric }: FabricBasicProps) {
  const { measurementUnit, role } = useSelector(
    (state: RootAuth) => state.auth
  );
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  const [projectModal, setProjectModal] = useState({ visible: false });
  const [collectionModal, setCollectionModal] = useState({ visible: false });
  const [isHangerRequestModalVisible, setIsHangerRequestModalVisible] =
    useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();
  const basicFabricFields = returnOrderedFields([
    'fabric_type',
    'price_per_m',
    'price_per_sqm',
    'price_per_kg',
    'price_per_piece',
    'weight_grams_per_sqm',
    'width_cm',
    'compositions',
    'construction',
    'created_at',
    'updated_at',
  ]);

  // Function to handle opening and closing of the hanger request modal
  const toggleHangerRequestModal = () => {
    setIsHangerRequestModalVisible(!isHangerRequestModalVisible);
  };

  const BasicInfoComponent = ({
    fieldName,
    title,
    value,
    fieldType,
  }: BasicInfoComponentProps) => {
    const hasValue = value !== null && value !== '' && value !== undefined;

    if (fieldType === 'date' && hasValue) {
      const date = new Date(value as string);
      value = format(date, "yyyy-MM-dd HH:mm:ss 'UTC'");
    } else if (typeof value === 'number' && fieldName.startsWith('price')) {
      value = priceParser(fabric.currency as string, value);
    } else if (fieldName === 'compositions') {
      value = parseTranslatedCompositions(
        value as unknown as compositionsProp[],
        true
      ) as string;
    }

    const widthMetric = i18n.t(
      'long_messages:message_fabric_width_tolerance_metric'
    );

    const widthImperial = i18n.t(
      'long_messages:message_fabric_width_tolerance_imperial'
    );

    const widthMessage =
      measurementUnit === 'metric' ? widthMetric : widthImperial;
    const weightMessage = i18n.t(
      'long_messages:message_fabric_weight_tolerance'
    );

    const tooltipText =
      (fieldName === 'width_cm' && widthMessage) ||
      (fieldName === 'weight_grams_per_sqm' && weightMessage);

    return (
      <div style={{ marginBottom: '25px' }}>
        {tooltipText !== false ? (
          <TextB2 style={{ wordWrap: 'break-word' }}>
            {title}
            <HelpIcon title={tooltipText} margin="0 3px 0 3px" />:
            {hasValue ? value : '-'}
          </TextB2>
        ) : (
          <TextB2 style={{ wordWrap: 'break-word' }}>
            {title}: {hasValue ? value : '-'}
          </TextB2>
        )}
      </div>
    );
  };

  const { libHashedName } = useParams();

  const handleConfirm = async () => {
    const data = { hashed_names: [fabric.hashed_name] };
    deleteToBinFabric(data, navigate);
    setModalVisible(false); // Close the modal after deletion
  };

  const editFabricNavigate = () => {
    navigate(
      routesNamedDict.FABRICS_FABRIC_EDIT.replace(
        ':libHashedName',
        libHashedName as string
      ).replace(':fabHashedName', fabric.hashed_name)
    );
  };

  const basicFieldsProp = basicFabricFields
    .filter(
      (field) =>
        fabric[field.name as keyof typeof fabric] !== null &&
        fabric[field.name as keyof typeof fabric] !== '' &&
        fabric[field.name as keyof typeof fabric] !== undefined
    ) // Filter out null, undefined, or empty string values
    .filter((field) => {
      const value = fabric[field.name as keyof typeof fabric];
      return Array.isArray(value) ? value.length !== 0 : true;
    }) // Filter out empty arrays
    .map((field, index) => (
      <BasicInfoComponent
        key={index}
        fieldName={field.name}
        title={parseFabricTitles(field.name, measurementUnit)}
        value={fabric[field.name as keyof typeof fabric] as string}
        fieldType={field.fieldType}
      />
    ));

  const fieldsCount = basicFieldsProp.length;
  const fieldsPerColumn = Math.ceil(fieldsCount / 2);
  const firstColumnFields = basicFieldsProp.slice(0, fieldsPerColumn);
  const secondColumnFields = basicFieldsProp.slice(fieldsPerColumn);

  return (
    <Row>
      <AddToProjectModal
        modal={projectModal}
        setModal={setProjectModal}
        hashedNames={[fabric.hashed_name]}
      />
      <AddToCollectionModal
        modal={collectionModal}
        setModal={setCollectionModal}
        fabricHashedNames={[fabric.hashed_name]}
      />
      <CreateHangerRequestModal
        visible={isHangerRequestModalVisible}
        onCancel={toggleHangerRequestModal}
        hashedName={fabric.hashed_name}
      />
      <Col>
        {fieldsCount > 10 ? (
          <Row gutter={[16, 16]}>
            <Col span={12}>{firstColumnFields}</Col>
            <Col span={12}>{secondColumnFields}</Col>
          </Row>
        ) : (
          basicFieldsProp
        )}
        <Space
          style={{
            marginTop: '10px',
            justifyContent: fieldsCount > 10 ? 'center' : 'start',
            width: '100%',
          }}
        >
          {role === 'designer' && (
            <ButtonBasic onClick={() => setProjectModal({ visible: true })}>
              {i18n.t('headers:add_to_project')}
            </ButtonBasic>
          )}
          {role === 'supplier' && account !== 'viewer' && (
            <ButtonBasic onClick={() => setCollectionModal({ visible: true })}>
              {i18n.t('headers:add_to_collection')}
            </ButtonBasic>
          )}
          {role === 'supplier' && account !== 'viewer' && (
            <ButtonBasic onClick={editFabricNavigate}>
              {i18n.t('buttons:edit')}
            </ButtonBasic>
          )}
          {role === 'designer' && account !== 'viewer' && (
            <ButtonBasic onClick={toggleHangerRequestModal}>
              {i18n.t('buttons:request_hanger')}
            </ButtonBasic>
          )}
          {role === 'supplier' && account !== 'viewer' && (
            <ButtonBasic danger onClick={() => setModalVisible(true)}>
              {i18n.t('buttons:delete')}
            </ButtonBasic>
          )}
        </Space>
        <Modal
          title={i18n.t('headers:confirm_deletion')}
          open={modalVisible}
          onOk={handleConfirm}
          onCancel={() => setModalVisible(false)}
          okText={i18n.t('buttons:yes')}
          cancelText={i18n.t('buttons:no')}
          okButtonProps={{
            style: { backgroundColor: Color.Red },
          }}
        >
          <p>{i18n.t('long_messages:message_confirm_fabric_deletion')}</p>
        </Modal>
      </Col>
    </Row>
  );
}
