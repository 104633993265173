import { ReactNode } from 'react';
import { CardHeaderRow } from '../../elements/StyledUI';
import { TitleH6 } from '../../elements/typography/TitleH6';

interface CardHeaderProps {
  children: ReactNode;
  hidden?: boolean;
}

// Place inside the card element div
export const CardHeader = ({ children, hidden = false }: CardHeaderProps) => {
  return (
    <CardHeaderRow hidden={hidden}>
      <TitleH6>{children}</TitleH6>
    </CardHeaderRow>
  );
};
