import { message } from 'antd';
import { isAxiosError } from 'axios';
import { api } from '../api/api';
import { AdminLabelsList } from './AdminTypes';

/**
 * Get all fabric ML Training labels as admin.
 */
export async function getFabricLabels(): Promise<AdminLabelsList> {
  try {
    const response = await api({
      method: 'GET',
      url: 'v1/superuser/training_label',
    });
    return response;
  } catch (error) {
    if (isAxiosError(error)) {
      message.error(error.response?.data.msg);
    }
    return Promise.reject(error);
  }
}
