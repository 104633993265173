import { api } from '../api/api';
import {
  containedFabricProjectsProps,
  ProjectsContainingFabrics,
} from './ProjectTypes';

/**
 * Retrieves project names containing the specified fabrics
 * by their hashed names.
 */
export async function getContainingFabricsProjects({
  fabricsHashedNames,
  fabricsSourceProjectName,
}: ProjectsContainingFabrics): Promise<containedFabricProjectsProps> {
  try {
    let queryString = '';
    // Construct the query string manually
    if (fabricsHashedNames !== undefined) {
      queryString = fabricsHashedNames
        .map((name) => `fabrics_hashed_names=${encodeURIComponent(name)}`)
        .join('&');
    } else if (fabricsSourceProjectName !== undefined) {
      queryString = `fabrics_source_project_name=${encodeURIComponent(
        fabricsSourceProjectName
      )}`;
    }
    const url = `v1/project/projects_containing_fabrics?${queryString}`;
    return await api({
      method: 'GET',
      url: url,
    });
  } catch (error) {
    const emptyReturn = {
      project_names: [],
    };
    return emptyReturn;
  }
}
