import { Image } from 'antd';
import { format } from 'date-fns';
import i18n from 'i18next';

import { BinTab } from './BinTab';
import { UploadBinTabProps } from './BinTypes';

export function UploadBinTab({
  userUploads,
  getBinContents,
}: UploadBinTabProps) {
  const columns = [
    {
      dataIndex: 'image_url',
      key: 'image_url',
      title: i18n.t('column_titles:image'),
      render: (image_url: string) => {
        return <Image width={160} src={image_url} />;
      },
    },
    {
      dataIndex: 'description',
      key: 'description',
      title: i18n.t('column_titles:description'),
    },
    {
      dataIndex: 'deleted_timestamp',
      key: 'deleted_timestamp',
      title: i18n.t('column_titles:deleted_at'),
      columnType: 'date' as const,
      render: (deleted_timestamp: string) => {
        const date = new Date(deleted_timestamp);
        return <span>{format(date, "yyyy-MM-dd HH:mm:ss 'UTC'")}</span>;
      },
    },
  ];

  return (
    <BinTab
      binData={userUploads}
      binColumns={columns}
      getBinContents={getBinContents}
      binType="uploads"
    />
  );
}
