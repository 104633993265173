import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TableGeneric } from '../../../components/table/TableGeneric';
import { columnFields } from '../../../components/table/TableTypes';
import {
  ApprovedUsers,
  getApprovedUsers,
} from '../../../utils/backendRequests/user/getApprovedUsers';
import { RootAuth, RootState } from '../../../utils/types/Types';
import { ProjectTableListProps } from '../TableTypes';
import { columnsDesktop } from './ColumnsDesktop';
import { columnsMobile } from './ColumnsMobile';

export function ProjectTableList({ data }: ProjectTableListProps) {
  const [approvedUsers, setApprovedUsers] = useState<ApprovedUsers>([]);
  const [columns, setColumns] = useState<columnFields[]>([]);
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  const account = useSelector((state: RootAuth) => state.auth.accountType);

  const _getApprovedUsers = async () => {
    const users = await getApprovedUsers();
    setApprovedUsers(users);
  };

  useEffect(() => {
    // Gets list of approved users here
    // API call is done once here as all projects will use the same list of approved users
    // Prevents multiple API calls for the same list of approved users during project renders
    _getApprovedUsers();
  }, []);

  useEffect(() => {
    if (!isMobileFlag) {
      setColumns(columnsDesktop(approvedUsers, account));
    } else {
      setColumns(columnsMobile(approvedUsers, account));
    }
  }, [approvedUsers, isMobileFlag]);

  return (
    <>
      <TableGeneric columns={columns} data={data} isFabric={false} />
    </>
  );
}
