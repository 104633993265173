import i18n from 'i18next';
import { useSelector } from 'react-redux';

import { useState } from 'react';
import {
  ButtonBasic,
  DropdownButton,
} from '../../elements/buttons/StyledButtons';
import {
  exportExcelFabrics,
  exportPDFFabrics,
} from '../../utils/fabrics/exportFabric';
import { RootAuth, RootState } from '../../utils/types/Types';
import { ExportFabricsModal } from '../modal/ExportFabricsModal';
import { ExportTableProps } from './FabricTypes';

export function ExportSelectedRowsButton({
  disabled,
  data,
  selectedRowKeys,
  pageType,
}: ExportTableProps) {
  const { measurementUnit } = useSelector((state: RootAuth) => state.auth);
  const [exportModal, setExportModal] = useState(false);
  const [includeImages, setIncludeImages] = useState<boolean>(false);
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);

  const handleCancelModal = () => {
    setExportModal(false);
  };

  const handleExcelExportFabrics = async () => {
    // Filter data based on selectedRowKeys
    const filteredData = selectedRowKeys
      ? selectedRowKeys.map((key) => data[key - 1]).filter(Boolean)
      : data;
    exportExcelFabrics(
      filteredData,
      measurementUnit as string,
      pageType,
      i18n.t('page_titles:fabrics_page'),
      includeImages
    );
    setExportModal(false);
  };

  const handlePDFExportFabrics = async () => {
    const filteredData = selectedRowKeys
      ? selectedRowKeys.map((key) => data[key - 1]).filter(Boolean)
      : data;
    exportPDFFabrics(
      filteredData,
      measurementUnit as string,
      pageType,
      i18n.t('page_titles:fabrics_page'),
      includeImages
    );
    setExportModal(false);
  };

  return (
    <div>
      <ExportFabricsModal
        visible={exportModal}
        includeImages={includeImages}
        setIncludeImages={setIncludeImages}
        onCancel={handleCancelModal}
        onExportExcel={handleExcelExportFabrics}
        onExportPDF={handlePDFExportFabrics}
      />
      {isMobileFlag ? (
        <DropdownButton
          disabled={disabled}
          onClick={() => setExportModal(true)}
        >
          {i18n.t('buttons:export')}
        </DropdownButton>
      ) : (
        <ButtonBasic
          block
          disabled={disabled}
          onClick={() => setExportModal(true)}
        >
          {i18n.t('buttons:export')}
        </ButtonBasic>
      )}
    </div>
  );
}
