import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  message,
} from 'antd';
import i18n from 'i18next';
import { useEffect, useMemo } from 'react';

import { getEditOptions } from '../../utils/backendRequests/list/lists';
import { parseFormLabel } from '../../utils/parsers/parseFormLabel';
import { sortDropdownOptionsByTranslation } from '../../utils/parsers/sortByTranslation';
import { FormItemsProps, formFieldsProp } from './FormTypes';

const { Option } = Select;
const { TextArea } = Input;

/**
 * FormItems Component
 *
 * Renders form items based on the provided configuration and fetches select options from the backend.
 */
export function FormItems({
  formFields,
  propsWithDropDownObj,
  setPropsWithDropDownObj,
  supplierBrandId,
  formRules = {},
  parseFieldTitle = parseFormLabel,
}: FormItemsProps) {
  // Sorts the dropdown options according to translation output
  const sortedPropsWithDropDownObj = useMemo(() => {
    return sortDropdownOptionsByTranslation(propsWithDropDownObj);
  }, [propsWithDropDownObj]);

  useEffect(() => {
    async function _fetchDropDownOptions() {
      const fetchOptionPromise = formFields
        .filter(
          (field) =>
            typeof field.fieldType === 'string' &&
            field.fieldType.includes('Dropdown')
        )
        .map((field) => getEditOptions(field, setPropsWithDropDownObj));

      await Promise.all(fetchOptionPromise).catch((err) => {
        message.error(err.message);
      });
    }

    _fetchDropDownOptions();
  }, [formFields, setPropsWithDropDownObj, supplierBrandId]);

  const renderFormComponent = (
    field: formFieldsProp,
    additionalProps: Record<string, unknown>
  ) => {
    if (field.fieldType === 'stringArea') {
      return (
        <TextArea {...additionalProps} autoSize={{ minRows: 2, maxRows: 6 }} />
      );
    } else if (field.fieldType === 'singleDropdown') {
      return (
        <Select {...additionalProps}>
          {(sortedPropsWithDropDownObj[field.name] || []).map(
            ({ value, label }, i: number) => (
              <Option value={value} key={i}>
                {
                  // If the field is translatable, translate the label, but always translate 'none'
                  field.isTranslatable || label === 'none'
                    ? i18n.t(label)
                    : label
                }
              </Option>
            )
          )}
        </Select>
      );
    } else if (field.fieldType === 'multiDropdown') {
      return (
        <Select mode="multiple" {...additionalProps}>
          {(sortedPropsWithDropDownObj[field.name] || []).map(
            ({ value, label }, i: number) => (
              <Option value={value} key={i}>
                {
                  // If the field is translatable, translate the label
                  field.isTranslatable ? i18n.t(label) : label
                }
              </Option>
            )
          )}
        </Select>
      );
    } else if (field.fieldType === 'number') {
      return <InputNumber {...additionalProps} style={{ width: '100%' }} />;
    } else if (field.fieldType === 'numberInteger') {
      return (
        <InputNumber
          {...additionalProps}
          style={{ width: '100%' }}
          precision={0}
        />
      );
    } else if (field.fieldType === 'boolean') {
      return <Switch {...additionalProps} />;
    } else if (field.fieldType === 'date') {
      return <DatePicker {...additionalProps} style={{ width: '100%' }} />;
    }

    return <Input {...additionalProps} />;
  };

  return formFields.map((field) => {
    const additionalProps: Record<string, unknown> = {
      placeholder: `${parseFieldTitle(field.name)}`,
    };

    return (
      <Form.Item
        label={parseFieldTitle(field.name)}
        name={field.name}
        key={field.name}
        rules={formRules[field.name]}
        // Switch component uses checked instead of the default: value
        valuePropName={field.fieldType === 'boolean' ? 'checked' : 'value'}
      >
        {renderFormComponent(field, additionalProps)}
      </Form.Item>
    );
  });
}
