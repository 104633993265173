import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { TableGeneric } from '../../../components/table/TableGeneric';
import { columnFields } from '../../../components/table/TableTypes';
import { UserProps } from '../../../utils/backendRequests/user/getCurrentUser';
import { getTranslatedValuesAndUpdateData } from '../../../utils/fabrics/parseFabricValues';
import { RootAuth } from '../../../utils/types/Types';
import { UserManagementColumns } from './UserManagementColumns';

interface UserManagementListProps {
  data: UserProps[];
  onSuccess: () => void;
}

export function UserManagementList({
  data,
  onSuccess,
}: UserManagementListProps) {
  const [columns, setColumns] = useState<columnFields[]>([]);
  const [translatedData, setTranslatedData] = useState<UserProps[]>([]);
  const account = useSelector((state: RootAuth) => state.auth.accountType);

  useEffect(() => {
    setColumns(UserManagementColumns(account, onSuccess));
  }, []);

  // Set translated data
  useEffect(() => {
    const translationData = getTranslatedValuesAndUpdateData(
      data,
      columns
    ) as UserProps[];
    setTranslatedData(translationData);
  }, [data, columns]);

  return (
    <>
      <TableGeneric columns={columns} data={translatedData} isFabric={false} />
    </>
  );
}
