import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const minDate = dayjs('1950-01-01', 'YYYY-MM-DD'); // Defined as the minimum possible date in the system
const maxDate = dayjs('2040-01-01', 'YYYY-MM-DD'); // Defined as the maximum possible date in the system

export { dayjs, maxDate, minDate };
