import { DownOutlined } from '@ant-design/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { store } from '../../../plugins/redux';
import { routesNamedDict } from '../../../routes';

import {
  LogoutDropDownIcon,
  ProfileDropDownIcon,
} from '../../../elements/icons/StyledIcons';
import {
  ProfileDropDownContainer,
  ProfileDropDownName,
} from '../../../elements/StyledUI';
import { logout } from '../../../utils/backendRequests/auth/logout';
import { RootAuth } from '../../../utils/types/Types';
import { DropdownKey, dropdownItems as items } from './dropdownItemsProfile';

interface ProfileDropDownProps {
  userName?: string;
  isMobile?: boolean;
}

export function ProfileDropDown({ userName, isMobile }: ProfileDropDownProps) {
  const navigate = useNavigate();
  const role = useSelector((state: RootAuth) => state.auth?.role);

  const onClick = ({ key }: { key: React.Key }) => {
    const dropdownKey = key as DropdownKey;

    if (dropdownKey === 'settings') {
      role === 'admin'
        ? navigate(routesNamedDict.ADMIN_SETTINGS)
        : navigate(routesNamedDict.SETTINGS);
    }
    if (dropdownKey === 'logout') {
      logout(store, navigate, routesNamedDict);
    }
  };

  return (
    <>
      {isMobile ? (
        <LogoutDropDownIcon menu={{ items, onClick }}>
          <ProfileDropDownContainer>
            <DownOutlined />
          </ProfileDropDownContainer>
        </LogoutDropDownIcon>
      ) : (
        <LogoutDropDownIcon menu={{ items, onClick }}>
          <ProfileDropDownContainer>
            <ProfileDropDownIcon />
            <ProfileDropDownName>{userName}</ProfileDropDownName>
            <DownOutlined />
          </ProfileDropDownContainer>
        </LogoutDropDownIcon>
      )}
    </>
  );
}
