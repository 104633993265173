import { Menu } from 'antd';
import styled from 'styled-components';
import { Color } from '../base/Color';

interface MenuWrapperProps {
  $black?: boolean;
}

export const MenuWrapper = styled(Menu)<MenuWrapperProps>`
  color: ${(props) => (props.$black === true ? Color.Black : Color.G4)};
  font-size: 14px;
  height: 100%;
  line-height: 17px;
  text-transform: uppercase;

  .ant-menu-item {
    display: flex;
    align-items: center;
  }
`;
