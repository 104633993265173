import { Input } from 'antd';
import i18n from 'i18next';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setName } from '../../plugins/redux/auth';
import { updateUserProfile } from '../../utils/backendRequests/auth/updateUserProfile';
import { RootAuth, Setter } from '../../utils/types/Types';
import { stringValid } from '../../utils/validations/stringValidator';
import { Modal } from '../Modal';

interface ChangeNameModalProps {
  isModalOpen: boolean;
  setIsModalOpen: Setter<boolean>;
}

export function ChangeUsernameModal({
  isModalOpen,
  setIsModalOpen,
}: ChangeNameModalProps) {
  const username = useSelector((state: RootAuth) => state.auth?.name);
  const [newUsername, setNewUsername] = useState(username || '');
  const dispatch = useDispatch();

  // Submits a request to change the username, and resets all fields
  // If successful, closes modal
  const handleSubmit = async () => {
    if (!stringValid(newUsername, 50, i18n.t('headers:new_username'), false))
      return null;
    const updateUserResponse = await updateUserProfile({ name: newUsername });

    if (updateUserResponse?.authorized) {
      dispatch(setName(newUsername));
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={i18n.t('headers:change_username')}
      open={isModalOpen}
      onOk={handleSubmit}
      onCancel={handleCancel}
    >
      <span>{i18n.t('headers:new_username')}</span>
      <Input
        value={newUsername}
        onChange={(e) => setNewUsername(e.target.value)}
      />
    </Modal>
  );
}
