import { Space } from 'antd';

import { SupplierRecord } from '../TableTypes';
import { EditSupplierBrand } from './EditSupplierBrand';

export const supplierBrandColumns = (getBrands: () => void) => [
  {
    dataIndex: 'key',
    key: 'key',
  },
  {
    dataIndex: 'name',
    key: 'name',
    title: 'Name',
    columnType: 'string',
  },
  {
    dataIndex: 'address',
    key: 'address',
    title: 'Address',
  },
  {
    dataIndex: 'city',
    key: 'city',
    title: 'City',
    columnType: 'string',
  },
  {
    dataIndex: 'country',
    key: 'country',
    title: 'Country',
    columnType: 'string',
  },
  {
    dataIndex: 'website',
    key: 'website',
    title: 'Website',
    render: (name: string) => <a href={name}>{name}</a>,
  },
  {
    key: 'action',
    title: 'Action',
    render: (record: SupplierRecord) => (
      <>
        <Space size="middle">
          <EditSupplierBrand
            brand={record}
            supplierBrandId={record.id}
            getBrands={getBrands}
          />
        </Space>
      </>
    ),
  },
];
