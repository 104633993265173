import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  libraryList: [],
};

const fabricLibrarySlice = createSlice({
  name: 'fabricLibrary',
  initialState,
  reducers: {
    setLibraryList: (state, action) => {
      state.libraryList = action.payload;
    },
  },
});

export const fabricLibrarySliceReducer = fabricLibrarySlice.reducer;
export const { setLibraryList } = fabricLibrarySlice.actions;
