import { message } from 'antd';
import { SupplierBrandValues } from '../../../components/table/TableTypes';
import { api } from '../api/api';

/**
 * Updates an existing supplier brand with new brand
 * values submitted form a form using the ID to update
 */
export async function editSupplierBrand(
  brand: SupplierBrandValues,
  brandId: number
): Promise<boolean> {
  try {
    return await api({
      method: 'PUT',
      url: `v1/superuser/brand/supplier/${brandId}`,
      body: brand,
    }).then(() => {
      message.success('Supplier Brand successfully updated.');
      return true;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return false;
  }
}
