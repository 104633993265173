import {
  DateFilter,
  FilterValues,
  FilterableField,
  GenericFilter,
  InitialChoicesOrRange,
  NumberFilter,
  TransformedFilterProps,
} from '../FilterTypes';

/**
 * Used to create the initial values for the filter. The output is an array of objects.
 */
export const createInitialFilterValues = (
  filterableFields: FilterableField[]
) => {
  const initialFilterValues: FilterValues = {};
  for (const field of filterableFields) {
    if (field.filterFlag) {
      if (field.name === 'certifications') {
        initialFilterValues[field.name] = {
          type: 'certification',
          values: [],
          includeNull: true,
          required: field.required,
        };
      } else if (field.name === 'colours') {
        initialFilterValues[field.name] = {
          type: 'colour',
          values: [],
          includeNull: true,
          required: field.required,
        };
      } else if (
        field.fieldType === 'string' ||
        field.fieldType === 'singleDropdown'
      ) {
        initialFilterValues[field.name] = {
          type: 'checkbox',
          values: [],
          includeNull: true,
          required: field.required,
        };
      } else if (field.fieldType === 'multiDropdown') {
        initialFilterValues[field.name] = {
          type: 'checkboxArray',
          values: [],
          includeNull: true,
          required: field.required,
        };
      } else if (
        field.fieldType === 'number' ||
        field.fieldType === 'numberInteger'
      ) {
        initialFilterValues[field.name] = {
          type: 'number',
          values: [],
          includeNull: true,
          required: field.required,
        };
      } else if (field.fieldType === 'date') {
        initialFilterValues[field.name] = {
          type: 'date',
          values: [],
          includeNull: true,
          required: field.required,
        };
      }
    }
  }
  return initialFilterValues;
};

export const createInitialChoicesOrRange = (
  initialFilterValues: FilterValues,
  filterableFields: TransformedFilterProps
) => {
  const initialChoicesOrRange: InitialChoicesOrRange = {};
  for (const [key] of Object.entries(initialFilterValues)) {
    // Iterate over filterableFields to find the matching key
    if (filterableFields) {
      for (const [filterableKey, filterableValue] of Object.entries(
        filterableFields
      )) {
        if (key === filterableKey) {
          if (filterableValue.filter_type === 'number') {
            // Assert that filterableValue is of type NumberFilter
            const filterObject = filterableValue as NumberFilter;
            const numberMin = filterObject.min;
            const numberMax = filterObject.max;
            const finalValue = [numberMin, numberMax];
            initialChoicesOrRange[key] = finalValue;
          } else if (filterableValue.filter_type === 'date') {
            // Assert that filterableValue is of type DateFilter
            const filterObject = filterableValue as DateFilter;
            const dateMin = filterObject.min;
            const dateMax = filterObject.max;
            const finalValue = [dateMin, dateMax];
            initialChoicesOrRange[key] = finalValue;
          } else {
            const filterObject = filterableValue as GenericFilter;
            // Handle other types if necessary
            initialChoicesOrRange[key] = filterObject.values as string[];
          }
          break; // Stop searching once the key is found
        }
      }
    }
  }
  return initialChoicesOrRange;
};
