import { message } from 'antd';
import { apiAuth } from '../api/apiAuth';
import { ProfileUpdateProps, ProfileUpdateReturn } from './AuthTypes';

/**
 * Update the user profile. Possible user fields to update include:
 * - measurement_unit
 * - name
 *
 * Returns the updated jwt token, a message, and authorized flag if successful.
 */
export const updateUserProfile = async (
  body: ProfileUpdateProps
): Promise<ProfileUpdateReturn> => {
  return await apiAuth({
    method: 'PUT',
    url: 'v1/profile/update_user',
    body: body,
  })
    .then((response) => {
      message.success(response.msg);
      return response;
    })
    .catch((error) => {
      message.error(error.message);
    });
};
