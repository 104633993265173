import i18n from '../../plugins/i18n';

export type compositionsProp = {
  name: string;
  percentage: number;
};

/**
 * Generate a formated display string for compositions that is translated.
 */
export const parseTranslatedCompositions = (
  compositions: compositionsProp[],
  isSameLine: boolean
) => {
  if (compositions && compositions.length > 0) {
    const translatedCompositions = compositions.map((composition) => {
      const translatedMaterial = i18n.t(composition.name, {
        ns: 'composition_values',
      });
      return `${composition.percentage}% ${translatedMaterial}`;
    });
    if (isSameLine) {
      return translatedCompositions.join(', ');
    } else {
      return translatedCompositions.join('\n');
    }
  } else {
    return null;
  }
};
