import { FabricOrderedFields } from '../../../components/fabric/FabricTypes';
import {
  DropDownObjProp,
  DropdownOption,
  SetPropsWithDropDownObj,
} from '../../../components/form/FormTypes';
import { api } from '../api/api';
import { getCertifications } from '../certifications/getCertifications';
import { getColours } from '../colours/getColours';

/**
 * Get edit form dropwdown options
 */

type dropdownItem = {
  value: string | number;
  label: string;
};

type CertificationProp = {
  id: number;
  is_owner: boolean;
  name: string;
};

export async function getEditOptions(
  field: FabricOrderedFields,
  setPropsWithDropDownObj: SetPropsWithDropDownObj
): Promise<void> {
  let prop = '';
  // Maps name of property to list endpoint on backend
  const propertyMap = {
    account_type: 'account_types',
    availability: 'availabilities',
    composition1_id: 'fabric_compositions',
    composition2_id: 'fabric_compositions',
    composition3_id: 'fabric_compositions',
    composition4_id: 'fabric_compositions',
    construction_id: 'fabric_constructions',
    country_id: 'countries',
    country_of_origin_id: 'countries',
    currency_id: 'currencies',
    fabric_type: 'fabric_types',
    measurement_unit: 'measurement_unit_types',
    season: 'seasons',
    wash_care: 'wash_cares',
    stretch: 'stretches',
    lace_structure: 'lace_structures',
    pattern_design: 'pattern_designs',
    aesthetic_finish: 'aesthetic_finishes',
    edge_finish: 'edge_finishes',
    look: 'looks',
    special_yarn: 'special_yarns',
    sustainability: 'sustainabilities',
    hand_feel: 'hand_feels',
    certification_type: 'certification_type',
    certification_status: 'certification_status',
  };

  if (field.name === 'colour_ids') {
    const data = await getColours();
    const dropDownitems: DropdownOption[] = data.map(
      ({ id, name }: { id: number; name: string }) => ({
        value: id,
        label: name,
      })
    );
    addNoneDropDownOption(field, dropDownitems);
    setPropsWithDropDownObj((obj: DropDownObjProp) => ({
      ...obj,
      [field.name]: dropDownitems,
    }));
    return Promise.resolve();
  }

  if (field.name === 'certifications') {
    // supplier when the supplier_brand_id is retreived.
    await getDropdownCertificationOptions(setPropsWithDropDownObj);
    return Promise.resolve();
  }

  try {
    prop = propertyMap[field.name as keyof typeof propertyMap];
  } catch {
    prop = '';
  }

  // default end point for getting the drop down values
  try {
    const url = `v1/list/${prop}`;
    const response = await api({
      method: 'GET',
      url: url,
    });

    const dropDownitems: DropdownOption[] = [];
    // Add empty option for all dropdowns which are not multi-dropdown and not required
    addNoneDropDownOption(field, dropDownitems);

    Object.entries(response).forEach(([id, title]) =>
      dropDownitems.push({ value: Number(id), label: String(title) })
    );
    setPropsWithDropDownObj((obj: DropDownObjProp) => {
      return { ...obj, [field.name]: dropDownitems };
    });
  } catch (error) {
    throw new Error(
      `Error - Could not get dropdown value: ${prop}, please try again.`
    );
  }
}

/**
 * Get dropdown options for certifications.
 * The endpoint is different for admin and supplier users.
 */
const getDropdownCertificationOptions = async (
  setPropsWithDropDownObj: SetPropsWithDropDownObj
) => {
  const certifications = await getCertifications();
  if (certifications) {
    const dropDownitems: DropdownOption[] = [];
    certifications.forEach((certification: CertificationProp) =>
      dropDownitems.push({ value: certification.id, label: certification.name })
    );
    setPropsWithDropDownObj((obj: DropDownObjProp) => {
      return { ...obj, certifications: dropDownitems };
    });
  }
};

// Add conditional None to the dropdown
const addNoneDropDownOption = (
  field: FabricOrderedFields,
  dropDownItems: dropdownItem[]
) => {
  if (field.fieldType !== 'multiDropdown' && field.required === false)
    dropDownItems.push({ value: '', label: 'none' });
};
