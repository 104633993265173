import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TableGeneric } from '../../../components/table/TableGeneric';
import { GenericTableColumn } from '../../../components/table/TableTypes';
import {
  CollectionListItem,
  SharedDesignerBrandsObject,
} from '../../../utils/backendRequests/collection/CollectionTypes';
import { getApprovedByDesignerBrands } from '../../../utils/backendRequests/list/approvedByDesignerBrands';
import { RootAuth } from '../../../utils/types/Types';
import {
  CollectionTableListProps,
  KeyValueDesignerBrandObject,
} from './CollectionListTypes';
import { getColumns } from './Columns';

export function CollectionTableList({
  data,
  getCollectionsList,
}: CollectionTableListProps) {
  const [columns, setColumns] = useState<
    GenericTableColumn<CollectionListItem>[]
  >([]);
  const [approvedDesignerBrands, setApprovedDesignerBrands] = useState<
    KeyValueDesignerBrandObject[]
  >([]);
  const role = useSelector((state: RootAuth) => state.auth.role);
  const account = useSelector((state: RootAuth) => state.auth.accountType);

  useEffect(() => {
    // Gets list of approved designer brands here
    // API call is done once here as all collections will use the same list of approved designer brands
    async function _getApprovedDesignerBrands() {
      const res: SharedDesignerBrandsObject =
        await getApprovedByDesignerBrands();
      if (res) {
        // Parses the object into an array of key-value pairs
        const arrayApprovedDesignerBrands: KeyValueDesignerBrandObject[] =
          Object.entries(res).map(([key, value]) => {
            return { key, value };
          });
        setApprovedDesignerBrands(arrayApprovedDesignerBrands);
      }
    }
    // Only run if user is a supplier
    if (role === 'supplier') _getApprovedDesignerBrands();
  }, []);

  useEffect(() => {
    setColumns(
      getColumns(approvedDesignerBrands, role, getCollectionsList, account)
    );
  }, [approvedDesignerBrands]);

  return (
    <>
      <TableGeneric columns={columns} data={data} isFabric={false} />
    </>
  );
}
