import { Col, Layout, Row } from 'antd';
import { useEffect, useState } from 'react';
import Media from 'react-media';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as DelvifyLogo } from '../../assets/images/logos/delvify.svg';
import { FooterLinks } from '../../components/footer/FooterLinks';
import { delvifyFooterURLs } from '../../components/footer/delvifyFooterURLs';
import { Alert } from '../../elements/Alert';
import { Color } from '../../elements/base/Color';
import { SloganH4 } from '../../elements/typography/SloganH4';
import { LoginWrapper } from '../../styles/wrappers/LoginWrapper';
import { LogoWrapper } from '../../styles/wrappers/LogoWrapper';
import { LayoutProps, RedirectInfo } from '../LayoutTypes';

const { Header, Footer, Content } = Layout;
const LoginBackground = styled(Col)`
  background: linear-gradient(to bottom right, ${Color.G3}, ${Color.Red});
`;

export function AuthLayout({ children }: LayoutProps) {
  const [redirected, setRedirected] = useState<RedirectInfo | null>(null);
  const { state } = useLocation();

  // Look at URL to see if users were redirected to the login page because session expired
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (state?.code) setRedirected(state); // Only set redirected if there is a code
  }, [state]);

  useEffect(() => {
    if (
      localStorage.getItem('token') !== null &&
      searchParams.get('tokenExpired') === '1'
    ) {
      setRedirected({
        code: null,
        msg: 'Please log in again. Logged out due to inactivity.',
      });
    }
  }, [searchParams]);

  //Handles alert's closure
  const handleClose = () => {
    setRedirected(null);
  };

  return (
    <div>
      <Row>
        <Col flex={1}>
          <Layout>
            <Header style={{ background: Color.White }}>
              <LogoWrapper $authlayout={true}>
                <Link to="/">
                  <DelvifyLogo />
                </Link>
              </LogoWrapper>
            </Header>
            {redirected && (
              <Alert
                message={redirected.msg}
                type={redirected.code === 200 ? 'success' : 'error'}
                closable
                onClose={handleClose}
              />
            )}
            <Content>
              <LoginWrapper>{children}</LoginWrapper>
            </Content>
            <Footer>
              <div style={{ textAlign: 'center' }}>
                <FooterLinks links={delvifyFooterURLs} />
              </div>
            </Footer>
          </Layout>
        </Col>
        <Media query="(max-width: 665px)">
          {(matches) =>
            !matches && (
              <LoginBackground flex={4}>
                <SloganH4>
                  Fashion Tech for <br /> a Better Tomorrow,
                  <span style={{ color: Color.Logo }}> Today</span>
                </SloganH4>
              </LoginBackground>
            )
          }
        </Media>
      </Row>
    </div>
  );
}
