import { message } from 'antd';
import { isAxiosError } from 'axios';
import { apiAuth } from '../api/apiAuth';
import { CreateBrandProps } from './AdminTypes';

/**
 * Create a designer or supplier brand in Backend_Auth.
 */
export async function adminCreateBrand({
  type,
  data,
}: CreateBrandProps): Promise<boolean> {
  let success = false;
  try {
    await apiAuth({
      method: 'post',
      url: `v1/superuser/create_${type}_brand`,
      body: data,
      headers: {
        'content-type': 'application/json',
      },
    });
    message.success(`Create ${type} brand success.`);
    success = true;
  } catch (error) {
    if (isAxiosError(error)) {
      message.error(
        error.message || 'An error occurred while creating the brand.'
      );
    }
  }
  return success;
}
