import { useState } from 'react';
import { Link } from 'react-router-dom';

import { removeSharedProject } from '../../utils/backendRequests/project/removeSharedProject';
import { Modal } from '../Modal';
import { RemoveSharedProjectModalWithLinkProps } from './ModalTypes';

export function RemoveSharedProjectModalWithLink({
  userToRemove,
  sharedUsers,
  projectName,
  setSharedUsers,
}: RemoveSharedProjectModalWithLinkProps) {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const showConfirmationModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const hideConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const removeSharedUserFromProject = async () => {
    const sharedUsersData = await removeSharedProject(
      projectName,
      sharedUsers,
      userToRemove.key,
      hideConfirmationModal
    );
    setSharedUsers(sharedUsersData);
  };

  return (
    <>
      {/* TODO: Link should be replaced with a Button Instead */}
      <Link to="#" onClick={showConfirmationModal}>
        Remove
      </Link>
      <Modal
        title={`Confirm removal of ${userToRemove.value}?`}
        open={isConfirmationModalOpen}
        okText="Yes"
        onOk={removeSharedUserFromProject}
        onCancel={hideConfirmationModal}
      ></Modal>
    </>
  );
}
