import { format } from 'date-fns';
import i18n from 'i18next';

import { BinTab } from './BinTab';
import { CollectionBinTabProps } from './BinTypes';

export function CollectionBinTab({
  collections,
  getBinContents,
}: CollectionBinTabProps) {
  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      title: i18n.t('common:name'),
      columnType: 'string' as const,
    },
    {
      dataIndex: 'deleted_timestamp',
      key: 'deleted_timestamp',
      title: i18n.t('column_titles:deleted_at'),
      columnType: 'date' as const,
      render: (deleted_timestamp: string) => {
        const date = new Date(deleted_timestamp);
        return <span>{format(date, "yyyy-MM-dd HH:mm:ss 'UTC'")}</span>;
      },
    },
  ];

  return (
    <BinTab
      binData={collections}
      binColumns={columns}
      getBinContents={getBinContents}
      binType="collections"
    />
  );
}
