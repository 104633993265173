import { UserOutlined } from '@ant-design/icons';
import { Space } from 'antd';

import { FontWeight } from '../../../elements/base/Font';
import { RemoveApprovedBrandModalWithLink } from '../../modal/RemoveApprovedBrandModalWithLink';
import { ApprovedSupplierBrandsProps } from '../TableTypes';

export function ApprovedSupplierBrands({
  approvedSupplierBrands,
  designerBrandId,
  setApprovedSupplierBrands,
}: ApprovedSupplierBrandsProps) {
  return (
    <Space direction="vertical" size="small">
      <p style={{ margin: '0' }}>Approved Brands</p>
      {approvedSupplierBrands.map((brand, index) => (
        <Space direction="vertical" size="small" key={index}>
          <Space style={{ height: '2rem' }}>
            <UserOutlined style={{ fontSize: '22px' }} />
            <p style={{ fontWeight: FontWeight.Regular }}>{brand.value}</p>
            <RemoveApprovedBrandModalWithLink
              approvedSupplierBrands={approvedSupplierBrands}
              designerBrandId={designerBrandId}
              setApprovedSupplierBrands={setApprovedSupplierBrands}
              supplierBrandToRemove={brand}
            />
          </Space>
        </Space>
      ))}
    </Space>
  );
}
