import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Brand } from '../../../utils/backendRequests/admin/AdminTypes';
import { parseBrandsResponse } from '../../../utils/backendRequests/admin/getBrands';
import { UpdateDesignerBrandModal } from '../../modal/createBrandModal/UpdateDesignerBrandModal';

interface EditDesignerBrandProps {
  brand: Brand | parseBrandsResponse;
  designerBrandId: number;
  getBrands: () => void;
}

export function EditDesignerBrand({
  brand,
  designerBrandId,
  getBrands,
}: EditDesignerBrandProps) {
  const [updateModal, setUpdateModal] = useState({ visible: false });
  const handleUpdate = () => {
    setUpdateModal({ visible: true });
  };

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <Link onClick={handleUpdate} to={''}>
          Edit
        </Link>
      </div>
      <UpdateDesignerBrandModal
        modal={updateModal}
        setModal={setUpdateModal}
        brand={brand}
        designerBrandId={designerBrandId}
        getBrands={getBrands}
      />
    </>
  );
}
