import { Input, Space } from 'antd';
import { Button } from 'antd/lib';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TabTitle } from '../../components/TabTitle';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { LinkB1 } from '../../elements/typography/LinkB1';
import { AuthLayout } from '../../layouts/auth/Auth';
import i18n from '../../plugins/i18n';
import { store } from '../../plugins/redux';
import { setUser } from '../../plugins/redux/auth';
import { routesNamedDict } from '../../routes';
import { verifyTwoFA } from '../../utils/backendRequests/auth/verifyTwoFA';
import { TitleProp } from '../PagesTypes';

export function TwoFactorAuthenticationPage({ title }: TitleProp) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [twoFactorCode, setTwoFactorCode] = useState<string>('');

  const handleSubmit = async () => {
    const response = await verifyTwoFA(state.email, twoFactorCode);
    if (response?.authorized) {
      store.dispatch(
        setUser({
          name: response.name,
          role: response.role,
          accountType: response.account_type,
          token: response.token,
          brandName: response.brandName,
          measurementUnit: response.measurementUnit,
          hoplunUsername: response.hoplunUsername,
        })
      );
      if (response.role === 'admin') {
        navigate(routesNamedDict.ADMIN);
      } else {
        navigate(routesNamedDict.HOME);
      }
    } else {
      setTwoFactorCode('');
    }
  };
  return (
    <AuthLayout>
      <TabTitle title={title} />
      <Space direction="vertical">
        <HeaderTitle
          title={i18n.t('page_titles:2fa_page')}
          subtitle={i18n.t('page_subtitles:2fa_sub')}
        />
        <Space direction="horizontal" size="middle">
          Security Code
          <Input
            value={twoFactorCode}
            onChange={(e) => setTwoFactorCode(e.target.value)}
            maxLength={6}
          />
          <Button onClick={handleSubmit}>{i18n.t('buttons:submit')}</Button>
        </Space>
        <LinkB1 to={routesNamedDict.LOGIN}>
          {i18n.t('buttons:back_to_login')}
        </LinkB1>
      </Space>
    </AuthLayout>
  );
}
