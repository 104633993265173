import { FilterValues, InitialChoicesOrRange } from '../FilterTypes';

// Converts initial filter values to an object containing only their values.
export const createInitialChoicesOrRange = (
  initialFilterValues: FilterValues
) => {
  const initialChoicesOrRange: InitialChoicesOrRange = {};
  for (const [key, value] of Object.entries(initialFilterValues)) {
    initialChoicesOrRange[key] = value.values;
  }
  return initialChoicesOrRange;
};
