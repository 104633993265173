import { Col, Row, Space, Tooltip } from 'antd';
import { ColorCircle, ColourTableText } from '../elements/colours/StyledColour';

export interface Colour {
  title: string | null;
  hexValue: string | null;
  tooltipShow?: boolean;
}

interface DisplayColourProps {
  colours: Colour[];
}

export function DisplayColour({ colours }: DisplayColourProps) {
  return (
    <Space direction="vertical" size={0}>
      {colours.map((colour, index) => (
        <Row key={index} align="middle" wrap={false}>
          <Col>
            <ColourTableText>{colour.title || '-'}</ColourTableText>
          </Col>
          <Col style={{ marginLeft: '8px' }}>
            {colour.hexValue && (
              <Tooltip
                title={colour.tooltipShow && colour.hexValue.toUpperCase()}
              >
                <ColorCircle
                  $hexValue={colour.hexValue}
                  $tooltipShow={colour.tooltipShow as boolean}
                />
              </Tooltip>
            )}
          </Col>
        </Row>
      ))}
    </Space>
  );
}
