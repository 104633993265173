import { Table } from 'antd';
import { format } from 'date-fns';
import i18n from 'i18next';
import {
  GreenCheckedIcon,
  RedUnCheckedIcon,
} from '../../elements/icons/StyledIcons';
import { TableGenericProps, columnFields } from './TableTypes';

// Ensure the array is sorted in ascending order
const pageOptionsArray = [20, 50, 100];
export const defaultPageSize = pageOptionsArray[1];

/**
 * Generic table element with dynamic width.
 *
 * Note: Antd Table currently sets the width of the column as the length of the\
 * data itself. However, if your data is shorter than the column title, the title
 * is cut off.
 *
 * Looping over column names:
 *   - If title exists: width is longest word multiplied by 15px per character
 *     - 14 to account for the font size, 1 to account for letter spacing
 *   - Else is 0
 * Width of Table is then set to max width value.
 */
export const TableGeneric = ({
  columns,
  data,
  rowSelection,
  rowKey = 'key',
  isBackendPagination = false,
  tableState,
  setTableState,
  sortableColumns,
  isFabric = true,
}: TableGenericProps) => {
  // Either use the total from the pagination object or the length of the data array
  const total = tableState?.total || data.length;
  const paginationObj = {
    position: ['bottomCenter' as const],
    defaultPageSize: defaultPageSize,
    current: tableState?.current,
    pageSizeOptions: pageOptionsArray,
    showSizeChanger: true,
    total: total,
    hideOnSinglePage: total < pageOptionsArray[0],
  };

  /** Calculate width for given column title:
   * - Check if a title exists
   * - Split the title into an array of words
   * - Get the longest word and multiply it by 15px per character
   *
   * - Set to zero width if there is no title
   */
  const widthCalculation = (title: string | undefined) => {
    return (
      (title || '')
        .split(' ')
        .reduce((max, part) => Math.max(max, part.length), 0) * 15
    );
  };

  columns = columns.map((column: columnFields) => {
    // Check if the column's dataIndex is in the sortableColumns list
    const isSortable = sortableColumns?.includes(column.dataIndex as string);
    // Set sorter to true if the column is sortable, otherwise set to undefined
    let sorter: ((a: unknown, b: unknown) => number) | boolean | undefined =
      isSortable ? true : undefined;

    // Sorting logic for non fabric table columns
    if (!isFabric && column.columnType) {
      if (column.columnType === 'string' && column.dataIndex) {
        sorter = (a: unknown, b: unknown) =>
          (
            (a as { [key: string]: string })[column.dataIndex!] || ''
          ).localeCompare(
            (b as { [key: string]: string })[column.dataIndex!] || ''
          );
      } else if (column.columnType === 'number' && column.dataIndex) {
        sorter = (a: unknown, b: unknown) =>
          ((a as { [key: string]: number })[column.dataIndex!] || 0) -
          ((b as { [key: string]: number })[column.dataIndex!] || 0);
      } else if (column.columnType === 'date' && column.dataIndex) {
        sorter = (a: unknown, b: unknown) =>
          (new Date(
            (a as { [key: string]: string })[column.dataIndex!]
          ) as unknown as number) -
          (new Date(
            (b as { [key: string]: string })[column.dataIndex!]
          ) as unknown as number);
      } else if (column.columnType === 'boolean' && column.dataIndex) {
        sorter = (a: unknown, b: unknown) => {
          if (
            (a as { [key: string]: boolean })[column.dataIndex!] ===
            (b as { [key: string]: boolean })[column.dataIndex!]
          ) {
            return 0;
          } else {
            return (a as { [key: string]: boolean })[column.dataIndex!]
              ? 1
              : -1;
          }
        };
      }
    }
    /**
     * If columnType is boolean, render the value as a check, a cross, or null
     * for true, false, and others respectively.
     * but only if no render function is provided
     */
    if (!column.render && column.columnType === 'boolean') {
      column.render = (value: boolean) => {
        if (value === true) {
          return <GreenCheckedIcon />;
        } else if (value === false) {
          return <RedUnCheckedIcon />;
        } else {
          return null;
        }
      };
    } else if (!column.render && column.columnType === 'date') {
      // Format date and time
      column.render = (value: string) => {
        if (value === null) {
          return null;
        }
        // Check if input includes time information (e.g., contains "T")
        const hasExplicitTime = value.includes('T');
        // Parse the input into a Date object
        const date = new Date(value);
        // Check if the input is invalid
        if (isNaN(date.getTime())) {
          return null;
        }
        if (hasExplicitTime) {
          return format(date, "yyyy-MM-dd HH:mm:ss 'UTC'");
        } else {
          // Format as date only
          return format(date, 'yyyy-MM-dd');
        }
      };
    }

    const width = column.title
      ? widthCalculation(i18n.t(column.title))
      : '75px';

    return {
      ...column,
      align: 'center',
      width: width,
      sorter: sorter,
    };
  });

  return (
    <Table
      rowSelection={rowSelection}
      columns={columns}
      dataSource={data.map((obj, index) => ({
        ...obj,
        key: index + 1, // Gives all incoming data a key, starts count from 1. This assumes the default rowKey value of 'key' is used.
      }))}
      rowKey={rowKey}
      pagination={paginationObj}
      scroll={{ x: 'max-content' }}
      style={{ whiteSpace: 'pre-wrap', marginTop: '20px', width: '100%' }}
      title={() => i18n.t('headers:table_total_count', { count: total })}
      bordered
      // Update pagination state if backend pagination is enabled
      onChange={(pagination, filters, sorter) => {
        // Handle sorter being an array or undefined
        const sortOrder = Array.isArray(sorter)
          ? sorter[0]?.order
          : sorter?.order;
        const sortField = Array.isArray(sorter)
          ? sorter[0]?.field
          : sorter?.field;

        // Convert sortField to string, handle arrays, numbers, and undefined
        const fieldString = Array.isArray(sortField)
          ? sortField.join(', ')
          : sortField?.toString() || '';

        // Map 'ascend' and 'descend' to 'asc' and 'desc'
        const mappedOrder =
          sortOrder === 'ascend'
            ? 'asc'
            : sortOrder === 'descend'
              ? 'desc'
              : '';

        // If sortOrder is empty, set fieldString to an empty string
        const finalFieldString = mappedOrder === '' ? '' : fieldString;

        // Check if the page or sorter has changed
        if (isBackendPagination && setTableState) {
          setTableState((prev) => ({
            ...prev,
            current: pagination.current as number,
            pageSize: pagination.pageSize as number,
            sortOrder: mappedOrder,
            sortField: finalFieldString,
          }));
        }
      }}
    />
  );
};
