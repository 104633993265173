import { useEffect, useState } from 'react';

import { TabTitle } from '../../components/TabTitle';
import { AdminCertReviewList } from '../../components/table/adminCertReviewList/AdminCertReviewList';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { AdminLayout } from '../../layouts/Admin';
import { AdminCertReviewResponse } from '../../utils/backendRequests/admin/AdminTypes';
import { getCertificationsToVerify } from '../../utils/backendRequests/admin/getCertificationsToVerify';
import { TitleProp } from '../PagesTypes';

export function AdminCertReviewListPage({ title }: TitleProp) {
  const [data, setData] = useState<AdminCertReviewResponse[]>([]);

  async function getCertsToVerify() {
    setData(await getCertificationsToVerify());
  }

  useEffect(() => {
    getCertsToVerify();
  }, []);

  return (
    <AdminLayout>
      <HeaderTitle title={'Certifications to Verify'} />
      <TabTitle title={title} />
      <AdminCertReviewList
        data={data}
        title={title}
        onSuccess={getCertsToVerify}
      />
    </AdminLayout>
  );
}
