import { Form, Space } from 'antd';
import { Dayjs } from 'dayjs';
import i18n from 'i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FileUpload } from '../../../components/upload/FileUpload';
import { ImageUpload } from '../../../components/upload/ImageUpload';
import { SaveFabricButton } from '../../../elements/buttons/StyledButtons';
import {
  StyledHorizontalSpace,
  StyledVerticalSpace,
} from '../../../elements/fabrics/fabricStyledElement';
import { TextB1 } from '../../../elements/typography/TextB1';
import { TextB3 } from '../../../elements/typography/TextB3';
import {
  CenteredUploadWrapper,
  FormWrapper,
  MobileFormWrapper,
} from '../../../elements/wrapper/FormWrapper';
import { createFabric } from '../../../utils/backendRequests/fabric/createFabric';
import { parseFabricTitles } from '../../../utils/fabrics/parseFabricFields';
import { compareAndReturnDifferences } from '../../../utils/fabrics/populateFabricValues';
import { nullParser } from '../../../utils/parsers/parseNullValues';
import { RootAuth } from '../../../utils/types/Types';
import { FormItems } from '../../form/FormItems';
import { PopulateFabricFieldsModal } from '../../modal/PopulateFabricFieldsModal';
import {
  CompositeFieldCheckProps,
  CreateFabricFormProps,
  FabricFormSubmitProps,
} from '../FabricTypes';
import {
  compositeFabricFieldCheck,
  fabricFieldRules,
} from '../fabricFormRules';
import { formFields, initialFabricFormFields } from '../formFields';

/**
 * FabricForm component
 * */
export function CreateFabricForm({
  libHashedName,
  isMobile,
}: CreateFabricFormProps) {
  const { measurementUnit } = useSelector((state: RootAuth) => state.auth);
  const [form] = Form.useForm();
  const [backImg, setBackImg] = useState<File | null>(null);
  const [backImgUrl, setBackImgUrl] = useState<string | null>(null);
  const [frontImg, setFrontImg] = useState<File | null>(null);
  const [frontImgUrl, setFrontImgUrl] = useState<string | null>(null);
  const [headerImg, setHeaderImg] = useState<File | null>(null);
  const [headerImgUrl, setHeaderImgUrl] = useState<string | null>(null);
  const [macroImg, setMacroImg] = useState<File | null>(null);
  const [macroImgUrl, setMacroImgUrl] = useState<string | null>(null);
  const [propsWithDropDownObj, setPropsWithDropDownObj] = useState({});
  const [attachments, setAttachments] = useState<File[] | null>([]);
  const [isPopFabricModalOpen, setIsPopFabricModalOpen] = useState(false);
  const [formValues, setFormValues] = useState<FabricFormSubmitProps | null>(
    null
  );
  const [isPopulatedData, setIsPopulatedData] = useState<Array<{
    field: string;
    original: unknown;
    updated: unknown;
  }> | null>(null);

  const initialFormFields = useRef(initialFabricFormFields);
  const fabricRulesConst = useMemo(
    () => fabricFieldRules(measurementUnit),
    [measurementUnit]
  );
  const navigate = useNavigate();

  useEffect(() => {
    // Set initial values
    form.setFieldsValue(initialFormFields.current);
  }, [initialFormFields, form]);

  const uploadBack = (
    <ImageUpload
      fieldName={i18n.t('fabric_fields:image_url_back')}
      setImage={setBackImg}
      imageUrl={backImgUrl}
      setImageUrl={setBackImgUrl}
    />
  );
  const uploadFront = (
    <ImageUpload
      fieldName={i18n.t('fabric_fields:image_url_front')}
      setImage={setFrontImg}
      imageUrl={frontImgUrl}
      setImageUrl={setFrontImgUrl}
    />
  );
  const uploadHeader = (
    <ImageUpload
      fieldName={i18n.t('fabric_fields:image_url_header')}
      setImage={setHeaderImg}
      imageUrl={headerImgUrl}
      setImageUrl={setHeaderImgUrl}
    />
  );
  const uploadMacro = (
    <ImageUpload
      fieldName={i18n.t('fabric_fields:image_url_macro')}
      setImage={setMacroImg}
      imageUrl={macroImgUrl}
      setImageUrl={setMacroImgUrl}
    />
  );

  const fileUpload = <FileUpload setAttachments={setAttachments} />;

  const handleFormSubmit = async (values: FabricFormSubmitProps) => {
    setFormValues(values);
    const populatedData = await compareAndReturnDifferences(values);
    setIsPopulatedData(populatedData);
    if (populatedData === null) {
      //Already autofilled review in process
      handleSubmit(values);
      return;
    }
    setIsPopFabricModalOpen(true);
  };

  const handleSubmit = async (fabricData: FabricFormSubmitProps) => {
    if (!fabricData) return;
    let values = fabricData;
    // Parses field values to null if they are empty strings
    values = nullParser(values) as FabricFormSubmitProps;
    // Format creation_date following the format of date from python datetime
    if (values.creation_date) {
      values.creation_date = (values.creation_date as Dayjs).format(
        'YYYY-MM-DD'
      );
    }

    // Replace form field with correct colours field for backend and delete
    // colour_ids intermediary form field
    if ('colour_ids' in values) {
      values.colours = values.colour_ids as number[];
      delete (values as { colour_ids?: number[] }).colour_ids;
    }

    // Check if composite fields are fulfilled
    if (
      !compositeFabricFieldCheck(values as unknown as CompositeFieldCheckProps)
    )
      return;

    // Add library_hashed_name to values
    values = { ...values, library_hashed_name: libHashedName };

    // Create formData, only append non-null images
    const formData: FormData = new FormData();
    if (frontImg) formData.append('image_front', frontImg);
    if (backImg) formData.append('image_back', backImg);
    if (headerImg) formData.append('image_header', headerImg);
    if (macroImg) formData.append('image_macro', macroImg);
    if (attachments && attachments.length > 0) {
      for (const file of attachments) {
        formData.append('attachments', file);
      }
    }
    formData.append('fabric', JSON.stringify(values));
    await createFabric(formData);
    navigate(-1);
  };

  const parseFabricTitleWithUnit = (name: string) => {
    return parseFabricTitles(name, measurementUnit);
  };

  return (
    <>
      {isMobile ? (
        <MobileFormWrapper>
          <Form form={form} onFinish={handleFormSubmit}>
            <FormItems
              formFields={formFields}
              propsWithDropDownObj={propsWithDropDownObj}
              setPropsWithDropDownObj={setPropsWithDropDownObj}
              supplierBrandId={null}
              formRules={fabricRulesConst}
              parseFieldTitle={parseFabricTitleWithUnit}
            />
            <CenteredUploadWrapper>
              <TextB1>{i18n.t('fabric_fields:attachments')}</TextB1>
              {fileUpload}
              <TextB3>{i18n.t('long_messages:upload_file_rule')}</TextB3>
              <TextB1>{i18n.t('fabric_fields:image_url_macro')}</TextB1>
              {uploadMacro}
              <TextB1>{i18n.t('fabric_fields:image_url_header')}</TextB1>
              {uploadHeader}
              <TextB1>{i18n.t('fabric_fields:image_url_front')}</TextB1>
              {uploadFront}
              <TextB1>{i18n.t('fabric_fields:image_url_back')}</TextB1>
              {uploadBack}
            </CenteredUploadWrapper>
            <Form.Item>
              <SaveFabricButton type={'create'} />
            </Form.Item>
          </Form>
        </MobileFormWrapper>
      ) : (
        <StyledHorizontalSpace>
          <StyledVerticalSpace>
            <TextB1>{i18n.t('fabric_fields:attachments')}</TextB1>
            {fileUpload}
            <TextB3>{i18n.t('long_messages:upload_file_rule')}</TextB3>
            <TextB1>{i18n.t('fabric_fields:image_url_macro')}</TextB1>
            {uploadMacro}
            <TextB1>{i18n.t('fabric_fields:image_url_header')}</TextB1>
            {uploadHeader}
            <TextB1>{i18n.t('fabric_fields:image_url_front')}</TextB1>
            {uploadFront}
            <TextB1>{i18n.t('fabric_fields:image_url_back')}</TextB1>
            {uploadBack}
          </StyledVerticalSpace>
          <PopulateFabricFieldsModal
            isVisible={isPopFabricModalOpen}
            onCancel={setIsPopFabricModalOpen}
            formValues={formValues}
            handleSubmit={handleSubmit}
            populatedData={isPopulatedData}
            measurementUnit={measurementUnit as string}
          />
          <Space>
            <FormWrapper>
              <Form form={form} onFinish={handleFormSubmit}>
                <FormItems
                  formFields={formFields}
                  propsWithDropDownObj={propsWithDropDownObj}
                  setPropsWithDropDownObj={setPropsWithDropDownObj}
                  supplierBrandId={null}
                  formRules={fabricRulesConst}
                  parseFieldTitle={parseFabricTitleWithUnit}
                />
                <Form.Item>
                  <SaveFabricButton type={'create'} />
                </Form.Item>
              </Form>
            </FormWrapper>
          </Space>
        </StyledHorizontalSpace>
      )}
    </>
  );
}
