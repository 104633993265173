import { columnFields } from '../../components/table/TableTypes';
import i18n from '../../plugins/i18n';
import { formFieldsProp } from './FabricsTypes';

type TranslatedRecordItem = {
  [namespaceKey: string]: unknown;
};

/**
 * Translates a field value based on its fieldName and the provided value.
 */
export const translateValue = (
  fieldName: string,
  value: string | null,
  propertyArray: formFieldsProp[] | columnFields[]
) => {
  // Return null if the value is null
  if (value === null) {
    return null;
  }
  const fieldDefinition = propertyArray.find(
    (field) => field.name === fieldName
  );
  if (
    fieldDefinition &&
    fieldDefinition.isTranslatable &&
    fieldDefinition.namespaceKey
  ) {
    // Use the key from the field definition if it's translatable
    return i18n.t(value, { ns: fieldDefinition.namespaceKey });
  } else {
    return value; // Return the original value if not translatable
  }
};

/**
 * Translates each field value in the provided data using translateValue function.
 */
export const getTranslatedValuesAndUpdateData = (
  data: Record<string, unknown>[],
  propertyArray: formFieldsProp[] | columnFields[]
) => {
  const updatedData = data.map((item: TranslatedRecordItem) => {
    const translatedItem: Record<string, unknown> = {};
    for (const fieldName in item) {
      const value = item[fieldName];
      const translatedValue = translateValue(
        fieldName,
        value as string,
        propertyArray
      );
      translatedItem[fieldName] = translatedValue;
    }
    return translatedItem;
  });
  return updatedData;
};
