import { List } from 'antd';
import i18n from 'i18next';

import { ButtonBasic } from '../../../elements/buttons/StyledButtons';
import { LibraryListProps } from './LibraryModalTypes';

export function LibraryList({ handleSave, libraries }: LibraryListProps) {
  return (
    <List
      dataSource={libraries}
      renderItem={(item) => (
        <List.Item
          actions={[
            <ButtonBasic onClick={() => handleSave(item)}>
              {i18n.t('buttons:move')}
            </ButtonBasic>,
          ]}
        >
          <List.Item.Meta title={item.name} />
        </List.Item>
      )}
    />
  );
}
