import { CheckOutlined } from '@ant-design/icons';
import { List, Space } from 'antd';
import i18n from 'i18next';

import { ButtonBasic } from '../../../elements/buttons/StyledButtons';
import { ProjectListProps } from './ProjectModalTypes';

export function ProjectList({
  handleSave,
  projects,
  containedFabricProjects = [],
}: ProjectListProps) {
  return (
    <List
      dataSource={projects}
      renderItem={(item) => (
        <List.Item
          actions={[
            containedFabricProjects.includes(item.name) ? (
              <Space>
                <span>{i18n.t('headers:copy_existing_fabrics')}</span>
                <CheckOutlined key="check" />
              </Space>
            ) : (
              <ButtonBasic onClick={() => handleSave(item)}>
                {i18n.t('buttons:add')}
              </ButtonBasic>
            ),
          ]}
        >
          <List.Item.Meta title={item.name} />
        </List.Item>
      )}
    />
  );
}
