import { message } from 'antd';
import { apiAuth } from '../api/apiAuth';
import { AuthenticationReturn } from './AuthTypes';

/**
 * Verify the two-factor authentication code.
 */
export const verifyTwoFA = async (
  email: string,
  code: string
): Promise<AuthenticationReturn> => {
  const body = {
    email: email,
    code: code,
  };
  return await apiAuth({
    method: 'POST',
    url: 'v1/authentication/2fa',
    body: body,
  }).catch((error) => {
    message.error(error.message);
  });
};
