import { message } from 'antd';
import { isAxiosError } from 'axios';
import { api } from '../api/api';
import { Users, userType } from './AdminTypes';

/**
 * Get all users based on the type, as admin.
 */
export async function getUsers(type: userType): Promise<Users> {
  try {
    const response = await api({
      method: 'GET',
      url: `v1/superuser/user/${type}`,
    });
    return response;
  } catch (error) {
    if (isAxiosError(error)) {
      message.error(error.response?.data.msg);
    }
    return Promise.reject(error);
  }
}
