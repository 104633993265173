import { AdminBulkCropperPage } from '../pages/admin/AdminBulkCropperPage';
import { AdminCertReviewListPage } from '../pages/admin/AdminCertReviewListPage';
import { AdminDesignerBrandPage } from '../pages/admin/AdminDesignerBrandPage';
import { AdminDesignerListPage } from '../pages/admin/AdminDesignerPage';
import { AdminFabricListPage } from '../pages/admin/AdminFabricListPage';
import { AdminHomePage } from '../pages/admin/AdminHomePage';
import { AdminImageBulkUploadFormPage } from '../pages/admin/AdminImageBulkUploadFormPage';
import { AdminSettingsPage } from '../pages/admin/AdminSettingsPage';
import { AdminSupplierBrandPage } from '../pages/admin/AdminSupplierBrandPage';
import { AdminSupplierListPage } from '../pages/admin/AdminSupplierPage';
import { AdminTrainingLabelsListPage } from '../pages/admin/AdminTrainingLabelsListPage';
import { RouteProps } from './RouteTypes';

export const routesAdmin: RouteProps[] = [
  {
    path: '/admin',
    title: 'Admin',
    page: AdminHomePage,
    children: [
      {
        path: '/training-labels',
        title: 'Training Labels',
        page: AdminTrainingLabelsListPage,
      },
      {
        path: '/all-fabrics',
        title: 'All Fabrics',
        page: AdminFabricListPage,
      },
      {
        path: '/designers',
        title: 'Designers',
        page: AdminDesignerListPage,
      },
      {
        path: '/designer-brands',
        title: 'Designer Brands',
        page: AdminDesignerBrandPage,
      },
      {
        path: '/settings',
        title: 'Settings',
        page: AdminSettingsPage,
      },
      {
        path: '/suppliers',
        title: 'Suppliers',
        page: AdminSupplierListPage,
      },
      {
        path: '/supplier-brands',
        title: 'Supplier Brands',
        page: AdminSupplierBrandPage,
      },
      {
        path: '/certifications/review',
        title: 'Certifications Review',
        page: AdminCertReviewListPage,
      },
      {
        path: '/image-bulk-upload',
        title: 'Image Bulk Upload',
        page: AdminImageBulkUploadFormPage,
      },
      {
        path: '/image-bulk-cropper',
        title: 'Image Bulk Cropper',
        page: AdminBulkCropperPage,
      },
    ],
  },
];
