import i18n from 'i18next';

import { maxDate, minDate } from '../../plugins/dayjs';
import { ReduceType, formFieldsProp } from './FabricsTypes';

/**
 * Fabric fields based on TextileAIFabrics table, fields include:
 * name (string): Represents the field name
 * title (string): Title of the fabric field
 * required (boolean): Represents if field is required in database
 * fieldType (string): String representing the fieldType for forms, Is also used to determine the type of filter
 * if filterFlag is true
 * stringMax (number): Optional, max length of the string field
 * numberRange (object): Optional, object which represents the min and max of the number field
 * dateRange (object): Optional, object which represents the min and max of the date field
 * currency (boolean): Optional, represents if a currency should be attached to the field
 * unitType (boolean): Optional, only used if the field title should have an imperial or metric unit appended
 * columnType (string): Optional, denotes the type of data in the column for sorting logic in tables.
 * filterFlag (boolean): Optional, denotes if the field should be included in the filter logic.
 * Include 'string', 'number', 'date', 'boolean' or omit if conventional sorting is not needed.
 * isTranslatable (boolean): Optional, denotes if the field should be translated in the frontend
 * namespaceKey (string): Optional, denotes key used to translate a translatable field in the frontend.
 */
export const fabricPropertyArray: formFieldsProp[] = [
  {
    name: 'name',
    required: true,
    fieldType: 'string',
    stringMax: 50,
    columnType: 'string',
  },
  {
    name: 'image_url_front',
    required: false,
    fieldType: 'image',
  },
  {
    name: 'image_url_back',
    required: false,
    fieldType: 'image',
  },
  {
    name: 'image_url_header',
    required: false,
    fieldType: 'image',
  },
  {
    name: 'image_url_macro',
    required: false,
    fieldType: 'image',
  },
  {
    name: 'fabric_type',
    required: true,
    fieldType: 'singleDropdown',
    columnType: 'string',
    filterFlag: true,
    isTranslatable: true,
    namespaceKey: 'fabric_type_values',
  },
  {
    name: 'original_supplier',
    required: false,
    fieldType: 'string',
    stringMax: 50,
    columnType: 'string',
    filterFlag: true,
  },
  {
    name: 'price_per_m',
    unitType: true,
    required: false,
    fieldType: 'number',
    columnType: 'number',
    currency: true,
    numberRange: { min: 0, minStrict: false, max: 1000000, maxStrict: false },
    filterFlag: true,
  },
  {
    name: 'price_per_sqm',
    unitType: true,
    required: false,
    fieldType: 'number',
    columnType: 'number',
    currency: true,
    numberRange: { min: 0, minStrict: false, max: 1000000, maxStrict: false },
    filterFlag: true,
  },
  {
    name: 'price_per_kg',
    unitType: true,
    required: false,
    fieldType: 'number',
    columnType: 'number',
    currency: true,
    numberRange: { min: 0, minStrict: false, max: 1000000, maxStrict: false },
    filterFlag: true,
  },
  {
    name: 'price_per_piece',
    required: false,
    fieldType: 'number',
    columnType: 'number',
    currency: true,
    numberRange: { min: 0, minStrict: false, max: 1000000, maxStrict: false },
    filterFlag: true,
  },
  {
    name: 'currency_id',
    required: false,
    fieldType: 'singleDropdown',
  },
  {
    name: 'currency', // Not a database field, string representation of currency_id
    required: false,
    fieldType: 'string',
    columnType: 'string',
    filterFlag: true,
  },
  {
    name: 'moq_m',
    required: false,
    unitType: true,
    fieldType: 'number',
    columnType: 'number',
    numberRange: { min: 0, minStrict: false, max: 1000000, maxStrict: false },
    filterFlag: true,
  },
  {
    name: 'mcq_m',
    required: false,
    unitType: true,
    fieldType: 'number',
    columnType: 'number',
    numberRange: { min: 0, minStrict: false, max: 1000000, maxStrict: false },
    filterFlag: true,
  },
  {
    name: 'moq_sqm',
    required: false,
    unitType: true,
    fieldType: 'number',
    columnType: 'number',
    numberRange: { min: 0, minStrict: false, max: 1000000, maxStrict: false },
    filterFlag: true,
  },
  {
    name: 'mcq_sqm',
    required: false,
    unitType: true,
    fieldType: 'number',
    columnType: 'number',
    numberRange: { min: 0, minStrict: false, max: 1000000, maxStrict: false },
    filterFlag: true,
  },
  {
    name: 'moq_kg',
    required: false,
    unitType: true,
    fieldType: 'number',
    columnType: 'number',
    numberRange: { min: 0, minStrict: false, max: 1000000, maxStrict: false },
    filterFlag: true,
  },
  {
    name: 'mcq_kg',
    required: false,
    unitType: true,
    fieldType: 'number',
    columnType: 'number',
    numberRange: { min: 0, minStrict: false, max: 1000000, maxStrict: false },
    filterFlag: true,
  },
  {
    name: 'moq_piece',
    required: false,
    fieldType: 'number',
    columnType: 'number',
    numberRange: { min: 0, minStrict: false, max: 1000000, maxStrict: false },
    filterFlag: true,
  },
  {
    name: 'mcq_piece',
    required: false,
    fieldType: 'number',
    columnType: 'number',
    numberRange: { min: 0, minStrict: false, max: 1000000, maxStrict: false },
    filterFlag: true,
  },
  {
    name: 'stock_m',
    required: false,
    unitType: true,
    fieldType: 'number',
    columnType: 'number',
    numberRange: { min: 0, minStrict: false, max: 1000000, maxStrict: false },
    filterFlag: true,
  },
  {
    name: 'stock_sqm',
    required: false,
    unitType: true,
    fieldType: 'number',
    columnType: 'number',
    numberRange: { min: 0, minStrict: false, max: 1000000, maxStrict: false },
    filterFlag: true,
  },
  {
    name: 'stock_kg',
    required: false,
    unitType: true,
    fieldType: 'number',
    columnType: 'number',
    numberRange: { min: 0, minStrict: false, max: 1000000, maxStrict: false },
    filterFlag: true,
  },
  {
    name: 'stock_piece',
    required: false,
    fieldType: 'number',
    columnType: 'number',
    numberRange: { min: 0, minStrict: false, max: 1000000, maxStrict: false },
    filterFlag: true,
  },
  {
    name: 'weight_grams_per_m',
    unitType: true,
    required: false,
    fieldType: 'number',
    columnType: 'number',
    numberRange: {
      min: 0,
      minStrict: true,
      max: 1000000,
      maxStrict: false,
    },
    filterFlag: true,
  },
  {
    name: 'weight_grams_per_sqm',
    unitType: true,
    required: false,
    fieldType: 'number',
    columnType: 'number',
    numberRange: {
      min: 0,
      minStrict: true,
      max: 1000000,
      maxStrict: false,
    },
    filterFlag: true,
  },
  {
    name: 'weight_grams_per_piece',
    unitType: true,
    required: false,
    fieldType: 'number',
    columnType: 'number',
    numberRange: {
      min: 0,
      minStrict: true,
      max: 1000000,
      maxStrict: false,
    },
    filterFlag: true,
  },
  {
    name: 'length_cm_per_piece',
    unitType: true,
    required: false,
    fieldType: 'number',
    columnType: 'number',
    numberRange: {
      min: 0,
      minStrict: true,
      max: 1000000,
      maxStrict: false,
    },
    filterFlag: true,
  },
  {
    name: 'width_cm',
    unitType: true,
    required: false,
    fieldType: 'number',
    columnType: 'number',
    numberRange: { min: 0, minStrict: true, max: 1000000, maxStrict: false },
    filterFlag: true,
  },
  {
    name: 'composition1_id',
    required: false,
    fieldType: 'singleDropdown',
    isTranslatable: true,
  },
  {
    name: 'composition1_percentage',
    required: false,
    fieldType: 'number',
    numberRange: {
      min: 0,
      minStrict: true,
      max: 100,
      maxStrict: false,
    },
  },
  {
    name: 'composition2_id',
    required: false,
    fieldType: 'singleDropdown',
    isTranslatable: true,
  },
  {
    name: 'composition2_percentage',
    required: false,
    fieldType: 'number',
    numberRange: {
      min: 0,
      minStrict: true,
      max: 100,
      maxStrict: false,
    },
  },
  {
    name: 'composition3_id',
    required: false,
    fieldType: 'singleDropdown',
    isTranslatable: true,
  },
  {
    name: 'composition3_percentage',
    required: false,
    fieldType: 'number',
    numberRange: {
      min: 0,
      minStrict: true,
      max: 100,
      maxStrict: false,
    },
  },
  {
    name: 'composition4_id',
    required: false,
    fieldType: 'singleDropdown',
    isTranslatable: true,
  },
  {
    name: 'composition4_percentage',
    required: false,
    fieldType: 'number',
    numberRange: {
      min: 0,
      minStrict: true,
      max: 100,
      maxStrict: false,
    },
  },
  {
    name: 'compositions', // Not a database field, array representation of all compositions and their percentages
    required: false,
    fieldType: 'multiDropdown', // Not used for forms but used for filtering
    filterFlag: true,
    isTranslatable: true,
  },
  {
    name: 'construction_id',
    required: false,
    fieldType: 'singleDropdown',
    isTranslatable: true,
    namespaceKey: 'construction_values',
  },
  {
    name: 'construction', // Not a database field, string representation of construction_id
    required: false,
    fieldType: 'string',
    columnType: 'string',
    filterFlag: true,
    isTranslatable: true,
    namespaceKey: 'construction_values',
  },
  {
    name: 'ml_pattern_tags',
    required: false,
    fieldType: 'string',
    stringMax: 100,
    columnType: 'string',
    filterFlag: true,
  },
  {
    name: 'ml_description',
    required: false,
    fieldType: 'string',
    stringMax: 400,
    columnType: 'string',
  },
  {
    name: 'ml_training_label',
    required: false,
    fieldType: 'string',
    stringMax: 50,
    columnType: 'string',
  },
  {
    name: 'created_at',
    required: true,
    fieldType: 'date',
    columnType: 'date',
    filterFlag: true,
    dateRange: {
      min: minDate,
      max: maxDate,
    },
  },
  {
    name: 'updated_at',
    required: false,
    fieldType: 'date',
    columnType: 'date',
    filterFlag: true,
    dateRange: {
      min: minDate,
      max: maxDate,
    },
  },
  {
    name: 'name_from_original_supplier',
    required: false,
    fieldType: 'string',
    stringMax: 50,
    columnType: 'string',
  },
  {
    name: 'supplier_brand_name', // Not a database field, string representation of supplier brand which owns fabric
    required: false,
    fieldType: 'string',
    columnType: 'string',
  },
  {
    name: 'description',
    required: false,
    fieldType: 'stringArea',
    stringMax: 400,
  },
  {
    name: 'notes',
    required: false,
    fieldType: 'stringArea',
    stringMax: 1000,
  },
  {
    name: 'colours',
    required: false,
    fieldType: 'string',
    filterFlag: true,
  },
  {
    name: 'colour_ids', // Representation of colour field in database
    required: false,
    fieldType: 'multiDropdown',
    filterFlag: true,
  },
  {
    name: 'country_of_origin_id',
    required: false,
    fieldType: 'singleDropdown',
  },
  {
    name: 'country_of_origin', // Not a database field, string representation of country_of_origin_id
    required: false,
    fieldType: 'string',
    columnType: 'string',
    filterFlag: true,
  },
  {
    name: 'attachments',
    required: false,
    fieldType: 'string',
  },
  {
    name: 'colour_fastness',
    required: false,
    fieldType: 'string',
    stringMax: 100,
    columnType: 'string',
    filterFlag: true,
  },
  {
    name: 'wash_care',
    required: false,
    fieldType: 'singleDropdown',
    columnType: 'string',
    filterFlag: true,
    isTranslatable: true,
    namespaceKey: 'wash_care_values',
  },
  {
    name: 'piling',
    required: false,
    fieldType: 'number',
    columnType: 'number',
    numberRange: {
      min: 1,
      minStrict: false,
      max: 5,
      maxStrict: false,
    },
    filterFlag: true,
  },
  {
    name: 'gauge_inch',
    required: false,
    fieldType: 'numberInteger',
    columnType: 'number',
    numberRange: { min: 0, minStrict: true, max: 32768, maxStrict: true },
    filterFlag: true,
  },
  {
    name: 'yarn_count',
    required: false,
    fieldType: 'string',
    stringMax: 50,
    filterFlag: true,
  },
  {
    name: 'stretch',
    required: false,
    fieldType: 'singleDropdown',
    columnType: 'string',
    filterFlag: true,
    isTranslatable: true,
    namespaceKey: 'stretch_values',
  },
  {
    name: 'functional_finish',
    required: false,
    fieldType: 'string',
    columnType: 'string',
    stringMax: 100,
    filterFlag: true,
  },
  {
    name: 'season',
    required: false,
    fieldType: 'singleDropdown',
    columnType: 'string',
    filterFlag: true,
    isTranslatable: true,
    namespaceKey: 'season_values',
  },
  {
    name: 'creation_date',
    required: false,
    fieldType: 'date',
    columnType: 'date',
    filterFlag: true,
    dateRange: {
      min: minDate,
      max: maxDate,
    },
  },
  {
    name: 'usage_category',
    required: false,
    fieldType: 'string',
    columnType: 'string',
    stringMax: 100,
    filterFlag: true,
  },
  {
    name: 'look',
    required: false,
    fieldType: 'singleDropdown',
    columnType: 'string',
    filterFlag: true,
    isTranslatable: true,
    namespaceKey: 'look_values',
  },
  {
    name: 'special_yarn',
    required: false,
    fieldType: 'singleDropdown',
    columnType: 'string',
    filterFlag: true,
    isTranslatable: true,
    namespaceKey: 'special_yarn_values',
  },
  {
    name: 'sustainability',
    required: false,
    fieldType: 'singleDropdown',
    columnType: 'string',
    filterFlag: true,
    isTranslatable: true,
    namespaceKey: 'sustainability_values',
  },
  {
    name: 'hand_feel',
    required: false,
    fieldType: 'singleDropdown',
    columnType: 'string',
    filterFlag: true,
    isTranslatable: true,
    namespaceKey: 'hand_feel_values',
  },
  {
    name: 'repeat',
    required: false,
    fieldType: 'string',
    columnType: 'string',
    stringMax: 50,
    filterFlag: true,
  },
  {
    name: 'lace_structure',
    required: false,
    fieldType: 'singleDropdown',
    columnType: 'string',
    filterFlag: true,
    isTranslatable: true,
    namespaceKey: 'lace_structure_values',
  },
  {
    name: 'pattern_design',
    required: false,
    fieldType: 'singleDropdown',
    columnType: 'string',
    filterFlag: true,
    isTranslatable: true,
    namespaceKey: 'pattern_design_values',
  },
  {
    name: 'aesthetic_finish',
    required: false,
    fieldType: 'singleDropdown',
    columnType: 'string',
    filterFlag: true,
    isTranslatable: true,
    namespaceKey: 'aesthetic_finish_values',
  },
  {
    name: 'edge_finish',
    required: false,
    fieldType: 'singleDropdown',
    columnType: 'string',
    filterFlag: true,
    isTranslatable: true,
    namespaceKey: 'edge_finish_values',
  },
  {
    name: 'lead_time_days',
    required: false,
    fieldType: 'numberInteger',
    columnType: 'number',
    numberRange: { min: 0, minStrict: false, max: 32768, maxStrict: true },
    filterFlag: true,
  },
  {
    name: 'availability',
    required: false,
    fieldType: 'singleDropdown',
    columnType: 'string',
    filterFlag: true,
    isTranslatable: true,
    namespaceKey: 'availability_values',
  },
  {
    name: 'certifications', // Should be used when manipulating/filtering certifications as data
    required: false,
    fieldType: 'multiDropdown',
    filterFlag: true,
  },
  {
    name: 'similarity_score', //Optional field only for image search which contains the similarity score field
    required: false,
    fieldType: 'number',
    columnType: 'number',
    filterFlag: true,
  },
];

// Object which maps fabricPropertyArray names to its array index
const nameToIndexMap = fabricPropertyArray.reduce(
  (acc: ReduceType, item: ReduceType, index: number) => {
    acc[item.name] = index;
    return acc;
  },
  {}
);

// unitDictionary for fabric fields
const unitDictionary: {
  [key: string]: {
    metric: string;
    imperial: string;
  };
} = {
  stock_m: { metric: '(m)', imperial: '(yd)' },
  stock_sqm: { metric: '(m²)', imperial: '(yd²)' },
  stock_kg: { metric: '(kg)', imperial: '(lb)' },
  price_per_sqm: { metric: '(m²)', imperial: '(yd²)' },
  price_per_m: { metric: '(m)', imperial: '(yd)' },
  price_per_kg: { metric: '(kg)', imperial: '(lb)' },
  moq_m: { metric: '(m)', imperial: '(yd)' },
  mcq_m: { metric: '(m)', imperial: '(yd)' },
  moq_sqm: { metric: '(m²)', imperial: '(yd²)' },
  mcq_sqm: { metric: '(m²)', imperial: '(yd²)' },
  moq_kg: { metric: '(kg)', imperial: '(lb)' },
  mcq_kg: { metric: '(kg)', imperial: '(lb)' },
  weight_grams_per_piece: { metric: '(g)', imperial: '(lb)' },
  length_cm_per_piece: { metric: '(cm)', imperial: '(inch)' },
  weight_grams_per_m: { metric: '(g/m)', imperial: '(oz/yd)' },
  weight_grams_per_sqm: { metric: '(g/m²)', imperial: '(oz/yd²)' },
  width_cm: { metric: '(cm)', imperial: '(inch)' },
};

/**
 * Parses fabric field name to the fabric title. Also appends a measurement unit if any is found.
 */
export const parseFabricTitles = (
  fabricField: string,
  unitType: string | null = null
) => {
  const fabricItem = fabricPropertyArray[nameToIndexMap[fabricField]];
  let titleString = i18n.t(`fabric_fields:${fabricItem.name}`);

  // Check if `unitType` is null and `fabricField` has `unitType`
  // Also, use optional chaining to prevent errors and nullish coalescing to provide a default empty string
  if (unitType !== null && fabricItem?.unitType) {
    const units = unitDictionary[fabricField];
    if (units && (unitType === 'metric' || unitType === 'imperial')) {
      titleString += ` ${units[unitType] ?? ''}`;
    }
  }

  return titleString;
};

// Returns specified fields following the order of fabricPropertyArray
export const returnOrderedFields = (fieldArray: string[]) => {
  return fabricPropertyArray.filter((item) => fieldArray.includes(item.name));
};

// Map of a specified paramenter of the given fabricPropertyArray
export const returnFieldPropertyObj = (
  givenFabricPropArr: formFieldsProp[],
  property: string
) => {
  return givenFabricPropArr.reduce((acc: ReduceType, item: ReduceType) => {
    if (item[property] !== undefined) {
      // Checks if the property exists in the item
      acc[item.name] = item[property];
    }
    return acc;
  }, {});
};
