import { DownOutlined } from '@ant-design/icons';
import { Col, Dropdown, Row, Space } from 'antd';
import i18n from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal } from '../../components/Modal';
import { Color } from '../../elements/base/Color';

import { TabTitle } from '../../components/TabTitle';
import { ExportSelectedRowsButton } from '../../components/fabric/ExportSelectedRowsButton';
import { TransformedFilterProps } from '../../components/filter/FilterTypes';
import { FabricFilterDrawer } from '../../components/filter/fabric/FabricFilterDrawer';
import { SelectFabricColumns } from '../../components/modal/SelectFabricColumns';
import { SelectMobileFabricColumns } from '../../components/modal/SelectMobileFabricColumns';
import { AddToProjectModal } from '../../components/modal/projectModal/AddToProjectModal';
import { intialDataProp } from '../../components/search/SearchTypes';
import { CompareButton } from '../../components/table/CompareButton';
import {
  defaultPageSize,
  TableGeneric,
} from '../../components/table/TableGeneric';
import { columnFields } from '../../components/table/TableTypes';
import { getSelectedColumns } from '../../components/table/fabricTable/ColumnsDesktop';
import { getSelectedMobileColumns } from '../../components/table/fabricTable/ColumnsMobile';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import {
  ButtonBasic,
  DropdownButton,
} from '../../elements/buttons/StyledButtons';
import { MainLayout } from '../../layouts/Main';
import { deleteFabricsFromCollection } from '../../utils/backendRequests/collection/deleteFabrics';
import { getSingleCollection } from '../../utils/backendRequests/collection/getSingleCollection';
import { fabricPropertyArray } from '../../utils/fabrics/parseFabricFields';
import { getTranslatedValuesAndUpdateData } from '../../utils/fabrics/parseFabricValues';
import { record, RootAuth, RootState } from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export function CollectionDetailsPage({ title }: TitleProp) {
  const { id } = useParams(); // collection ID received from the router
  const [data, setData] = useState<record[]>([]);
  const [translatedData, setTranslatedData] = useState<record[]>([]);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [projectModal, setProjectModal] = useState({ visible: false });
  const [collectionName, setCollectionName] = useState<string>('');
  const [columnModal, setColumnModal] = useState({ visible: false });
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  const { measurementUnit, role } = useSelector(
    (state: RootAuth) => state.auth
  );
  const [selectableColumnNames, setSelectableColumnNames] = useState<string[]>(
    []
  );
  const [sortableColumnNames, setSortableColumnNames] = useState<string[]>([]);
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  const [filterableFields, setFilterableFields] =
    useState<TransformedFilterProps>({});
  const [filterableColumns, setFilterableColumns] =
    useState<TransformedFilterProps>({});

  // columns to be initialized based on current selection or local storage
  const [columns, setColumns] = useState<columnFields[]>([]);
  // Hashed names of selected fabrics according to the filtered selection
  const selectedHashedNames = useMemo(() => {
    if (data.length === 0) {
      return [];
    } else {
      return selectedRowKeys
        .filter((rowKey) => data[(rowKey as number) - 1] !== undefined)
        .map((rowKey) => data[(rowKey as number) - 1]['hashed_name']);
    }
  }, [data, selectedRowKeys]);

  // Pagination and Sorter state
  const [tableState, setTableState] = useState({
    current: 1,
    pageSize: defaultPageSize,
    total: 0,
    sortField: '',
    sortOrder: '',
  });

  useEffect(() => {
    if (isMobileFlag) {
      setColumns(
        getSelectedMobileColumns(
          'collection',
          measurementUnit,
          selectableColumnNames,
          role,
          account
        )
      );
    } else {
      setColumns(
        getSelectedColumns(
          'collection',
          measurementUnit,
          role,
          selectableColumnNames,
          account
        )
      );
    }
  }, [isMobileFlag, measurementUnit, selectableColumnNames]);

  async function getFabricsFromCollection() {
    const res = await getSingleCollection(
      Number(id),
      tableState,
      filterableColumns
    );
    if (res) {
      setData(res.fabrics);
      setCollectionName(res.name);
      setIsOwner(res.is_owner);
      setTableState((prevState) => ({
        ...prevState,
        total: res.fabrics_count,
      }));
      setSelectableColumnNames(res.selectable_columns);
      setSortableColumnNames(res.sortable_columns);
      if (Object.keys(filterableFields).length === 0) {
        setFilterableFields(res.filterable_columns);
      }
    } else {
      setData([]);
      setIsOwner(false);
    }
  }

  async function getFilteredFabricsFromCollection() {
    setTableState((prevState) => ({
      ...prevState,
      current: 1,
    }));
    const res = await getSingleCollection(
      Number(id),
      tableState,
      filterableColumns
    );
    if (res) {
      setData(res.fabrics);
      setCollectionName(res.name);
      setIsOwner(res.is_owner);
      setTableState((prevState) => ({
        ...prevState,
        current: 1,
        total: res.fabrics_count,
      }));
      setSelectableColumnNames(res.selectable_columns);
      setSortableColumnNames(res.sortable_columns);
    } else {
      setData([]);
      setIsOwner(false);
    }
  }

  // Load data on page load
  useEffect(() => {
    getFabricsFromCollection();
  }, [id]);

  // Load data on pagination or sorter change
  useEffect(() => {
    if (data.length > 0) getFabricsFromCollection();
  }, [
    tableState.current,
    tableState.pageSize,
    tableState.sortField,
    tableState.sortOrder,
  ]);

  // Set translated data
  useEffect(() => {
    const translationData = getTranslatedValuesAndUpdateData(
      data,
      fabricPropertyArray
    ) as intialDataProp;
    setTranslatedData(translationData);
  }, [data]);

  // Get Filtered data
  useEffect(() => {
    // Check if data has items and filterableColumns is not an empty object
    if (data.length > 0 && Object.keys(filterableColumns).length > 0) {
      getFilteredFabricsFromCollection();
    }
  }, [filterableColumns]);

  // Resets selected keys when filter updates data
  useEffect(() => {
    setSelectedRowKeys([]);
  }, [data]);

  // Resets data when all data filtered out
  useEffect(() => {
    if (data.length === 0 && Object.keys(filterableColumns).length > 0) {
      getFabricsFromCollection();
    }
  }, [filterableColumns]);

  // Remove selected fabrics from collection
  const handleRemoveConfirm = async () => {
    const response = await deleteFabricsFromCollection(
      Number(id),
      selectedHashedNames
    );

    if (response) {
      setModalVisible(false); // Close the modal after deletion
      setSelectedRowKeys([]);
      getFabricsFromCollection();
    }
  };

  const onSelectChange = (newSelectedRowKeys: number[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange as (
      selectedRowKeys: React.Key[],
      selectedRows: record[]
    ) => void,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const items = [
    {
      key: 'filter',
      label: (
        <DropdownButton
          onClick={() => setFilterOpen(true)}
          disabled={data.length === 0}
        >
          {i18n.t('buttons:filter')}
        </DropdownButton>
      ),
    },
    {
      key: 'compare',
      label: role === 'designer' && (
        <CompareButton
          filteredData={data}
          selectedRowKeys={selectedRowKeys}
          isMobile={isMobileFlag}
        />
      ),
    },
    {
      key: 'select-columns',
      label: (
        <DropdownButton
          onClick={() => setColumnModal({ visible: true })}
          disabled={data.length === 0}
        >
          {i18n.t('buttons:select_columns')}
        </DropdownButton>
      ),
    },
    {
      key: 'export',
      label: role === 'designer' && (
        <ExportSelectedRowsButton
          disabled={!hasSelected}
          data={data}
          selectedRowKeys={selectedRowKeys}
          pageType={i18n.t('page_titles:collections_page')}
        />
      ),
    },
    {
      key: 'add-to-project',
      label: role === 'designer' && (
        <DropdownButton
          onClick={() => setProjectModal({ visible: true })}
          disabled={!hasSelected}
        >
          {i18n.t('buttons:add_to_project')}
        </DropdownButton>
      ),
    },
    {
      key: 'remove',
      label: isOwner && account !== 'viewer' && (
        <DropdownButton
          onClick={() => setModalVisible(true)}
          disabled={!hasSelected}
          danger
        >
          {i18n.t('buttons:remove')}
        </DropdownButton>
      ),
    },
  ];

  return (
    <MainLayout>
      <HeaderTitle
        title={`${i18n.t(
          'page_titles:collection_details_page'
        )} "${collectionName}"`}
        subtitle={i18n.t('page_subtitles:collection_details_sup_sub')}
      />
      <TabTitle title={title} id={id} />
      <AddToProjectModal
        modal={projectModal}
        setModal={setProjectModal}
        hashedNames={selectedHashedNames}
      />
      {isMobileFlag ? (
        <SelectMobileFabricColumns
          modal={columnModal}
          setModal={setColumnModal}
          setSelectedCol={setColumns}
          pageType={'collection'}
          selectableColumnNames={selectableColumnNames}
        />
      ) : (
        <SelectFabricColumns
          modal={columnModal}
          setModal={setColumnModal}
          setSelectedCol={setColumns}
          pageType={'collection'}
          selectableColumnNames={selectableColumnNames}
        />
      )}
      <Row justify="start" gutter={[16, 16]}>
        <Col span={isMobileFlag ? 24 : undefined}>
          {isMobileFlag ? (
            <Dropdown menu={{ items }}>
              <ButtonBasic>
                <Space>
                  {i18n.t('buttons:Options')}
                  <DownOutlined />
                </Space>
              </ButtonBasic>
            </Dropdown>
          ) : (
            <Space direction="horizontal" size="small">
              <ButtonBasic
                onClick={() => setFilterOpen(true)}
                disabled={
                  data.length === 0 &&
                  Object.keys(filterableColumns).length === 0
                }
              >
                {i18n.t('buttons:filter')}
              </ButtonBasic>
              {role === 'designer' && (
                <CompareButton
                  filteredData={data}
                  selectedRowKeys={selectedRowKeys}
                />
              )}
              <ButtonBasic
                onClick={() => setColumnModal({ visible: true })}
                disabled={data.length === 0}
              >
                {i18n.t('buttons:select_columns')}
              </ButtonBasic>
              {role === 'designer' && (
                <ExportSelectedRowsButton
                  disabled={!hasSelected}
                  data={data}
                  selectedRowKeys={selectedRowKeys}
                  pageType={i18n.t('page_titles:collections_page')}
                />
              )}
              {role === 'designer' && (
                <ButtonBasic
                  onClick={() => setProjectModal({ visible: true })}
                  disabled={!hasSelected}
                >
                  {i18n.t('buttons:add_to_project')}
                </ButtonBasic>
              )}
              {isOwner && account !== 'viewer' && (
                <ButtonBasic
                  disabled={!hasSelected}
                  danger
                  onClick={() => setModalVisible(true)}
                >
                  {i18n.t('buttons:remove')}
                </ButtonBasic>
              )}
            </Space>
          )}
          <Modal
            title={i18n.t('headers:remove_fabrics')}
            open={modalVisible}
            onOk={handleRemoveConfirm}
            onCancel={() => setModalVisible(false)}
            okText={i18n.t('buttons:yes')}
            cancelText={i18n.t('buttons:no')}
            okButtonProps={{
              style: { backgroundColor: Color.Red },
            }}
          >
            <p>
              {i18n.t('long_messages:message_remove_fabrics_from_collection')}
            </p>
          </Modal>
        </Col>
      </Row>
      <Row>
        <FabricFilterDrawer
          initialData={data}
          filterOpen={filterOpen}
          closeFilter={() => setFilterOpen(false)}
          setFilterableColumns={setFilterableColumns}
          filterableFields={filterableFields}
        />
        <TableGeneric
          rowSelection={rowSelection}
          columns={columns}
          data={translatedData}
          tableState={tableState}
          setTableState={setTableState}
          isBackendPagination={true}
          sortableColumns={sortableColumnNames}
        />
      </Row>
    </MainLayout>
  );
}
