import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Input, InputNumber, message, Row, Space, Tooltip } from 'antd';
import i18n from 'i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { TabTitle } from '../../components/TabTitle';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { AdminLayout } from '../../layouts/Admin';
import { ImageBulkCropperResponse } from '../../utils/backendRequests/admin/AdminTypes';
import { imageBulkCropper } from '../../utils/backendRequests/admin/imageBulkCropper';
import { RootState } from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export const AdminBulkCropperPage = ({ title }: TitleProp) => {
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);

  // State variables for input fields
  const [folderName, setFolderName] = useState('');
  const [leftPosition, setLeftPosition] = useState<number | null>(null);
  const [topPosition, setTopPosition] = useState<number | null>(null);
  const [rightPosition, setRightPosition] = useState<number | null>(null);
  const [bottomPosition, setBottomPosition] = useState<number | null>(null);
  const [fromRight, setFromRight] = useState<number | null>(null);
  const [fromBottom, setFromBottom] = useState<number | null>(null);
  const [resultText, setResultText] = useState<ImageBulkCropperResponse | null>(
    null
  );

  const handleCrop = async () => {
    const cropDimensions = {
      leftPosition: leftPosition as number,
      topPosition: topPosition as number,
      rightPosition: rightPosition as number,
      bottomPosition: bottomPosition as number,
      fromRight: fromRight as number,
      fromBottom: fromBottom as number,
    };

    try {
      const res = await imageBulkCropper(folderName, cropDimensions);
      setResultText(res);
      if (res?.isSuccess) {
        resetFields();
      }
    } catch (error) {
      message.error('An error occurred while cropping images.');
    }
  };

  const onSubmit = () => {
    const rightPositionsValid =
      (rightPosition === null) != (fromRight === null);
    const bottomPositionsValid =
      (bottomPosition === null) != (fromBottom === null);

    if (
      !folderName ||
      leftPosition === null ||
      topPosition === null ||
      !rightPositionsValid ||
      !bottomPositionsValid
    ) {
      return message.error(
        `Please fill in all required fields: Folder Name, Left Position, Top Position, 
      either Right Position or From Right (but not both), and either Bottom Position
       or From Bottom (but not both).`
      );
    }

    handleCrop();
  };

  const resetFields = () => {
    setFolderName('');
    setLeftPosition(null);
    setTopPosition(null);
    setRightPosition(null);
    setBottomPosition(null);
    setFromRight(null);
    setFromBottom(null);
  };

  return (
    <AdminLayout>
      <HeaderTitle title={i18n.t('Bulk Image Cropper')} isCenter={true} />
      <TabTitle title={title} />
      <Row>
        <div
          style={{
            fontFamily: 'inherit',
            fontSize: 'inherit',
            maxWidth: '40vw',
          }}
        >
          To use the Bulk Image Cropper, follow these steps:
          <ol>
            <li>
              Place all your images in one folder in the appropriate working
              folder on AWS S3 (see the list below). Ensure that folder names do
              not contain spaces.
            </li>
            <li>
              Specify the path to the folder you created, and the crop
              dimensions. The absolute positions are denoted in the fields: Left
              Position, Top Position, Right Position, Bottom Position. These are
              the pixel coordinates. The relative positions are denoted in the
              fields: From Right, From Bottom. From Right and From Bottom denote
              how many pixels from the edge should be cropped. Right Position
              and From Right cannot be filled in at the same time, and Bottom
              Position and From Bottom cannot be filled in at the same time. 0
              can be passed, but values cannot be negative.
            </li>
            <li>
              Click “Crop” to create a new folder named 'cropped_images', where
              all cropped images will be moved to. Images can be downloaded 1 by
              1 at the moment. But they can be bulk moved or copied to another
              folder for the fabric bulk upload. If you need to bulk dowload
              images, please contact the Dev team.
            </li>
          </ol>
        </div>
      </Row>
      <Row>
        <div style={{ fontFamily: 'inherit', fontSize: 'inherit' }}>
          <p> Working Folders:</p>
          <ul>
            <li>delvify-dobby/crop_images - Production AWS S3</li>
            <li>delvify-dobby-staging/crop_images - Staging AWS S3</li>
          </ul>
        </div>
      </Row>
      <Row style={{ marginTop: '15px' }}>
        <Space>
          <label>
            {i18n.t('headers:folder_name')}
            <Tooltip
              title={
                'Specify the relative path to the folder containing images, starting from the crop_images folder on AWS. Do not include a leading /. For example, if the images are in the directory delvify-dobby-staging/crop_images/Talent/2nd_upload where delvify-dobby-staging is the bucket name, the value should be Talent/2nd_upload.'
              }
            >
              <QuestionCircleOutlined
                style={{
                  fontSize: '14px',
                  margin: '0 3px 0 3px',
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
            :
          </label>
          <Input
            style={{ width: 200 }}
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            placeholder={i18n.t('headers:enter_folder_name')}
          />
        </Space>
      </Row>
      <Row style={{ marginTop: '15px' }}>
        <Space direction={isMobileFlag ? 'vertical' : 'horizontal'}>
          <div>
            <label>Left Position: </label>
            <InputNumber
              value={leftPosition}
              onChange={(value) => setLeftPosition(value)}
              placeholder={'Left Position'}
              style={{ marginTop: '5px', marginLeft: '5px' }}
              precision={0}
            />
          </div>
          <div>
            <label>Top Position:</label>
            <InputNumber
              value={topPosition}
              onChange={(value) => setTopPosition(value)}
              placeholder={'Top Position'}
              style={{ marginTop: '5px', marginLeft: '5px' }}
              precision={0}
            />
          </div>
          <div>
            <label>Right Position:</label>
            <InputNumber
              value={rightPosition}
              onChange={(value) => setRightPosition(value)}
              placeholder={'Right Position'}
              style={{ marginTop: '5px', marginLeft: '5px' }}
              precision={0}
            />
          </div>
          <div>
            <label>Bottom Position:</label>
            <InputNumber
              value={bottomPosition}
              onChange={(value) => setBottomPosition(value)}
              placeholder={'Bottom Position'}
              style={{ marginTop: '5px', marginLeft: '5px' }}
              precision={0}
            />
          </div>
          <div>
            <label>From Right:</label>
            <InputNumber
              value={fromRight}
              onChange={(value) => setFromRight(value)}
              placeholder={'From Right'}
              style={{ marginTop: '5px', marginLeft: '5px' }}
              precision={0}
            />
          </div>
          <div>
            <label>From Bottom:</label>
            <InputNumber
              value={fromBottom}
              onChange={(value) => setFromBottom(value)}
              placeholder={'From Bottom'}
              style={{ marginTop: '5px', marginLeft: '5px' }}
              precision={0}
            />
          </div>
        </Space>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Button type="primary" onClick={onSubmit}>
          Crop
        </Button>
      </Row>
      <Row>
        {resultText && (
          <div
            style={{
              color: resultText.isSuccess ? 'green' : 'red',
              marginTop: '10px',
            }}
          >
            *{resultText.text}
          </div>
        )}
      </Row>
    </AdminLayout>
  );
};
