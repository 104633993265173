import { axios } from '../../../plugins/axios';
/**
 * A template to make API calls to axios with. Also includes general error
 * handling.
 *
 * @param {object} - Object used to make the API call using axios
 * @returns {*} - Return of the API call
 */

interface apiProps {
  method: string;
  url: string;
  body?: object;
  headers?: object;
  params?: object;
}

export const api = async ({ method, url, body, headers, params }: apiProps) => {
  return axios
    .request({
      url: `${process.env.REACT_APP_BACKEND_DOBBY}${url}`,
      method,
      data: body,
      headers,
      params,
    })
    .then((r) => r.data)
    .catch((error) => {
      throw new Error(error.response.data.msg);
    });
};
