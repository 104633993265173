import i18n from 'i18next';
import { FrontImageStyled } from '../../../elements/images/StyledImages';

export const columnsMobile = [
  {
    dataIndex: 'image_url',
    key: 'image',
    title: i18n.t('column_titles:image'),
    width: 96,
    render: (text: string) => (
      <FrontImageStyled width={'30vw'} height={'30vw'} src={text} />
    ),
  },
  {
    dataIndex: 'description',
    key: 'description',
    title: i18n.t('column_titles:description'),
  },
];
