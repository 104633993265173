import { Row, Space } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { TabTitle } from '../../components/TabTitle';
import { GenericFilterDrawer } from '../../components/filter/generic/GenericFilterDrawer';
import { createInitialFilterValues } from '../../components/filter/generic/libraryInitialValues';
import { CreateFabricLibraryModal } from '../../components/modal/CreateFabricLibraryModal';
import { FabricLibraryList } from '../../components/table/fabricLibraryList/FabricLibraryList';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { ButtonBasic } from '../../elements/buttons/StyledButtons';
import { MainLayout } from '../../layouts/Main';
import { routesNamedDict } from '../../routes';
import { RootAuth, RootLibrary } from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export function FabricLibraryListPage({ title }: TitleProp) {
  const libraries = useSelector(
    (state: RootLibrary) => state.fabricLibrary.libraryList
  );
  const { role } = useSelector((state: RootAuth) => state.auth);
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  const [modal, setModal] = useState({ visible: false });
  const [filterOpen, setFilterOpen] = useState(false);
  const [filteredData, setFilteredData] = useState(libraries);
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect if role is not 'supplier'
    if (role !== 'supplier') {
      navigate('/' + routesNamedDict.NOT_FOUND);
    }
  }, [role, navigate]);

  useEffect(() => {
    if (state?.data) state.data = false;
  }, [state]);

  // Update filteredData whenever the library changes
  useEffect(() => {
    setFilteredData(libraries);
  }, [libraries]);

  const handleNew = () => {
    setModal({ visible: true });
  };

  const viewAllFabrics = () => {
    navigate(routesNamedDict.ALL_FABRICS);
  };

  return (
    <MainLayout>
      <HeaderTitle
        title={i18n.t('page_titles:fabric_libraries_page')}
        subtitle={i18n.t('page_subtitles:fabric_libraries_sup_sub')}
      />
      <TabTitle title={title} />
      <CreateFabricLibraryModal setModal={setModal} modal={modal} />
      <Row justify="start">
        <Space>
          {account !== 'viewer' && (
            <ButtonBasic onClick={handleNew}>
              {i18n.t('buttons:create')}
            </ButtonBasic>
          )}
          <Space>
            <ButtonBasic
              onClick={() => setFilterOpen(true)}
              disabled={libraries.length === 0}
            >
              {i18n.t('buttons:filter')}
            </ButtonBasic>
            <ButtonBasic onClick={viewAllFabrics}>
              {i18n.t('buttons:all_fabrics')}
            </ButtonBasic>
          </Space>
        </Space>
        <GenericFilterDrawer
          initialData={libraries}
          setFilteredData={setFilteredData}
          filterOpen={filterOpen}
          closeFilter={() => setFilterOpen(false)}
          createInitialFilterValues={createInitialFilterValues}
          role={role as string}
        />
      </Row>
      <Row>
        <FabricLibraryList title={title} data={filteredData} />
      </Row>
    </MainLayout>
  );
}
