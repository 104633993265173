import { Modal as AntdModal, ModalProps } from 'antd';
import { useSelector } from 'react-redux';

import { RootState } from '../utils/types/Types';
import { Spinner } from './Spinner';

/**
 * Component extends the antd Modal with an added Spinner component
 * which is active when there is a pending API call (gets this from redux).
 * This is required as App.jsx has a general Spinner component but it cannot
 * overlay antd Modals.
 */
export const Modal = (props: ModalProps) => {
  const { apiCallCount } = useSelector((state: RootState) => state.common);

  return (
    <AntdModal {...props}>
      <Spinner spinning={apiCallCount !== 0}>{props.children}</Spinner>
    </AntdModal>
  );
};
