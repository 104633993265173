import { message } from 'antd';
import i18n from 'i18next';
import { MsgProp } from '../../types/Types';
import { api } from '../api/api';

/**
 * Create a bulk upload.
 */
export async function createBulkUpload(data: FormData) {
  try {
    return await api({
      method: 'POST',
      url: `v1/bulk_upload`,
      body: data,
    }).then((res: MsgProp) => {
      message.info(i18n.t('long_messages:message_fabric_import_progress'));
      return res;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
