import { Button, Upload } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { ImageUploadStyle } from '../../elements/images/StyledImages';
import { UploadWrapper } from '../../elements/wrapper/UploadWrapper';
import { parseScaledImageFile } from '../../utils/parsers/parseScaledImageFile';
import { Setter } from '../../utils/types/Types';
import { ImageCropperModal } from '../modal/ImageCropperModal';
import { AddImageTitle } from './AddImageTitle';
import { CustomUploadRequestOption } from './FileUpload';

interface EditFabricImageUploadProps {
  fieldName: string;
  setImage: Setter<File | null>;
  imageUrl: string | null;
  setImageUrl: Setter<string | null>;
  imagesToDelete: string[];
  setImagesToDelete: Setter<string[]>;
  deleteFieldName: string;
  hasImage: boolean;
}

export function EditFabricImageUpload({
  fieldName,
  setImage,
  imageUrl,
  setImageUrl,
  imagesToDelete,
  setImagesToDelete,
  deleteFieldName,
  hasImage,
}: EditFabricImageUploadProps) {
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const [originalImageFile, setOriginalImageFile] = useState<File | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [scaledImageUrl, setScaledImageUrl] = useState<string | null>(null);

  //Display intial existing image for editing an existing fabric
  useEffect(() => {
    if (imageUrl !== null) {
      setCroppedImageUrl(imageUrl);
    }
  }, [imageUrl]);

  const props = {
    maxCount: 1,
    showUploadList: false,
    customRequest(options: unknown) {
      const { file } = options as CustomUploadRequestOption;
      setOriginalImageFile(file); // Store the original image file
      setImage(file);
      getBase64(file, (url: string | null) => {
        if (url !== null) {
          setImageUrl(url);
          setScaledImageUrl(null); // Reset the scaled image URL
          setModalVisible(true);
          parseScaledImageFile(url).then((file) => {
            const newUrl = URL.createObjectURL(file);
            setScaledImageUrl(newUrl);
          });
        }
      });
    },
  };

  // Gets the image as base64 to preview.
  const getBase64 = (img: File, callback: (url: string | null) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      callback(reader.result as string | null)
    );
    reader.readAsDataURL(img);
  };

  // Delete image option
  const handleDelete = () => {
    setImageUrl(null);
    setImage(null);
    if (
      hasImage &&
      !imagesToDelete.find((element) => element === deleteFieldName)
    )
      setImagesToDelete((prev) => [...prev, deleteFieldName]);
  };

  return (
    <div style={{ width: '200px' }}>
      <Upload {...props}>
        <UploadWrapper>
          {croppedImageUrl ? (
            <ImageUploadStyle src={croppedImageUrl} alt={fieldName} />
          ) : (
            <AddImageTitle />
          )}
        </UploadWrapper>
      </Upload>
      {imageUrl && (
        <Button
          type="primary"
          danger
          onClick={handleDelete}
          style={{ marginTop: '10px', width: '100%' }}
        >
          {i18n.t('buttons:delete')}
        </Button>
      )}
      <ImageCropperModal
        setImage={setImage}
        imageUrl={imageUrl}
        setCroppedImageUrl={setCroppedImageUrl}
        setScaledImageUrl={setScaledImageUrl}
        originalImageFile={originalImageFile}
        scaledImageUrl={scaledImageUrl}
        setImageUrl={setImageUrl}
        setModalVisible={setModalVisible}
        modalVisible={modalVisible}
      />
    </div>
  );
}
