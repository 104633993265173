import { axiosNoAuth as axios } from '../../../plugins/axiosNoAuth';
import { NavigateProp, PathsObject } from './AuthTypes';

/**
 * Resets password. If successful display a success message and navigate to the login page.
 *
 * Uses useNavigate() hook from react-router-dom
 */
export async function resetPassword(
  email: string,
  navigate: NavigateProp,
  paths: PathsObject
): Promise<void> {
  axios
    .post(
      process.env.REACT_APP_BACKEND_AUTH + 'v1/authentication/password_reset',
      { email }
    )
    .then((response) => {
      if (response.status === 200) {
        navigate(paths.LOGIN, {
          state: { code: response.status, msg: response.data.msg },
        });
      }
    })
    .catch((error) => {
      // Wrong email, or internal server error
      navigate(paths.PASSWORD_RESET, {
        state: { code: error.response.status, msg: error.response.data.msg },
      });
    });
}
