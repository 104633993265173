import { formFieldsProp } from '../../form/FormTypes';

export const verifyFields: formFieldsProp[] = [
  {
    name: 'is_name_verified',
    fieldType: 'boolean',
    required: true,
  },
  {
    name: 'is_company_verified',
    fieldType: 'boolean',
    required: true,
  },
  {
    name: 'expiry_date',
    fieldType: 'date',
    required: false,
  },
  {
    name: 'certification_status',
    fieldType: 'singleDropdown',
    required: true,
  },
];

export const initCreateVerifyFormValues = {
  is_name_verified: false,
  is_company_verified: false,
  expiry_date: null,
  certification_status: 1,
};
