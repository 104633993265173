import { message } from 'antd';
import { isAxiosError } from 'axios';
import i18n from 'i18next';

import { refreshProjectList } from '../../redux/list';
import { api } from '../api/api';
import { projectsResponse } from './ProjectTypes';
/**
 * Creates a new project.
 * After a project is successfully created, the project
 * list stored in redux is refreshed.
 */
export async function createProject(
  name: string,
  description: string | null,
  hashedNames: string[]
): Promise<projectsResponse> {
  const data = {
    name: name,
    description: description,
    fabrics_hashed_names: hashedNames,
  };
  try {
    const response = await api({
      method: 'POST',
      url: 'v1/project',
      body: data,
      headers: {
        'content-type': 'application/json',
      },
    });
    message.success(i18n.t('long_messages:message_project_created_success'));
    refreshProjectList();
    return response;
  } catch (error) {
    if (isAxiosError(error)) {
      message.error((error.response as { msg?: string })?.msg);
    } else if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
