import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { TableGeneric } from '../../../components/table/TableGeneric';
import { routesNamedDict } from '../../../routes';
import { AdminLabel } from '../../../utils/backendRequests/admin/AdminTypes';
import { TabTitle } from '../../TabTitle';
import { AdminLabelListProps, LabelColumn } from '../TableTypes';
import { labelColumns } from './LabelColumns';

/**
 * Display table of lace fabrics allowing the admin to update
 * the machine learning training labels for selected fabrics.
 */
export function AdminLabelList({ data, title }: AdminLabelListProps) {
  const [columns, setColumns] = useState<LabelColumn[]>([]);
  const navigate = useNavigate(); // Initialize navigate function

  const handleViewLabel = (label: AdminLabel) => {
    navigate(routesNamedDict.ADMIN_ALL_FABRICS, {
      state: {
        label: label,
      },
    });
  };

  useEffect(() => {
    setColumns(labelColumns(handleViewLabel));
  }, []);

  return (
    <>
      {/* if no data's fed in, show parent component's title for browser tab, i.e. use 'Fabric' instead of 'Library :id', otherwise child will overide parent tab's title */}
      {data[0] && <TabTitle title={title} />}
      <TableGeneric columns={columns} data={data} isFabric={false} />
    </>
  );
}
