import { Input, Row, Typography } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import i18n from '../../plugins/i18n';
import { setHoplunUsername } from '../../plugins/redux/auth';
import { getHoplunLoginVerified } from '../../utils/backendRequests/user/getHoplunLoginVerified';
import { Setter } from '../../utils/types/Types';
import { Modal } from '../Modal';

const { Text } = Typography;

interface HopLunLoginProps {
  isModalOpen: boolean;
  setIsModalOpen: Setter<boolean>;
}

export function HopLunLoginModal({
  isModalOpen,
  setIsModalOpen,
}: HopLunLoginProps) {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const dispatch = useDispatch();

  // If successful, closes modal
  const handleSubmit = async () => {
    if (username.trim() === '' || password.trim() === '') {
      setError(i18n.t('long_messages:empty_username_password'));
      return;
    }
    const hoplunVerified = await getHoplunLoginVerified(username, password);
    if (hoplunVerified) {
      dispatch(setHoplunUsername(username));
    }
    setIsModalOpen(false);
    setError('');
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setError('');
  };

  return (
    <Modal
      title={i18n.t('headers:hoplun_login_modal')}
      open={isModalOpen}
      onOk={handleSubmit}
      onCancel={handleCancel}
    >
      {error && <Text type="danger">{error}</Text>}
      <Row>
        <span>{i18n.t('headers:username')}</span>
        <Input value={username} onChange={(e) => setUsername(e.target.value)} />
      </Row>
      <Row>
        <span>{i18n.t('common:password')}</span>
        <Input.Password
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Row>
    </Modal>
  );
}
