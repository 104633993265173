import { TrimsPage } from '../pages/trims/TrimsPage';
import { RouteProps } from './RouteTypes';

export const routesTrims: RouteProps[] = [
  {
    path: '/trims',
    title: 'Trims',
    key: 'trims_page',
    page: TrimsPage,
  },
];
