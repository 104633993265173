import { BinListPage } from '../pages/bin/BinListPage';
import { RouteProps } from './RouteTypes';

export const routesBin: RouteProps[] = [
  {
    path: '/bin',
    title: 'Bin',
    key: 'bin_page',
    page: BinListPage,
  },
];
