import { message } from 'antd';

import i18n from '../../../plugins/i18n';
import { api } from '../api/api';

/**
 * Attempts to verify the provided HopLun login credentials.
 * Returns true if the credentials were successfully verfied.
 */

export async function getHoplunLoginVerified(
  username: string,
  password: string
): Promise<boolean> {
  const data = {
    hoplun_username: username,
    hoplun_password: password,
  };
  try {
    return await api({
      method: 'PUT',
      url: 'v1/user/hoplun_credentials',
      body: data,
    }).then(() => {
      message.success(i18n.t('long_messages:hoplun_login_success_message'));
      return true;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return false;
  }
}
