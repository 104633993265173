import { FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Checkbox, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import i18n from 'i18next';
import React from 'react';
import { HelpIcon } from '../../elements/icons/StyledIcons';
import { Setter } from '../../utils/types/Types';
import { Modal } from '../Modal';

interface ExportFabricsModalProps {
  visible: boolean;
  includeImages: boolean;
  setIncludeImages: Setter<boolean>;
  onCancel: () => void;
  onExportExcel: () => void;
  onExportPDF: () => void;
}

export function ExportFabricsModal({
  visible,
  includeImages,
  setIncludeImages,
  onCancel,
  onExportExcel,
  onExportPDF,
}: ExportFabricsModalProps) {
  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setIncludeImages(e.target.checked);
  };

  return (
    <Modal
      title={i18n.t('headers:select_export_type')}
      open={visible}
      okText={i18n.t('buttons:upload_file')}
      onCancel={onCancel}
      footer={[
        <React.Fragment key="footer">
          <Button onClick={onExportPDF}>
            <FilePdfOutlined />
            {i18n.t('buttons:pdf')}
          </Button>
          <Button onClick={onExportExcel}>
            <FileExcelOutlined />
            {i18n.t('buttons:excel')}
          </Button>
        </React.Fragment>,
      ]}
    >
      <Row>{i18n.t('long_messages:export_fabric_modal_message')}</Row>
      <Row>
        <Checkbox checked={includeImages} onChange={handleCheckboxChange}>
          {i18n.t('headers:include_images')}
        </Checkbox>
        <HelpIcon
          title={i18n.t('long_messages:message_explain_include_images')}
        />
      </Row>
    </Modal>
  );
}
