import { message } from 'antd';
import { api } from '../api/api';
import { AdminCertReviewResponse } from './AdminTypes';

/**
 * Gets all certification details which should be verified.
 */
export async function getCertificationsToVerify(): Promise<
  AdminCertReviewResponse[]
> {
  try {
    return await api({
      method: 'GET',
      url: `v1/superuser/certification/verify`,
    }).then((res: AdminCertReviewResponse[]) => {
      return res;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
