import styled from 'styled-components';

export const SearchBarWrapper = styled.div`
  max-height: 40px;
  maxwidth: 100%;
  @media only screen and (min-width: 1200px) {
    min-width: 600px;
  }
  @media only screen and (min-width: 960px) and (max-width: 1200px) {
    min-width: 500px;
  }
  @media only screen and (min-width: 768px) and (max-width: 960px) {
    min-width: 300px;
  }
`;
