import { message } from 'antd';

import { TransformedFilterProps } from '../../../components/filter/FilterTypes';
import { GenericTableReqProps, TableStateObj } from '../../types/Types';
import { api } from '../api/api';
import { GetFabricsResponse } from './FabricTypes';

/**
 * Returns all fabrics for a given user
 */
export async function getAllFabrics(
  tableState: TableStateObj,
  filterableColumns: TransformedFilterProps
): Promise<GetFabricsResponse> {
  const params: GenericTableReqProps = {
    page: tableState.current,
    page_size: tableState.pageSize,
  };
  // Add sort parameters only if both sortField and sortOrder have values
  if (tableState.sortField && tableState.sortOrder) {
    params.sort_by = tableState.sortField;
    params.sort_order = tableState.sortOrder;
  }
  if (filterableColumns) {
    params.filter_values = JSON.stringify(filterableColumns);
  }
  try {
    return await api({
      method: 'GET',
      url: 'v1/fabric',
      params: params,
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
