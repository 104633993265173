import { DownOutlined } from '@ant-design/icons';
import { Col, Dropdown, Row, Space } from 'antd';
import i18n from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from '../../components/Modal';
import { TabTitle } from '../../components/TabTitle';
import { ExportSelectedRowsButton } from '../../components/fabric/ExportSelectedRowsButton';
import { TransformedFilterProps } from '../../components/filter/FilterTypes';
import { FabricFilterDrawer } from '../../components/filter/fabric/FabricFilterDrawer';
import { SelectFabricColumns } from '../../components/modal/SelectFabricColumns';
import { SelectMobileFabricColumns } from '../../components/modal/SelectMobileFabricColumns';
import { intialDataProp } from '../../components/search/SearchTypes';
import { CompareButton } from '../../components/table/CompareButton';
import {
  defaultPageSize,
  TableGeneric,
} from '../../components/table/TableGeneric';
import { columnFields } from '../../components/table/TableTypes';
import { getSelectedColumns } from '../../components/table/fabricTable/ColumnsDesktop';
import { getSelectedMobileColumns } from '../../components/table/fabricTable/ColumnsMobile';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { Color } from '../../elements/base/Color';
import {
  ButtonBasic,
  DropdownButton,
} from '../../elements/buttons/StyledButtons';
import { MainLayout } from '../../layouts/Main';
import { routesNamedDict } from '../../routes';
import { deleteFabricsFromProjects } from '../../utils/backendRequests/project/deleteFabrics';
import { getFabrics } from '../../utils/backendRequests/project/getFabrics';
import { fabricPropertyArray } from '../../utils/fabrics/parseFabricFields';
import { getTranslatedValuesAndUpdateData } from '../../utils/fabrics/parseFabricValues';
import { record, RootAuth, RootState } from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export function ProjectDetailsPage({ title }: TitleProp) {
  const { name } = useParams(); // Project name received from the router
  const [data, setData] = useState<record[]>([]);
  const [translatedData, setTranslatedData] = useState<record[]>([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [refresh, setRefresh] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [columnModal, setColumnModal] = useState({ visible: false });
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  const { measurementUnit, role } = useSelector(
    (state: RootAuth) => state.auth
  );
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  const [filterableFields, setFilterableFields] =
    useState<TransformedFilterProps>({});
  const [filterableColumns, setFilterableColumns] =
    useState<TransformedFilterProps>({});

  // Pagination and Sorter state
  const [tableState, setTableState] = useState({
    current: 1,
    pageSize: defaultPageSize,
    total: 0,
    sortField: '',
    sortOrder: '',
  });
  const [sortableColumnNames, setSortableColumnNames] = useState<string[]>([]);

  const navigate = useNavigate();
  useEffect(() => {
    // Redirect if role is not 'designer'
    if (role !== 'designer') {
      navigate('/' + routesNamedDict.NOT_FOUND);
    }
  }, [role, navigate]);

  // columns to be initialized based on current selection or local storage
  const [columns, setColumns] = useState<columnFields[]>([]);
  const [selectableColumnNames, setSelectableColumnNames] = useState<string[]>(
    []
  );
  // Hashed names of selected fabrics according to the filtered selection
  const selectedHashedNames = useMemo(() => {
    if (data.length === 0) {
      return [];
    } else {
      return selectedRowKeys
        .filter((rowKey) => data[(rowKey as number) - 1] !== undefined)
        .map((rowKey) => data[(rowKey as number) - 1]['hashed_name']);
    }
  }, [data, selectedRowKeys]);

  useEffect(() => {
    if (isMobileFlag) {
      setColumns(
        getSelectedMobileColumns(
          'project',
          measurementUnit,
          selectableColumnNames,
          role,
          account
        )
      );
    } else {
      setColumns(
        getSelectedColumns(
          'project',
          measurementUnit,
          role,
          selectableColumnNames,
          account
        )
      );
    }
  }, [isMobileFlag, measurementUnit, selectableColumnNames]);

  async function _getFabricsFromProject() {
    const res = await getFabrics(String(name), tableState, filterableColumns);
    if (res) {
      setData(res.fabrics);
      setIsOwner(res.is_owner);
      setTableState((prevState) => ({
        ...prevState,
        total: res.fabrics_count,
      }));
      setSelectableColumnNames(res.selectable_columns);
      setSortableColumnNames(res.sortable_columns);
      if (Object.keys(filterableFields).length === 0) {
        setFilterableFields(res.filterable_columns);
      }
    }
  }

  async function _getFilteredFabricsFromProject() {
    setTableState((prevState) => ({
      ...prevState,
      current: 1,
    }));
    const res = await getFabrics(String(name), tableState, filterableColumns);
    if (res) {
      setData(res.fabrics);
      setIsOwner(res.is_owner);
      setTableState((prevState) => ({
        ...prevState,
        current: 1,
        total: res.fabrics_count,
      }));
      setSelectableColumnNames(res.selectable_columns);
      setSortableColumnNames(res.sortable_columns);
    }
  }

  useEffect(() => {
    _getFabricsFromProject();
  }, [name, refresh]);

  // Load data on pagination or sorter change
  useEffect(() => {
    if (data.length > 0) _getFabricsFromProject();
  }, [
    tableState.current,
    tableState.pageSize,
    tableState.sortField,
    tableState.sortOrder,
  ]);

  // Set translated data
  useEffect(() => {
    const translationData = getTranslatedValuesAndUpdateData(
      data,
      fabricPropertyArray
    ) as intialDataProp;
    setTranslatedData(translationData);
  }, [data]);

  // Get Filtered data
  useEffect(() => {
    // Check if data has items and filterableColumns is not an empty object
    if (data.length > 0 && Object.keys(filterableColumns).length > 0) {
      _getFilteredFabricsFromProject();
    }
  }, [filterableColumns]);

  // Resets selected keys when filter updates data
  useEffect(() => {
    setSelectedRowKeys([]);
  }, [data]);

  // Resets data when all data filtered out
  useEffect(() => {
    if (data.length === 0 && Object.keys(filterableColumns).length > 0) {
      _getFabricsFromProject();
    }
  }, [filterableColumns]);

  const handleConfirm = async () => {
    const deleteFabricsResult = await deleteFabricsFromProjects(
      String(name),
      selectedHashedNames
    );
    if (deleteFabricsResult) {
      setSelectedRowKeys(deleteFabricsResult.newSelectedRowKeys);
      setRefresh(deleteFabricsResult.refreshCount);
      setModalVisible(false); // Close the modal after deletion
    }
  };

  const onSelectChange = (newSelectedRowKeys: number[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange as (
      selectedRowKeys: React.Key[],
      selectedRows: record[]
    ) => void,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const items = [
    {
      key: 'filter',
      label: (
        <DropdownButton onClick={() => setFilterOpen(true)}>
          {i18n.t('buttons:filter')}
        </DropdownButton>
      ),
    },
    {
      key: 'compare',
      label: (
        <CompareButton
          filteredData={data}
          selectedRowKeys={selectedRowKeys}
          isMobile={true}
        />
      ),
    },
    {
      key: 'select-columns',
      label: (
        <DropdownButton onClick={() => setColumnModal({ visible: true })}>
          {i18n.t('buttons:select_columns')}
        </DropdownButton>
      ),
    },
    {
      key: 'export',
      label: (
        <ExportSelectedRowsButton
          disabled={!hasSelected}
          data={data}
          selectedRowKeys={selectedRowKeys}
          pageType={i18n.t('buttons:all_fabrics')}
        />
      ),
    },
    // Conditionally include the "remove" button if isOwner is true
    ...(isOwner
      ? [
          {
            key: 'remove',
            label: (
              <DropdownButton
                onClick={() => setModalVisible(true)}
                disabled={!hasSelected}
                danger
              >
                {i18n.t('buttons:remove')}
              </DropdownButton>
            ),
          },
        ]
      : []),
  ];

  return (
    <MainLayout>
      <HeaderTitle
        title={`${i18n.t('page_titles:project_page')} "${String(name)}"`}
      />
      <TabTitle title={title} id={name} />
      {isMobileFlag ? (
        <SelectMobileFabricColumns
          modal={columnModal}
          setModal={setColumnModal}
          setSelectedCol={setColumns}
          pageType={'project'}
          selectableColumnNames={selectableColumnNames}
        />
      ) : (
        <SelectFabricColumns
          modal={columnModal}
          setModal={setColumnModal}
          setSelectedCol={setColumns}
          pageType={'project'}
          selectableColumnNames={selectableColumnNames}
        />
      )}
      <Row justify="start" gutter={[16, 16]}>
        <Col span={isMobileFlag ? 24 : undefined}>
          {isMobileFlag ? (
            <Dropdown menu={{ items }}>
              <ButtonBasic>
                <Space>
                  {i18n.t('buttons:Options')}
                  <DownOutlined />
                </Space>
              </ButtonBasic>
            </Dropdown>
          ) : (
            <Space direction="horizontal" size="small">
              <CompareButton
                filteredData={translatedData}
                selectedRowKeys={selectedRowKeys}
              />
              <ButtonBasic
                onClick={() => setFilterOpen(true)}
                disabled={
                  data.length === 0 &&
                  Object.keys(filterableColumns).length === 0
                }
              >
                {i18n.t('buttons:filter')}
              </ButtonBasic>
              <ButtonBasic
                onClick={() => setColumnModal({ visible: true })}
                disabled={data.length === 0 || isMobileFlag}
              >
                {i18n.t('buttons:select_columns')}
              </ButtonBasic>
              <ExportSelectedRowsButton
                disabled={!hasSelected}
                data={translatedData}
                selectedRowKeys={selectedRowKeys}
                pageType={name as string}
              />
              {isOwner && (
                <ButtonBasic
                  onClick={() => setModalVisible(true)}
                  disabled={!hasSelected}
                  danger
                >
                  {i18n.t('buttons:remove')}
                </ButtonBasic>
              )}
            </Space>
          )}
        </Col>
      </Row>
      <Modal
        title={i18n.t('headers:remove_fabrics')}
        open={modalVisible}
        onOk={handleConfirm}
        onCancel={() => setModalVisible(false)}
        okText={i18n.t('buttons:yes')}
        cancelText={i18n.t('buttons:no')}
        okButtonProps={{
          style: { backgroundColor: Color.Red },
        }}
      >
        <p>{i18n.t('long_messages:message_remove_fabrics_from_project')}</p>
      </Modal>
      <Row>
        <FabricFilterDrawer
          initialData={data}
          filterOpen={filterOpen}
          closeFilter={() => setFilterOpen(false)}
          setFilterableColumns={setFilterableColumns}
          filterableFields={filterableFields}
        />
        <TableGeneric
          rowSelection={rowSelection}
          columns={columns}
          data={translatedData}
          tableState={tableState}
          setTableState={setTableState}
          isBackendPagination={true}
          sortableColumns={sortableColumnNames}
        />
      </Row>
    </MainLayout>
  );
}
